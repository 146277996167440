import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils';

// Assets
import logo from 'assets/img/landing-page/jpt-logo.svg';

// Components
import Modal, { DefaultModalProps } from '.';
import ButtonLoader from '../ButtonLoader';
import InputField from '../InputField';

import { partners } from 'app/containers/HomePage';
import { selectLandingPage } from 'app/containers/HomePage/selectors';
import { landingPageActions } from 'app/containers/HomePage/slice';
import GreatModal from './GreatModal';

const EarlyAccessModal = memo((props: DefaultModalProps) => {
    // const partnerSlugs = ['autosweeprfid', 'bayadcenter', 'beep', 'meralco', 'kload', 'globe', 'smart', 'pldt', 'maynilad']
    const dispatch    = useDispatch();
    const landingPage = useSelector(selectLandingPage);
    
    const successModal                      = useState(false);
    const errorModal                        = useState(false);
    const [email, setEmail]                 = useState('');
    const [validEmail, setValidEmail]       = useState(true);
    const [emptyEmail, setEmptyEmail]       = useState(false);
    const [gettingAccess, setGettingAccess] = useState(false);
    const [emailExists, setExists]          = useState(landingPage.checkEmailResult !== undefined && !landingPage.checkEmailResult);
    const [existingEmail, setExistingEmail] = useState('');
    const [verifyError, setVerifyError]     = useState(landingPage.invalidEmail);

    const Partners = () => (
        <section className="partners">
        {
            partners.map(partner =>
                <a href={`/${partner.slug}`} title={partner.name} target="_blank" rel="noreferrer">
                    <figure className="avatar">
                        <img src={partner.logo_url} alt={partner.name} /> 
                    </figure>
                </a>
            )
        }
        </section>
    )

    const getEarlyAccess = async () => {
        setEmptyEmail(!email);
        if (!email) return;
        setGettingAccess(true);
        setVerifyError(false)
        let isValid = validateEmail(email);
        setValidEmail(isValid);
        dispatch(landingPageActions.getEarlyAccess(email))
        // if (isValid) {
        //     verifyEmail(email, (result, error) => {
        //         if (result) {
        //             dispatch(landingPageActions.getEarlyAccess(email))
        //         } else {
        //             setVerifyError(error)
        //             setGettingAccess(false);
        //         }
        //     })
        // }
    }

    useEffect(() => {
        if (landingPage.signUpResult !== undefined) {
            setGettingAccess(false);
            if (!landingPage.signUpResult) {
                setExistingEmail(email);
                setExists(true);
            } else if (landingPage.signUpResult) {
                props.state[1](false);
                successModal[1](true);
                setEmail('');
            }
        }
    }, [landingPage.signUpResult])

    useEffect(() => {
        if (landingPage.invalidEmail !== undefined) {
            setVerifyError(landingPage.invalidEmail);
            setGettingAccess(false);
        }
    }, [landingPage.invalidEmail])

    return (<>
        <GreatModal state = {successModal} message = {<>You're on the waitlist.<br/>Please check your email</>}/>
        <Modal disableBackdropClick
            state = {props.state}
            style = 'early-access action-box content-box'
        >
            <img className="logo" src={logo} alt={process.env.APP_NAME}/>
            <h3>We are currently in our private beta.<br />Sign Up to get our invitation.</h3>
            <div jpt-form="invites" className="pseudo-form">
                <InputField loader
                    valid       = {!(emailExists || emptyEmail || verifyError) && !!email && validEmail}
                    error       = {emailExists || emptyEmail || !!verifyError || !validEmail}
                    value       = {email}
                    type        = "email"
                    name        = "email"
                    placeholder = "Your Email"
                    max         = {64}
                    onChange    = {e => {
                        setVerifyError(false)
                        setExists(existingEmail == e.target.value)
                        setEmail(e.target.value)
                        setEmptyEmail(!e.target.value)
                        setValidEmail(validateEmail(e.target.value));
                    }}
                    style       = {{inputContainer: 'icon icon-email'}}/>
                <div className="error-container active" data-error-container>
                    <small id="email-error" className="error">
                    {
                        emptyEmail ? 'Email is required':
                        !validEmail || verifyError ? 'Please enter a valid email address':
                        emailExists ? 'Email is already taken':''
                    }
                    </small>
                </div>
                <ButtonLoader submit hasLoader
                    loading = {gettingAccess}
                    name  = 'early-access'
                    style = 'filled blue'
                    text  = 'Get Early Access'
                    onClick = {() => getEarlyAccess()}
                />
            </div>
            <section className="divider"><span>OR</span></section>
            <h3>Start Paying Bills</h3>
            <Partners/>
            <p className="biller_link">
                <a href="#partners" className="close" onClick={() => props.state[1](false)}>
                    See all Billers
                </a>
            </p>
        </Modal>
    </>)
});

export default EarlyAccessModal;