import { CircularProgress } from '@material-ui/core';
import { selectUserInfo } from 'app/common/auth/selectors';
import ButtonLoader from 'app/components/ButtonLoader';
import InputField from 'app/components/InputField';
import ErrorModal from 'app/components/Modal/ErrorModal';
import SuccessModal from 'app/components/Modal/SuccessModal';
import { selectUserDashboard } from 'app/containers/UserDashboardPage/selectors';
import { userDashboardPageActions } from 'app/containers/UserDashboardPage/slice';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomizationField from './CustomizationField';
import Option from "./option";

const Customizations = memo(() => {
    const v2 = process.env.REACT_APP_USE_KEYCLOAK == 'True';

    const dispatch           = useDispatch();
    const username           = useSelector(selectUserInfo)?.username
    const userDashboardState = useSelector(selectUserDashboard);
    const customization      = userDashboardState.customizations;
    const is_bayadcenter     = username == "bayadcenter"

    const requiredMessage = "Fields Required"
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal]     = useState(false);
    const [errorMessage, setErrorMessage] = useState(requiredMessage);

    const [welcome_message, setWelcomeMessage]                = useState("");
    const [billers_welcome_message, setBillersWelcomeMessage] = useState("");

    const [option1, setOption1] = useState(new Option())
    const [option2, setOption2] = useState(new Option())
    const [option3, setOption3] = useState(new Option())

    const tryParse = (str: string) => {
        try {
            return JSON.parse(str)
        } finally {
            return str
        }
    }

    useEffect(() => setSuccessModal(userDashboardState.savingCustomization === false), [userDashboardState.savingCustomization])

    useEffect(() => {
        dispatch(userDashboardPageActions.getApiVersion())
        dispatch(userDashboardPageActions.getCustomization())
        dispatch(userDashboardPageActions.getCategories())
    }, [username])

    useEffect(() => {
        if (!v2 && customization['length'] > 0) {
            const welcome = tryParse(customization[0].field_value)
            setWelcomeMessage((is_bayadcenter? welcome.welcome_message: welcome) || '')
            setBillersWelcomeMessage((is_bayadcenter? welcome.billers_welcome_message: welcome) || '')
            setOption1(JSON.parse(customization[1].field_value))
            setOption2(JSON.parse(customization[2].field_value))
            setOption3(JSON.parse(customization[3].field_value))
        } else if (!Array.isArray(customization)) {
            if (is_bayadcenter) {
                setWelcomeMessage(customization['welcome_message']?.welcome_message);
                setBillersWelcomeMessage(customization['welcome_message']?.billers_welcome_message);
            } else {
                setWelcomeMessage(customization['welcome_message']);
            }
            setOption1(customization['options'][0])
            setOption2(customization['options'][1])
            setOption3(customization['options'][2])
        }
    }, [customization])

    useEffect(() => {
        dispatch(userDashboardPageActions.updateActionTime())
    }, [welcome_message, billers_welcome_message, option1, option2, option3])

    const update = () => {
        if (!validateClient()) return;
        if (
            validateField(option1) &&
            validateField(option2) &&
            validateField(option3)
        ) {
            const new_fields: any = []
            const welcome = is_bayadcenter? {welcome_message, billers_welcome_message}: welcome_message
            if (!v2) {
                new_fields.push({...customization[0], field_value: welcome})
                new_fields.push({...customization[1], field_value: option1})
                new_fields.push({...customization[2], field_value: option2})
                new_fields.push({...customization[3], field_value: option3})
            } else {
                new_fields.push({field_key: 'welcome_message', field_value: welcome})
                new_fields.push({field_key: 'option_1'       , field_value: {...customization['options'][0], ...option1}})
                new_fields.push({field_key: 'option_2'       , field_value: {...customization['options'][1], ...option2}})
                new_fields.push({field_key: 'option_3'       , field_value: {...customization['options'][2], ...option3}})
            }
            dispatch(userDashboardPageActions.updateCustomization(new_fields))
        } else {
            setErrorModal(true)
            setErrorMessage(requiredMessage)
        }
    }

    const validateClient = () => {
        let required_names: any[] = [],
            types   = [option1.type, option2.type, option3.type],
            options = [option1, option2, option3].reduce<any>((_, option, __, arr: any[]) => {
                if (option.type == 'field') {
                    arr.push(option.name)
                }
                return arr
            }, [])
        if (types.includes('field')) {
            switch (username) {
                case 'autosweeprfid': required_names = ['plate_number']; break;
                case 'beep'         : required_names = ['card_number']; break;
                // case 'bayadcenter'  : return names.includes('card_number');
                // case 'eastriprfid'  : return names.includes('card_number');
            }
            if (!required_names.every(name => options.includes(name))) {
                setErrorModal(true)
                setErrorMessage(`One or more required Field Name(s) is missing: ${JSON.stringify(required_names)}`)
                return false
            }
        }
        return true
    }
    
    const validateField = (field: Option) => {
        switch (field.type) {
            case 'static':
                return (
                    !!field.title &&
                    !!field.img_url
                )
            case 'field':
                return (
                    !!field.title &&
                    // !!field.icon &&
                    !!field.input_type &&
                    !!field.tooltip
                )
            case 'multiple':
                return (
                    !!field.title &&
                    // !!field.icon &&
                    !!field.input_type &&
                    !!field.tooltip
                )
            default: return true;
        }
    }

    return <>
        <div className="pseudo-form customizations">
            <section className="divider"><span>CUSTOMIZATIONS</span></section>
            {
                !customization || customization['length'] == 0 ? <CircularProgress/>: <>
                    <InputField
                        type        = "text"
                        label       = "Welcome Message"
                        placeholder = "Type here.."
                        value       = {welcome_message}
                        onChange    = {e => setWelcomeMessage(e.target.value)}
                    />
                    {is_bayadcenter && <InputField
                        type        = "text"
                        label       = "Welcome Message for the Billers"
                        placeholder = "Type here.."
                        value       = {billers_welcome_message}
                        onChange    = {e => setBillersWelcomeMessage(e.target.value)}
                    />}
                    <CustomizationField name="option_1" label="Field 1" option={option1} account="autosweeprfid" onChange={option => setOption1({...option1, ...option})}/>
                    <CustomizationField name="option_2" label="Field 2" option={option2} account="autosweeprfid" onChange={option => setOption2({...option2, ...option})}/>
                    <CustomizationField name="option_3" label="Field 3" option={option3} account="autosweeprfid" onChange={option => setOption3({...option3, ...option})}/>
                    <section className="footer">
                        <ButtonLoader submit hasLoader
                            loading = {userDashboardState.savingCustomization}
                            name  = "update_account"
                            style = "filled blue"
                            text  = "Update"
                            onClick = {update}
                        />
                    </section>
                </>
            }
        </div>
        <SuccessModal
            state     = {[successModal, setSuccessModal]}
            className = "customizations-success-modal larger_modal"
            message   = "You have successfully updated the fields."
            onClose   = {() => dispatch(userDashboardPageActions.resetCustomization())}
        />
        <ErrorModal
            state     = {[errorModal, setErrorModal]}
            className = "customizations-success-modal larger_modal"
            title     = "Error"
            message   = {errorMessage}
        />
    </>
})

export default Customizations;
