import { Grid, Typography } from "@material-ui/core";
import { WatchLater } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberFormat } from "utils";
import checkIcon from 'assets/img/success.png'
import Sales from "./Sales";
import { Link } from "react-router-dom";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import BarChart from "../Analytics/BarChart";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";

const PartnerOverview = React.memo(() => {
    const dispatch         = useDispatch();
    const dashboard        = useSelector(selectUserDashboard);
    const current_datetime = moment();

    const v2 = process.env.REACT_APP_USE_KEYCLOAK == 'True';
    const {lastDeposit, nextDeposit} = dashboard?.overview;

    return <>
        <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8} className="item-grid partial">
                <Sales current_datetime={current_datetime} component/>
            </Grid>
            <Grid item xs={12} md={4} className="item-grid partial">
                <Typography variant="body1" color="textSecondary">Deposited {lastDeposit?.date}</Typography><br />
                <Typography variant="h2"><img src={checkIcon} width="25px"/> ₱{ numberFormat(lastDeposit?.amount || 0) }</Typography><br /><br />
                <Typography variant="body1" color="textSecondary">Expected {nextDeposit?.date}</Typography><br />
                <Typography variant="h2"><WatchLater style={{fontSize: "28px", color: '#777', marginBottom: '5px'}}/> ₱{ numberFormat(nextDeposit?.amount || 0) }</Typography><br /><br />
                {v2 && <span><Link to="/user-dashboard/analytics" className="current" onClick={() => dispatch(userDashboardPageActions.updateActionTime())}>View Analytics</Link></span>}
                <p></p>
            </Grid>
        </Grid>
        {v2 && <BarChart component/>}
    </>
})

export default PartnerOverview;