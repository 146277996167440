import ButtonLoader                 from "app/components/ButtonLoader";
import InputField                   from "app/components/InputField";
import Modal, { DefaultModalProps } from "app/components/Modal";
import SocialLogin                  from "app/components/SocialLogin";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMobile } from "utils";
import { social_medias } from "utils/options";
import { selectIP, selectSignUpState } from "../selectors";
import { signUpActions } from "../slice";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useStyles } from "./styles";

const IndividualModal = memo(({state}: DefaultModalProps) => {
    const dispatch = useDispatch();
    const classes  = useStyles();
    const ip_info  = useSelector(selectIP);
    const signUp   = useSelector(selectSignUpState);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        await executeRecaptcha('homepage');
    }, []);
  
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const [token, setToken] = useState('');

    const required_field = "This field is required";
    const invalid_format = "Invalid Format";
    const [errorFirstName   , setErrorFirstName]    = useState(false);
    const [errorLastName    , setErrorLastName]     = useState(false);
    const [errorMobileNumber, setErrorMobileNumber] = useState(false);
    
    const [firstName   , setFirstName]    = useState(signUp.firstName);
    const [lastName    , setLastName]     = useState(signUp.lastName);
    const [mobileNumber, setMobileNumber] = useState(signUp.user_info?.mobile_number?.number || '');

    const validateFields = () => {
        setErrorFirstName   (!firstName);
        setErrorLastName    (!lastName);
        setErrorMobileNumber(!mobileNumber || mobileNumber.length != 11);
        return (
            firstName &&
            lastName  &&
            mobileNumber &&
            mobileNumber.length == 11
        )
    }

    const submit = () => {
        handleReCaptchaVerify();

        if (!validateFields()){ return;}

        dispatch(signUpActions.setAccountType('individual'))
        dispatch(signUpActions.setBasicInfo({
            firstName, lastName, mobileNumber: {
                prefix: "+63",
                number: mobileNumber
            }
        }))
        dispatch(signUpActions.nextStep())
        dispatch(signUpActions.saveState())
    }
    return (
        <Modal
            disableBackdropClick
            disableEscapeKeyDown
            state                = {state}
            containerClassname   = {`modal-container account-selection`}
            dialogClassname      = {classes.modal}
            modalClassname       = {classes.modalContainer}
            closeButtonClassname = {classes.modalCloseButton}
        >
            <div className="pseudo-form" jpt-form="individual_form">
                <section>
                    <h1 className={classes.modalHeader}>Sign up for free!</h1>
                    <p className={classes.modalSubheader}>We're glad you're ready to join JustPayto.<br/>Create your account and start receiving money conveniently.</p>
                </section>
                <section className={classes.modalSocialMediaContainer}>
                    <h3 className={classes.modalFormHeader}>Sign up with your social media account</h3>
                    {Object.keys(social_medias).map(key => <SocialLogin social={key} className={classes.modalSocialMediaButton}/>)}
                </section>
                <section className={classes.modalDividerContainer}>
                    <span className={classes.modalDivider}>OR</span>
                </section>
                <section className={classes.modalForm}>
                    <h3 className={classes.modalFormHeader}>Create an account</h3>
                    <InputField
                        type  = "text"
                        name  = "first_name"
                        label = "First Name"
                        style = {{
                            container: classes.modalFormInputContainer,
                            input    : classes.modalFormInput
                        }}
                        placeholder   = "Juan"
                        error         = {errorFirstName}
                        value         = {firstName}
                        error_message = {errorFirstName ? required_field : ''}
                        onChange      = {e => {
                            setFirstName(e.target.value)
                            setErrorFirstName(e.target.value.trim() === '')
                        }}
                    />
                    <InputField
                        type  = "text"
                        name  = "last_name"
                        label = "Last Name"
                        style = {{
                            container: classes.modalFormInputContainer,
                            input    : classes.modalFormInput
                        }}
                        placeholder   = "Dela Cruz"
                        error         = {errorLastName}
                        value         = {lastName}
                        error_message = {errorLastName ? required_field : ''}
                        onChange      = {e => {
                            setLastName(e.target.value)
                            setErrorLastName(e.target.value.trim() === '')
                        }}
                    />
                    <InputField readonly
                        type  = 'email'
                        name  = 'email'
                        label = 'Your Email'
                        style = {{
                            container: `valid ${classes.modalFormInputContainer}`,
                            input    : classes.modalFormInput
                        }}
                        value = {signUp.email}
                    />
                    <InputField
                        type  = "mobile"
                        name  = "mobile_number"
                        label = "Mobile Number"
                        style = {{
                            container: classes.modalFormInputContainer,
                            input    : classes.modalFormInput
                        }}
                        country       = "PH"                                                                                               //{ip_info?.country_code}
                        placeholder   = "912 345 6789"
                        value         = {formatMobile(mobileNumber.substring(1))}
                        error         = {errorMobileNumber}
                        error_message = {errorMobileNumber ? (!mobileNumber || mobileNumber == '0'? required_field: invalid_format) : ''}
                        onChange      = {e => {
                            const value = "0"+e.target.value.replaceAll(' ', '').trim();
                            setMobileNumber(value)
                            setErrorMobileNumber(value === '' || value.length != 11)
                        }}
                    />
                    <ButtonLoader hasLoader submit
                        name  = 'business_form_submit'
                        style = {`filled blue ${classes.button} ${classes.modalButton}`}
                        text  = 'Continue'
                        onClick = {() => submit()}
                    />
                    <div className={classes.modalFooter}>
                        By clicking "Continue" you agree to JustPayto's
                        <a target="pdf-frame" href={`${process.env.REACT_APP_S3_JUSTPAYTO_WEB_ASSETS_URL}/terms-of-service.pdf`} className={classes.modalLinks}> Terms of Service</a> and
                        <a target="pdf-frame" href={`${process.env.REACT_APP_S3_JUSTPAYTO_WEB_ASSETS_URL}/privacy-policy.pdf`} className={classes.modalLinks}> Privacy Policy</a>.
                    </div>
                </section>
            </div>
        </Modal>
    )
})

export default IndividualModal;