import { PayloadAction }                            from '@reduxjs/toolkit';
import { delay, call, put, takeLatest }             from 'redux-saga/effects';
import { request }                                  from 'utils/request';
import { CHECK_RESET_PASSWORD_KEY, RESET_PASSWORD } from 'app/common/core_api/resources';
import { ResetPasswordPageActions }                 from './slice';

export function* checkKey(action: PayloadAction<{
    username: string,
    key     : String
}>) {
    try {
        let options: RequestInit = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(action.payload)
        }
        let result = yield call(request, CHECK_RESET_PASSWORD_KEY, options);
        yield put(ResetPasswordPageActions.validKey(result));
    } catch (err) {
        console.log(err)
        yield put(ResetPasswordPageActions.validKey({
            error : "An unexpected error occured",
            result: false
        }))
    }
}

export function* resetPassword(action: PayloadAction<{
    access_token?: string,
    key?         : string | null,
    username?    : string | null,
    newPassword  : String
}>) {
    try {
        let options: RequestInit = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(action.payload)
        }
        let result = yield call(request, RESET_PASSWORD, options);
        yield put(ResetPasswordPageActions.successReset(result));
    } catch (err) {
        console.log(err)
        yield put(ResetPasswordPageActions.errorReset({
            error : "An unexpected error occured",
            result: false
        }))
    }
}

function* forgotPasswordSaga() {
    yield takeLatest(ResetPasswordPageActions.checkKey.type, checkKey);
    yield takeLatest(ResetPasswordPageActions.resetPassword.type, resetPassword);
}

export default forgotPasswordSaga;