import { numberFormat } from "utils";

const useApiHeader = (transaction) => {
    let title, detailStatus, details, amount,
        color = 'black',
        amount_details = transaction?.amount_details,
        transactionType = transaction?.intent || '',
        types = {
            cash_in           : 'Cash-In',
            cash_out          : 'Cash-Out',
            top_up            : 'Top-Up',
            deposit           : 'Deposit',
            send_money        : 'Send Money',
            account_activation: 'Account Activation',
            payment           : 'Payment',
        },
        type = transactionType in types? types[transactionType]: "Transaction",
        endUserName = {
            cash_in : transaction?.sender_details?.name,
            cash_out: transaction?.receiver_details?.name,
        };
    let status = transaction?.is_onhold? 'hold': transaction?.status || 'default';
    switch (status) {
        case 'complete':
            detailStatus = 'Successful';
            title = `${type} Completed`;
            color = 'green';
        break;
        case 'invalid':
        case 'expired':
        case 'reject':
            detailStatus = 'Failed';
            title = `${type} Failed`;
            color = 'red';
        break;
        case 'verified':
            detailStatus = 'Pending';
            title = `For Settlement`;
            color = 'blue';
        break;
        case 'pending_payment':
            detailStatus = 'Pending';
            title = `${type} Pending`;
            color = 'yellow';
        break;
        case 'review':
            detailStatus = 'Pending';
            title = `${type} under review`;
            color = 'yellow';
        break;
        case 'hold':
            detailStatus = 'Transaction on Hold';
            title = `${type} under review`;
            color = 'yellow';
        break;
    }
    const templatePerTypes = {
        cash_in : ":status cash-in of :amount from :name",
        cash_out: ":status cash-out of :amount to :name :depository :account_number",
        deposit : ":status deposit of :amount to :depository account :account_number",
        top_up  : ":status top-up of :amount to Pre-funded Wallet",
    }
    amount = (
        amount_details?.currency == 'PHP'?
        `₱ ${numberFormat(transaction?.payout_amount || 0)}`:
        `${numberFormat(transaction?.payout_amount || 0)} ${amount_details?.currency}`
    );
    details = (
        (templatePerTypes[transactionType] || '')
            .replace(':status', detailStatus)
            .replace(':amount', amount)
            .replace(':name', endUserName[transactionType])
            .replace(':depository', (
                transaction?.receiver_details?.bank_details?.name ||
                transaction?.receiver_details?.bank_details?.bank
            ))
            .replace(':account_number', transaction?.receiver_details?.bank_details?.account_number)
    );

    return {title, details, color}
}

export default useApiHeader;