import React, { memo, useState, useEffect } from "react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectUserInfo  } from "app/common/auth/selectors";
import { useQuery } from "utils/useQuery";
import { selectResetPasswordPage } from "./selectors";
import { ResetPasswordPageActions, sliceKey, reducer } from "./slice";
import saga from "./saga";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";

// Components
import MasterPage   from "../../MasterPage";
import ButtonLoader from "app/components/ButtonLoader";
import InputField   from "app/components/InputField";
import ErrorModal   from "app/components/Modal/ErrorModal";

// Assets
import success_icon from 'assets/img/success.svg';
import bg   from 'assets/img/background/justpayto-philippines-payments-3.jpg';
import logo from 'assets/img/landing-page/jpt-logo.svg';

// Styles
import 'styles/sass/login.scss';

const ForgotPassword = memo(() => {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga });
    const dispatch           = useDispatch();
    const history            = useHistory();
    const user               = useSelector(selectUserInfo);
    const auth               = useSelector(selectAuth);
    const resetPasswordState = useSelector(selectResetPasswordPage);

    const [reseting, setReseting]         = useState(false);
    const [success, setSuccess]           = useState(false);
    // const [oldPass, setOldPass]           = useState('');
    const [newPassword, setNewPassword]   = useState('');
    const [repeatPass, setRepeatPass]     = useState('');
    const [oldPassEmpty, setOldPassEmpty] = useState(false);
    const [newPassEmpty, setNewPassEmpty] = useState(false);
    const [matchedPass, setMatchedPass]   = useState(true);
    const errorModalState                 = useState(false);

    const query        = useQuery();
    const username     = query.get('username')
    const key          = query.get('key')
    const fromEmail    = username && key
    const access_token = auth?.access_token

    // Check valid KEY
    useEffect(() => {
        if (username && key) {
            dispatch(ResetPasswordPageActions.checkKey({username, key}));
        } else {
            dispatch(ResetPasswordPageActions.validKey({result: true}));
        }
    }, [username, key])

    // Check valid KEY Results
    useEffect(() => {
        if (
            resetPasswordState.validKey !== undefined &&
            resetPasswordState.validKey !== null &&
            !resetPasswordState.validKey &&
            resetPasswordState.error
        ) {
            history.push('/login')
        }
    }, [resetPasswordState.validKey, resetPasswordState.error])

    // Reset Password Results
    useEffect(() => {
        if (
            resetPasswordState.result !== undefined &&
            resetPasswordState.result !== null &&
            !resetPasswordState.result
        ) {
            errorModalState[1](true);
        } else if (resetPasswordState.result) {
            setSuccess(true);
        }
    }, [resetPasswordState.result, resetPasswordState.error])

    const resetPass = () => {
        setReseting(true);
        setNewPassEmpty(!newPassword);
        // setOldPassEmpty(!oldPass);
        setMatchedPass(newPassword === repeatPass);
        // const validOldPass = !auth?.access_token || (auth?.access_token && oldPass)
        if (newPassword && newPassword === repeatPass) {
            dispatch(ResetPasswordPageActions.resetPassword({
                key, username, newPassword, access_token
            }))
        } else {
            setReseting(false)
        }
    }

    const auth_json = localStorage.getItem('auth')
    const storage_access_token = auth_json? JSON.parse(auth_json).access_token: null

    return (
        !auth?.access_token && !fromEmail && !storage_access_token?
        <Redirect to="/login"/>: <>
        <ErrorModal state = {errorModalState} message = {resetPasswordState.error || ''}/>
        <MasterPage hasHeader
            title = "Reset Password"
            backgroundImage = {bg}
        >
            <section className="action-box-wrapper">
            {
                !success ?
                <section className="action-box content-box small">
                    <img className="logo" src={logo} alt={process.env.REACT_APP_NAME}/>
                    <div jpt-form="reset-password" className="pseudo-form">
                        <h1>Reset Password</h1>
                        {/* {auth?.access_token && (
                            <InputField loader
                                type        = 'password'
                                name        = 'old_password'
                                placeholder = 'Old Password'
                                style       = {{input: 'icon icon-password'}}
                                error = {oldPassEmpty}
                                value = {oldPass}
                                onChange = {e => {
                                    setOldPass(e.target.value)
                                    setOldPassEmpty(!e.target.value)
                                }}
                            />
                        )} */}
                        <InputField loader
                            type        = 'password'
                            name        = 'password'
                            placeholder = 'New Password'
                            style       = {{inputContainer: 'icon icon-password'}}
                            error = {newPassEmpty}
                            value = {newPassword}
                            onChange = {e => {
                                setNewPassword(e.target.value)
                                setNewPassEmpty(!e.target.value)
                            }}
                        />
                        <InputField loader
                            type        = 'password'
                            name        = 'password_confirmation'
                            placeholder = 'Repeat New Password'
                            style       = {{inputContainer: 'icon icon-password'}}
                            error = {!matchedPass}
                            value = {repeatPass}
                            onChange = {e => {
                                setRepeatPass(e.target.value)
                                setMatchedPass(e.target.value === newPassword)
                            }}
                        />

                        <div className="error-container" data-error-container>
                        {
                            auth?.access_token && oldPassEmpty ? "Old Password is required":
                            newPassEmpty ? "New password is required":
                            !matchedPass ? "Password does not match": ''
                        }
                        </div>
                        <section className="footer">
                            <ButtonLoader hasLoader
                                loading = {reseting}
                                name  = 'reset'
                                style = 'filled blue'
                                text  = 'Reset'
                                onClick = {resetPass}
                            />
                            <p className="blue pointer"><a href="mailto:hello@justpay.to">Contact Support</a></p>
                            {/* {auth?.access_token && <p className="blue pointer"><Link to="/user-dashboard">Back</Link></p>} */}
                        </section>
                    </div>
                </section> :
                <section className="action-box content-box small success">
                    <h1 className="center">Great</h1>
                    <img className="logo" src={success_icon} alt="Success Icon"/>
                    <h3 className="center">You have successfully updated<br/>your password.</h3>
                    <p className="blue pointer link">
                        {key?
                            <Link to="/login">Go to Log In</Link>:
                            <Link to="/user-dashboard">Go Back</Link>
                        }
                    </p>
                </section>
            }
            </section>
        </MasterPage>
    </>)
})

export default ForgotPassword;