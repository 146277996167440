import { createSelector } from '@reduxjs/toolkit';
import { RootState }      from 'types';
import { initialState }   from './slice';

const selectDomain = (state: RootState) =>
    state.paymentPageRequest || initialState;

export const paymentAttributes = createSelector(
    [selectDomain],
    paymentState => paymentState,
);
