import React, { memo } from 'react'

interface FilterProps {
    status   : boolean
    text     : string
    setFilter: () => void
}

const FilterButton = memo(({status, text, setFilter}: FilterProps) => (
    <button
        className = {status ? "active" : ""}
        onClick   = {() => setFilter()}
    >
        {text}
    </button>
))

export default FilterButton