import ButtonLoader from "app/components/ButtonLoader";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import { payment_methods, transaction_status } from "app/containers/UserDashboardPage/utils";
import React, { createRef, memo } from "react";
import ReactExport from 'react-data-export';
import { useDispatch } from "react-redux";
import { formatDatetime, numberFormat } from "utils";

const File   = ReactExport.ExcelFile;
const Sheet  = ReactExport.ExcelFile.ExcelSheet;

interface Props {
    data    : any[]
    filename: string
    total   : number
    loading?: boolean
}

const ExcelDownload = memo(({data, filename, total, loading}: Props) => {
    const dispatch = useDispatch();
    const style    = width => ({width: {wpx: width}})
    const intents  = {
        top_up  : "Top-Up",
        deposit : "Deposit",
        cash_in : "Cash-In",
        cash_out: "Cash-Out"
    }
    const hold = d => d?.additional_detail?.conditions?.is_onhold
    const endUser = (data) => {
        switch (data.intent) {
            case 'cash_out': return data.receiver_details;
        }
        return data.sender_details
    }
    const dataset  = [{
        columns: [
            {title: "Transaction Number"          , ...style(140)},
            {title: "Transaction Type"            , ...style(140)},
            {title: "Transaction Date"            , ...style(120)},
            {title: "Transaction Timestamp"       , ...style(160)},
            {title: "Status"                      , ...style(100)},
            {title: "Name"                        , ...style(180)},
            {title: "Email"                       , ...style(180)},
            {title: "Mobile Number"               , ...style(140)},
            {title: "Address"                     , ...style(190)},
            {title: "Nationality"                 , ...style(190)},
            {title: "Date of Birth"               , ...style(120)},
            {title: "Place of Birth"              , ...style(140)},
            {title: "Sex"                         , ...style(120)},
            {title: "Govt Issued ID"              , ...style(180)},
            {title: "Signature"                   , ...style(180)},
            {title: "Payment Method"              , ...style(130)},
            {title: "Settlement Account"          , ...style(140)},
            {title: "Account Name"                , ...style(140)},
            {title: "Account Number"              , ...style(130)},
            {title: "Reference Number"            , ...style(130)},
            {title: "Currency"                    , ...style(120)},
            {title: "Amount"                      , ...style(130)},
            {title: "System Fee"                  , ...style(120)},
            {title: "Bank Fee"                    , ...style(120)},
            {title: "Partner Fee"                 , ...style(120)},
            {title: "Other Charges"               , ...style(120)},
            {title: "Total Payout"                , ...style(130)},
        ],
        data: data.map(data => (endUser => [
            {value: data.payment_id || 'NA'},
            {value: intents[data.intent] || data.intent || 'NA'},
            {value: formatDatetime(data.updated_at, "MM/DD/YYYY") || 'NA'},
            {value: formatDatetime(data.updated_at, "H:mm:ss") || 'NA'},
            {value: (transaction_status[hold(data)? 'hold': data.status]) || 'NA'},
            {value: endUser.name || 'NA'},
            {value: endUser.email || 'NA'},
            {value: (
                typeof endUser?.mobile == 'string'?
                endUser?.mobile:
                `${endUser?.mobile?.prefix||''}${endUser?.mobile?.number||''}` || 'NA'
            )},
            {value: endUser.residential_address || 'NA'},
            {value: endUser.nationality || 'NA'},
            {value: endUser.birth_date || 'NA'},
            {value: endUser.birth_place || 'NA'},
            {value: endUser.sex || 'NA'},
            {value: endUser.document_meta?.front || 'NA'},
            {value: endUser.document_meta?.signature || 'NA'},
            {value: (
                ['top_up', 'deposit', 'cash_out'].includes(data.intent)? 'NA':
                payment_methods[data.source_of_funds?.payment_category] || 'NA'
            )},
            {value: data.intent != 'cash_in' && (
                data.receiver_details?.bank_details?.name ||
                data.receiver_details?.bank_details?.bank
            ) || 'NA'},
            {value: data.intent != 'cash_in' && data.receiver_details?.bank_details?.account_name || 'NA'},
            {value: data.intent != 'cash_in' && data.receiver_details?.bank_details?.account_number || 'NA'},
            {value: (
                data.additional_details?.payout_info?.reference_number ||
                data.additional_details?.payout_info?.disbursement_reference_number ||
                'NA'
            )},
            {value: data.amount_details?.currency || 'NA'},
            {value: numberFormat(+(data.amount_sent || 0))},
            {value: ['top_up', 'deposit'].includes(data.intent)? 'NA': numberFormat(+(data.fees?.system || 0))},
            {value: ['top_up', 'deposit'].includes(data.intent)? 'NA': numberFormat(+(data.fees?.sending?.fee || 0) + +(data.fees?.receiving?.fee || 0))},
            {value: ['top_up', 'deposit'].includes(data.intent)? 'NA': numberFormat(0)}, // Partner Fee
            {value: ['top_up', 'deposit'].includes(data.intent)? 'NA': numberFormat(+(data.other_charges || 0))},
            {value: numberFormat(+(data.payout_amount || 0))},
        ])(
            endUser(data)
        ))
    }]
    const ref = createRef<HTMLButtonElement>();
    return <>
        <File filename = {filename} element = {<button ref={ref}/>}>
            <Sheet dataSet={dataset} name="Transactions"/>
        </File>
        <ButtonLoader hasLoader
            loading = {loading}
            onClick = {() => ref.current?.click() && dispatch(userDashboardPageActions.updateActionTime())}
            style   = "filled blue"
            text    = {data.length != total ? `Download Selected (${data.length})` : 'Download'}
        />
    </>
})

export default ExcelDownload;