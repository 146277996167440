import { signUpActions } from "app/containers/Onboarding/slice";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { social_medias } from "utils/options";
import ButtonLoader from "../ButtonLoader";
import useFacebookLogin from "./useFacebookLogin";
import useLinkedInLogin from "./useLinkedIn";
import useTwitterLogin from "./useTwitterLogin";

interface Props {
    className?: string
    social    : string
    disabled? : boolean
}

const SocialLogin = memo(({social, className, disabled: readOnly}: Props) => {
    const dispatch = useDispatch();
    const twitter   = useTwitterLogin();
    const facebook  = useFacebookLogin();
    const linkedin  = useLinkedInLogin();

    const [disabled, setDisabled] = useState(readOnly);

    useEffect(() => setDisabled(readOnly), [readOnly]);

    useEffect(() => facebook.response && submitUserInfo(facebook.response), [facebook.response])

    const submitUserInfo = ({
        firstName,
        lastName,
        birthdate,
        profile_photo,
        social_media_links,
        username,
        description,
    }: any) => {
        dispatch(signUpActions.setAccountType('individual'))
        dispatch(signUpActions.setBasicInfo({firstName, lastName, birthdate}))
        dispatch(signUpActions.setSocialMedia(social_media_links || {}))
        dispatch(signUpActions.setAccountInformation({
            username: username || '',
            password: ''
        }))
        dispatch(signUpActions.setPaymentPage({
            description   : description || '',
            profile_photo : profile_photo || '',
            background_url: ''
        }))
        dispatch(signUpActions.nextStep())
        dispatch(signUpActions.saveState())
    }

    const onClick = () => {
        switch (social) {
            case 'facebook' : facebook.getBasicInfo(); break;
            case 'instagram': facebook.getInstagramInfo(); break;
            case 'twitter'  : twitter.login(); break;
            case 'linkedin' : linkedin.login(); break;
            default: break;
        }
    }

    return (
        <ButtonLoader
            disabled = {disabled}
            name     = {social}
            style    = {`filled social icon icon-${social} ${className}`}
            text     = {social_medias[social]}
            onClick  = {onClick}
        />
    )
})

export default SocialLogin;