
type OptionType = "static" | "field" | "disable" | "multiple"

export default class Option {
    type       : OptionType = "static"
    title      : string     = ""
    name       : string     = ""
    icon       : string     = ""
    img_url    : string     = ""
    input_type : string     = ""
    tooltip    : string     = ""
}
