import React from "react"

const Subdomain = ({name, children}) => {
    const current_subdomain = window.location.host.split('.')[0]

    // Get available subdomains depending on the current environment
    const env            = process.env.REACT_APP_ENV || 'prod'
    const subdomains     = ['home', 'onboarding', 'dashboard']
    const env_subdomains = {
        prod   : subdomains.concat(['www']),
        staging: subdomains.map(subdomain => `${subdomain}-staging`).concat(['staging']),
        dev    : subdomains.map(subdomain => `${subdomain}-dev`).concat(['dev']),
        local  : subdomains.map(subdomain => `${subdomain}-local`).concat(['local']),
    }[env]

    let env_name = 'www'
    switch (name) {
        case 'www':
            switch (env) {
                case 'prod': break;
                default:
                    env_name = env;
            }
        break;
        default:
            switch (env) {
                case 'prod':
                    env_name = name;
                break;
                default:
                    env_name = `${name}-${env}`;
            }
    }

    // Redirect if not on the proper subdomain
    if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
        if (!env_subdomains?.includes(current_subdomain)) {
            window.location.href = window.location.href.replace(window.location.host, `${env_subdomains?.at(-1)}.${window.location.host}`)
            return <></>
        }
        else if (current_subdomain != env_name) {
            window.location.href = window.location.href.replace(current_subdomain, env_name)
            return <></>
        }
    }
    return <>{children}</>
}

export default Subdomain