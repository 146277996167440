import { RootState } from 'types/RootState';
import { encryptedRequest, request } from 'utils/request';
import { paymentPageActions } from './slice';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import {
    call,
    put,
    select,
    takeLeading,
} from 'redux-saga/effects';
import {
    configHeaders,
    configCustomHeaders
} from 'utils/request-config';
import {
    KEYCLOAK_PEER_TO_PEER_USERS,
    GET_MERCHANT_ID,
    GET_CARD_PROCESSOR,
    GET_DEPOSITORY_ACCOUNT,
    GET_DISBURSEMENT_STATUS,
    GET_REALM,
    KEYCLOAK_MASTER_TOKEN,
} from 'app/common/core_api/resources';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchUserInformation(identifier: string, options?: object) {
    switch (identifier) {
        case 'user_attribute':
            let userName: string    = yield select((state: RootState) => state?.paymentPage?.username),
                userGroups: any[]   = [],
                accountType         = 'individual',
                accountLabel        = 'Standard',
                groupsAssigned      = [] as any,
                associatedGroup     = [] as any,
                clientFullContract  = ['autosweeprfid', 'bayadcenter', 'beep', 'easytriprfid'],
                realm               = yield call(request, `${GET_REALM}?username=${userName}`),
                userInformation     = realm ? yield call(request, `${KEYCLOAK_PEER_TO_PEER_USERS.replace('peer-to-peer', realm)}?exact=true&username=${userName}`, options) : null;

            if (!realm || (userInformation[0].username !== userName)) {
                window.location.href = `${window.location.origin}/early-access?username=${userName}&status=not_found`;
            }

            switch (realm) {
                case 'bayadcenter':
                    const response = yield call(request, `${KEYCLOAK_PEER_TO_PEER_USERS}?username=bayadcenter`, options);
                    const {id, username, email, attributes} = response?.[0];

                    if (userInformation[0]) {
                        userInformation[0] = {...userInformation[0],
                            partnerUsername : username,
                            email           : email,
                            attributes      : {
                                ...userInformation[0].attributes,
                                account_type        : attributes?.account_type,
                                charge_fees_to      : attributes?.charge_fees_to,
                                mobile_number       : attributes?.mobile_number,
                                address             : attributes?.address,
                                pickup_location     : attributes?.pickup_location,
                                defined_amount      : attributes?.defined_amount,
                                depository_details  : attributes?.depository_details,
                                disbursement        : attributes?.disbursement,
                                fields              : attributes?.fields,
                                fulfillment_methods : attributes?.fulfillment_methods,
                                payment_methods     : attributes?.payment_methods,
                                is_sms_enabled      : attributes?.is_sms_enabled,
                                is_revoked          : attributes?.is_revoked,
                                registration_status : attributes?.registration_status,
                                status              : attributes?.status,
                            },
                        };
                    }

                    userGroups = yield call(request, `${KEYCLOAK_PEER_TO_PEER_USERS}/${id}/groups?briefRepresentation=false`, options);
                break;
                case 'gas-station':
                    let getUsername = ['petron', 'shell', 'caltex'].find(user => userName.includes(user));

                    if (getUsername) {
                        const response = yield call(request, `${KEYCLOAK_PEER_TO_PEER_USERS}?username=${getUsername}`, options);
                        const {id, username, email, attributes} = response?.[0];

                        if (userInformation[0]) {
                            userInformation[0] = {
                                partnerUsername : username,
                                email           : email,
                                attributes      : {
                                    ...attributes,
                                    ...userInformation[0].attributes,
                                },
                                ...userInformation[0],
                            };
                        }

                        userGroups = yield call(request, `${KEYCLOAK_PEER_TO_PEER_USERS}/${id}/groups?briefRepresentation=false`, options);
                    }
                break;
                default:
                    userGroups = yield call(request, `${KEYCLOAK_PEER_TO_PEER_USERS}/${userInformation?.[0]?.id}/groups?briefRepresentation=false`, options);
            }

            // Identifies Account Type
            for (let groupName of ['Partners', 'Premium', 'Standard']) {
                let selectedGroup = userGroups.find(group => group.name == groupName);

                if (selectedGroup) {
                    associatedGroup.push(selectedGroup);

                    accountLabel = groupName;

                    break;
                }
            }

            // Identifies Group
            for (let groupName of ['Bayadcenter', 'Beep', 'AutosweepRFID', 'EasytripRFID', 'Gas Station', 'Water Utilities']) {
                let selectedGroup = userGroups.find(group => group.name == groupName);

                if (selectedGroup) {
                    associatedGroup.push(selectedGroup);
                    groupsAssigned.push(groupName);
                }
            }

            // Formats User's attributes. Specifically removes `user_info.` prefix
            for (const [key] of Object.entries(userInformation[0]['attributes'])) {
                let formattedKey = key.replace('user_info.', '');

                if (formattedKey != key) {
                    delete Object.assign(userInformation[0]['attributes'], {[formattedKey]: userInformation[0]['attributes'][key] })[key];
                }
            }

            // Formats group attributes if exists. Specifically removes `user_info.` prefix
            for (const group in associatedGroup) {
                let selectedGroupAttributes = associatedGroup[group]['attributes'] as object;

                for (const [key] of Object.entries(selectedGroupAttributes)) {
                    let formattedKey = key.replace('user_info.', '');

                    if (formattedKey != key) {
                        delete Object.assign(selectedGroupAttributes, {[formattedKey]: selectedGroupAttributes[key] })[key];
                    }
                }

                // Parse array to get first item as the valid value for `selectedGroupAttributes` attributes
                Object.keys(selectedGroupAttributes).map(index => {
                    try {
                        selectedGroupAttributes[index] = JSON.parse(selectedGroupAttributes[index][0]);
                    } catch (e) {
                        selectedGroupAttributes[index] = selectedGroupAttributes[index][0];
                    }

                    return null;
                });

                // Parse array to get first item as the valid value for `userInformation` attributes
                Object.keys(userInformation[0]['attributes']).map(index => {
                    if (parseInt(group) <= 0) {
                        try {
                            userInformation[0]['attributes'][index] = JSON.parse(userInformation[0]['attributes'][index][0]);
                        } catch (e) {
                            userInformation[0]['attributes'][index] = userInformation[0]['attributes'][index]?.[0] || '';
                        }
                    }

                    if (typeof userInformation[0]['attributes'][index] !== 'string') {
                        userInformation[0]['attributes'][index] = {...selectedGroupAttributes[index], ...userInformation[0]['attributes'][index]};
                    }

                    return null;
                });

                // Merges Group Attribute with User Attribute
                userInformation[0]['attributes'] = {...selectedGroupAttributes, ...userInformation[0]['attributes']};
            }

            // Identifying Account Type (with clients)
            if (clientFullContract.includes(userInformation[0].partnerUsername || userInformation[0].username)) {
                accountLabel    = 'Premium';
                accountType     = 'client';
            } else if (groupsAssigned.length) {
                accountType = 'client';
            } else {
                switch (userInformation[0]['attributes']['account_type']['type']) {
                    case 'individual': accountType = 'individual'; break;
                    case 'business':
                        accountType = 'business';

                        switch (userInformation[0]['attributes']['account_type']['business']['type']) {
                            case 'non_profit_organizations': accountType = 'donation'; break;
                        }
                    break;
                }
            }

            userInformation[0] = {...userInformation[0], ...{
                accountLabel    : accountLabel,
                accountType     : accountType,
                groups          : groupsAssigned,
            }};

            return userInformation;
        default:
            return null;
    }
}

function* identifyingMerchantIDByAccountType(userInformation: any) {
    let accountType = 'individual';

    switch (userInformation[0]['attributes']['account_type']['type']) {
        case 'individual': accountType = 'individual'; break;
        case 'business':
            accountType = 'business';

            switch (userInformation[0]['attributes']['account_type']['business']['type']) {
                case 'non_profit_organizations': accountType = 'donation'; break;
                default:
                    switch (userInformation[0]['attributes']['account_type']['business']['category']) {
                        case 'airlines': case 'travel': case 'transportation': accountType = 'transportation'; break;
                        case 'gas_station': accountType = 'gas_station'; break;
                        case 'collectibles': case 'clothing': case 'entertainment': case 'retail': accountType = 'online_retail'; break;
                        case 'electronics': case 'technology': accountType = 'electronics'; break;
                        case 'finance': case 'personal_care': case 'professional_services': accountType = 'professional_services'; break;
                        case 'food_drinks': accountType = 'fastfood'; break;
                        case 'lodging': case 'real_estate': accountType = 'restaurants'; break;
                        case 'education': accountType = 'education'; break;
                        case 'telecommunications': case 'utilities': accountType = 'utilities'; break;
                        case 'others': accountType = 'others'; break;
                        default: accountType = 'business';
                    }
            }
        break;
    }

    return yield call(request, `${GET_MERCHANT_ID}?account_type=${accountType}`);
}

export function* getUserInformation() {
    try {
        let peerToPeerRequest: RequestInit = {
            method  : 'POST',
            headers : configCustomHeaders({
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/x-www-form-urlencoded',
            }),
            body    : new URLSearchParams({
                grant_type      : 'client_credentials',
                client_id       : `${process.env.REACT_APP_OIDC_CLIENT_ID}`,
                client_secret   : `${process.env.REACT_APP_OIDC_CLIENT_SECRET}`,
            }).toString(),
        };

        const peerToPeerAccessToken = yield call(request, KEYCLOAK_MASTER_TOKEN, peerToPeerRequest);

        let peerToPeerOptions: RequestInit = {
            headers: configHeaders(peerToPeerAccessToken.access_token),
        };

        const userInformation   = yield fetchUserInformation('user_attribute', peerToPeerOptions);
        const depositoryAccount = yield call(request, `${GET_DEPOSITORY_ACCOUNT}?code=${userInformation[0]['attributes']['depository_details']['bank']}`);
        const merchantID        = yield identifyingMerchantIDByAccountType(userInformation);
        const cardProcessor     = yield call(request, `${GET_CARD_PROCESSOR}?merchant_id=${merchantID}`);

        yield put(paymentPageActions.getUserInformation({
            accessToken : peerToPeerAccessToken.access_token,
            user        : userInformation[0],
            loading     : false,
            coreData    : {
                depositoryAccount   : depositoryAccount,
                merchantID          : merchantID,
                cardProcessor       : {
                    ...cardProcessor,
                    ...{ version: merchantID.slice(-2) == 'v2' ? 'v2' : 'v1' },
                },
            },
        }));
    } catch (err) {
        if (err) {
            yield put(paymentPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR));
        }
    }
}

export function* getDisbursementStatus(action: PayloadAction<any>) {
    if (action.payload !== undefined) {
        return;
    }

    try {
        let user        = yield select((state: RootState) => state?.paymentPage?.user),
            category    = user?.attributes?.account_type?.type == 'individual' ? 'individual' : (user?.attributes?.account_type?.business?.category || 'individual'),
            response    = yield call(encryptedRequest, `${GET_DISBURSEMENT_STATUS}?category=${category}`);

        yield put(paymentPageActions.getDisbursementStatus(JSON.parse(response)));
    } catch  (err) {
        if (err) {
            yield put(paymentPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR));
        }
    }
}

export function* paymentPageSaga() {
    yield takeLeading(paymentPageActions.getAccessToken.type, getUserInformation);
    yield takeLeading(paymentPageActions.getDisbursementStatus.type, getDisbursementStatus);
}
