import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContactlessInfo, SocialMediaLinks } from "../Onboarding/types";
import UserDashboardState, { DocumentMeta, LineGraphFilter, MobileNumber, TransactionData, UpdateSettingsPayload } from "./types";

export const initialState: UserDashboardState = {
    tab                 : 'overview',
    customizations      : [],
    api_version         : "1",
    barchartTransactions: []
}

const userDashboardPageSlice = createSlice({
    name: 'userDashboardPage',
    initialState,
    reducers: {
        updateActionTime(state) {
            state.lastActionTime = moment().add(15, 'minutes')
        },
        removeActionTime(state) {
            state.lastActionTime = undefined
        },
        setTab(state, action: PayloadAction<string>) {
            state.tab = action.payload
            state.lastActionTime = moment().add(15, 'minutes')
        },
        setTwoFAStatus(state, action: PayloadAction<string>) {},
        setSMSNotifStatus(state, action: PayloadAction<string>) {},
        success(state) {
            state.result = true;
        },
        error(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.result = false;
        },
        updateUser(state, action: PayloadAction<{username: string, data: object}>) {},
        successUpdateUser(state) {
            state.updateUserResult = true
        },
        failedUpdateUser(state) {
            state.updateUserResult = false
        },
        resetUpdateUser(state) {
            state.updateUserResult = undefined
        },
        getTransactions(state, action: PayloadAction<{
            filter?: string,
            start?: string,
            end?: string
        } | undefined>) {
            state.transactions = undefined;
            if (action.payload) {
                state.transactionQuery = action.payload;
            }
        },
        loadTransactions(state, action: PayloadAction<TransactionData[]>){
            state.transactions = action.payload;
        },
        getOverview(state) {
            state.overview = undefined;
        },
        setOverview(state, action: PayloadAction<any>) {
            state.overview = action.payload;
        },
        getBarchartTransactions(state, action: PayloadAction<LineGraphFilter>) {},
        loadBarchartTransactions(state, action: PayloadAction<object[]>) {
            state.barchartTransactions = action.payload
        },
        updateAccount(state, action: PayloadAction<{
            name?              : string,
            address?           : string,
            pickup_location?   : string,
            mobile_number?     : MobileNumber,
            description?       : string,
            profile_photo?     : string,
            background_url?    : string,
            social_media_links?: SocialMediaLinks,
            document_meta?     : DocumentMeta,
            contactless?       : ContactlessInfo
        }>) {
            state.lastActionTime = moment().add(15, 'minutes')
        },
        loadLineGraphs(state, action: PayloadAction<LineGraphFilter>) {},
        loadGrossSales(state, action: PayloadAction<object[]>) {
            state.grossSales = action.payload
        },
        loadNewCustomers(state, action: PayloadAction<object[]>) {
            state.newCustomers = action.payload
        },
        loadNetVolumes(state, action: PayloadAction<object[]>) {
            state.netVolumes = action.payload
        },
        loadAvgRevenues(state, action: PayloadAction<object[]>) {
            state.avgRevenues = action.payload
        },
        loadCompletedTransaction(state, action: PayloadAction<object[]>) {
            state.completedTransactions = action.payload
        },
        loadVerifiedTransactions(state, action: PayloadAction<object[]>) {
            state.verifiedTransactions = action.payload
        },
        loadPendingTransactions(state, action: PayloadAction<object[]>) {
            state.pendingTransactions = action.payload
        },
        loadOnHoldTransactions(state, action: PayloadAction<object[]>) {
            state.onHoldTransactions = action.payload
        },
        loadFailedTransactions(state, action: PayloadAction<object[]>) {
            state.failedTransactions = action.payload
        },
        updateSettings(state, action: PayloadAction<UpdateSettingsPayload>) {
            state.updatingSettings = true
            state.lastActionTime = moment().add(15, 'minutes')
        },
        resetUpdateSettings(state) {
            state.updatingSettings = undefined
        },
        settingsUpdated(state) {
            state.updatingSettings = false
        },
        resetGraphs(state) {
            state.lastActionTime = moment().add(15, 'minutes')
            state.grossSales            = undefined
            state.newCustomers          = undefined
            state.netVolumes            = undefined
            state.avgRevenues           = undefined
            state.completedTransactions = undefined
            state.verifiedTransactions  = undefined
            state.pendingTransactions   = undefined
            state.onHoldTransactions    = undefined
            state.failedTransactions    = undefined
        },
        getLastPayoutDate(state) {},
        setLastPayoutDate(state, action: PayloadAction<string>) {
            state.lastPayoutDate = action.payload
        },
        updateDelivery(state, action: PayloadAction<{transactionNo: string, action: string}>) {},
        updateTransaction(state, payload_action: PayloadAction<{transactionNo: string, action: string}>) {
            const {transactionNo, action} = payload_action.payload;
            const transactions            = [...(state.transactions || [])];
            const index                   = transactions?.findIndex(transaction => transaction.transactionNo == transactionNo);
            if (transactions && index != -1) {
                transactions[index].delivery_status = (
                    action == "cancel"  ? "canceled":
                    action == "order"   ? "for_pickup":
                    action == "pick-up" ? "for_delivery":
                    action == "complete"? "delivered":
                    "not_ready"
                )
            }
            state.transactions = transactions
        },
        sendOtp(state, action: PayloadAction<string>) {},
        validateOtp(state, action: PayloadAction<{
            otp      : string,
            action   : string,
            callback?: (data?: any) => void
        }>) {},
        reactivate(state, action: PayloadAction<(data?: any) => void>) {},
        getCustomization(state) {
            state.customizations = []
        },
        loadCustomization(state, action: PayloadAction<any[]>) {
            state.customizations = action.payload
        },
        updateCustomization(state, action: PayloadAction<any[]>) {
            state.savingCustomization = true
            state.lastActionTime = moment().add(15, 'minutes')
        },
        savedCustomization(state) {
            state.savingCustomization = false
        },
        resetCustomization(state) {
            state.savingCustomization = undefined
        },
        getCategories(state) {
            state.categories = undefined
        },
        setCategories(state, action: PayloadAction<any[]>) {
            state.categories = action.payload
        },
        getApiVersion(state) {
            state.api_version = "1"
        },
        setApiVersion(state, action: PayloadAction<string>) {
            state.api_version = action.payload
        },
        addCategories(state, action: PayloadAction<any[]>) {
            state.addingMultiInput = true
        },
        addSubcategories(state, action: PayloadAction<any[]>) {
            state.addingMultiInput = true
        },
        savedMultiInput(state) {
            state.addingMultiInput = false
        },
        resetMultiInput(state) {
            state.addingMultiInput = undefined
        },
        failedMultiInput(state, action: PayloadAction<any[]>) {
            state.existingBillers = action.payload
        },
        removeCategories(state) {
            state.removingCategories = true
            state.lastActionTime = moment().add(15, 'minutes')
        },
        removedCategories(state) {
            state.removingCategories = false
        },
        resetRemovingCategories(state) {
            state.removingCategories = undefined
        },
        setChangePlan(state, action: PayloadAction<boolean | undefined>) {
            state.changePlanModal = action.payload === undefined? !state.changePlanModal: action.payload
            state.lastActionTime = moment().add(15, 'minutes')
        },
        changePlan(state, action: PayloadAction<string>) {},
        getSales(state) {},
        setSales(state, action: PayloadAction<any[]>) {
            state.salesData = action.payload;
        },
        getAnalytics(state) {},
        setAnalytics(state, action: PayloadAction<any[]>) {
            state.analyticsData = action.payload;
        },
        getLineGraphs(state, action: PayloadAction<LineGraphFilter>) {},
    }
});

export const {
    actions: userDashboardPageActions,
    reducer,
    name: sliceKey,
} = userDashboardPageSlice;
