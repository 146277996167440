import { PayloadAction } from '@reduxjs/toolkit';
import { signUpActions } from 'app/containers/Onboarding/slice';
import { paymentPageRequestActions } from 'app/containers/PaymentPage/slice';
import { userDashboardPageActions } from 'app/containers/UserDashboardPage/slice';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'types';
import { ResponseError, request } from 'utils/request';
import { configHeaders } from 'utils/request-config';
import { COUNTRIES, GET_IP, GET_USER, GET_USER_INFO, LOGIN, REFRESH_TOKEN, SEND_AUTH_OTP, TOKEN_LOGIN, VALIDATE_OTP } from './core_api/resources';
import { GeneralComponent } from './slice';

const getAccessToken  = (state: RootState) => state?.general?.auth?.access_token;
const getRefreshToken = (state: RootState) => state?.general?.auth?.refresh_token;

export function* tokenLogin(action: PayloadAction<string>) {
    try {
        const url  = TOKEN_LOGIN + `?token=${action.payload}`
        const data = yield call(request, url);
        localStorage.setItem('auth', JSON.stringify(data))
        localStorage.setItem('remember_me', data['remember_me'])
        yield put(GeneralComponent.setAuth(data))
    } catch (err) {
        console.log(err)
        window.location.href = '/login'
    }
}

export function* login(action: PayloadAction<{
    email: string,
    password: string,
    rememberMe: boolean,
    tokenize?: boolean
}>) {
    yield delay(500);
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(action.payload)
        }
        const data = yield call(request, LOGIN, options);
        if (data['access_token']) {
            localStorage.setItem('auth', JSON.stringify(data))
            localStorage.setItem('remember_me', data['remember_me'])
            yield put(GeneralComponent.setAuth(data))
        }
        else if (data['otp_key']) {
            let auth = {
                otp_key: data['otp_key'],
                email  : data['email']
            }
            sessionStorage.setItem('auth', JSON.stringify(auth))
            sessionStorage.setItem('verificationExpiry', data['expiry_time']);
            yield put(GeneralComponent.otpSent(data['expiry_time']))
            yield put(GeneralComponent.setAuth(auth))
        }
        else if (data['login_token']){
            window.location.href = `/login?login_token=${data['login_token']}`
        } else {
            yield put(GeneralComponent.loginFailed(data['error'] || 'Wrong Username or Password'))
        }
    } catch (err) {
        console.log(err)
        yield put(GeneralComponent.loginFailed('An Unexpected error occured'))
    }
}

export function* rememberLogin() {
    const token = localStorage.getItem('remember_me')
    if (token && token != "null") {
        try {
            // Login using remember me token
            let options = {
                method: "POST",
                headers: {'Content-type': 'application/json'},
            }
            const data = yield call(request, LOGIN + `/${token}`, options);
            // Update access token
            localStorage.setItem('auth', JSON.stringify(data))
            localStorage.setItem('remember_me', data['remember_me'])
            yield put(GeneralComponent.setAuth(data))
        } catch (err) {
            console.log(err)
        }
    } else {
        localStorage.removeItem('auth')
        localStorage.removeItem('remember_me')
        yield put(userDashboardPageActions.error('idle'))
        yield put(GeneralComponent.removeAuth())
        yield put(GeneralComponent.setUserInfo())
    }
}

export function* getUser(action: PayloadAction<string>) {
    yield delay(500);
    try {
        let getUser     = (state: RootState) => state?.general?.user;
        let user        = yield select(getUser)
        let options: RequestInit = {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
            }
        }
        const user_info = yield call(request, GET_USER.replace(':id', action.payload), options);
        if (user_info) {
            yield put(GeneralComponent.loginSuccess({user, user_info}))
        } else {
            yield put(GeneralComponent.loginFailed(user_info['error'] || 'User not found'))
        }
    } catch (err) {
        console.log(err)
        yield put(GeneralComponent.loginFailed('An Unexpected error occured'))
    }
}

export function* validateOTP(action: PayloadAction<{otp: string, tokenize?: boolean}>) {
    const getAuth = (state: RootState) => state?.general?.auth;
    const auth    = yield select(getAuth)

    yield delay(500);
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({
                ...action.payload,
                email  : auth?.email,
                otp_key: auth?.otp_key
            })
        }
        const data = yield call(request, VALIDATE_OTP, options);
        if (data['access_token']) {
            localStorage.setItem('auth', JSON.stringify(data))
            localStorage.setItem('remember_me', data['remember_me'])
            yield put(GeneralComponent.setAuth(data))
        }
        else if (data['otp_key']) {
            let auth = {
                otp_key: data['otp_key'],
                email  : data['email']
            }
            sessionStorage.setItem('auth', JSON.stringify(auth))
            sessionStorage.setItem('verificationExpiry', data['expiry_time']);
            yield put(GeneralComponent.otpSent(data['expiry_time']))
            yield put(GeneralComponent.setAuth(auth))
        }
        else if (data['login_token']){
            alert(data)
            window.location.href = `/login?login_token=${data['login_token']}`
        } else {
            yield put(GeneralComponent.invalidOTP(data['error'] || 'An Unexpected error occured'))
        }
    } catch (err) {
        console.log(err)
        yield put(GeneralComponent.loginFailed('An Unexpected error occured'))
    }
}

export function* getIp() {
    yield delay(500);
    try {
        const data = yield call(request, GET_IP);
        yield put(signUpActions.setIp(data))

        yield delay(5000);
        yield put(paymentPageRequestActions.storeInitialSetup({ipInfo: data}))
    } catch (err) {
        console.log(err)
    }
}

export function* getCountries() {
    yield delay(500);
    try {
        const data = yield call(request, COUNTRIES);
        yield put(GeneralComponent.setCountries(data))
    } catch (err) {
        console.log(err)
    }
}

export function* sendOTP() {
    yield delay(500);
    const getEmail = (state: RootState) => state.general?.auth?.email
    const email    = yield select(getEmail)
    try {
        let url     = SEND_AUTH_OTP + `?email=${email}`
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-type': 'application/json'}
        }
        const data = yield call(request, url, options);
        sessionStorage.setItem('verificationExpiry', data);
        yield put(GeneralComponent.otpSent(data));
    } catch (err) {
        console.log(err)
    }
}

function* getUserInfo() {
    const access_token = yield select(getAccessToken)
    const url = GET_USER_INFO
    const options: RequestInit = {
        headers: configHeaders(access_token)
    }

    try {
        const user = yield call(request, url, options)
        yield put(GeneralComponent.setUser(user))
        yield put(GeneralComponent.setUserInfo({...user, ...user.user_info}))
    } catch (err) {
        const response = (err as ResponseError).response
        if (response.status == 401) {
            yield call(rememberLogin)
        }
    }
}

function* refreshToken(action: PayloadAction<any>) {
    const refresh_token = yield select(getRefreshToken)
    try {
        const access_token = yield select(getAccessToken)
        let options: RequestInit = {
            method: "POST",
            headers: configHeaders(access_token),
            body: JSON.stringify({refresh_token})
        }
        let data = yield call(request, REFRESH_TOKEN, options);
        
        localStorage.setItem('auth', JSON.stringify(data))
        localStorage.setItem('remember_me', data['remember_me'])
        yield put(GeneralComponent.setAuth(data))

        if (action.payload) {
            yield call(action.payload)
        }
    } catch (err) {
        localStorage.removeItem('auth')
        yield put(GeneralComponent.removeAuth())
        yield put(GeneralComponent.setUserInfo())
    }
}

function* generalSaga() {
    yield takeLatest(GeneralComponent.getCountries, getCountries)
    yield takeLatest(GeneralComponent.getIp, getIp)
    yield takeLatest(GeneralComponent.getUser, getUser)
    yield takeLatest(GeneralComponent.login.type, login)
    yield takeLatest(GeneralComponent.tokenLogin.type, tokenLogin)
    yield takeLatest(GeneralComponent.refreshToken.type, refreshToken)
    yield takeLatest(GeneralComponent.validateOTP.type, validateOTP)
    yield takeLatest(GeneralComponent.sendOTP.type, sendOTP)

    yield takeLatest(GeneralComponent.getUserInfo.type, getUserInfo)
}

export default generalSaga;