import React from 'react';
import Cookies from 'universal-cookie';

import { AES, enc } from 'crypto-js';
import { paymentPageRequestActions } from 'app/containers/PaymentPage/slice';

export class FormatAmount {
    chargeToSender  : boolean;
    amountDetails   : any;
    receiverDetails : any;
    loopCounter     : number;
    amount          : number;

    /**
     * Formats amount with currency
     * 
     * @param {Array} payAttr - Payment Attributes
     */
    constructor(payAttr) {
        this.chargeToSender     = false;
        this.amountDetails      = payAttr.PaymentPayload['amount_details'];
        this.receiverDetails    = payAttr.PaymentPayload['receiver_details'];
        this.loopCounter        = 0;
        this.amount             = 0;

        switch (payAttr.PaymentPayload['receiver_details']['account_plan']) {
            case 'Standard':
                switch (payAttr.PaymentPayload['receiver_details']['account_type']) {
                    case 'individual':
                        this.chargeToSender = false;
                    break;
                    case 'donation':
                        this.chargeToSender = true;
                    break;
                }
            break;
            default:
                this.chargeToSender = payAttr.PaymentPayload['receiver_details']['options']['charge_fees_to'] == 'sender';
        }
    }

    /**
     * Compiles the given fees to get the TOTAL amount
     * 
     * @param {number} fees - Targets what fees needed to be compiled
     * @returns {number}
     */
    __compileFees(fees) {
        return Object.values(fees || {}).reduce((total: any, item: any) => parseFloat(total as string) + parseFloat(item as string), 0);
    }

    /**
     * Computes the Amount/Fees depending on the provided `type`
     * 
     * @param {string} type - Declares what `type` of amount needed to be computed. Accepted values: (`totalFee`, `processingFee`, `otherCharges`, `Dot Notation for nested array`)
     * @returns {this}
     */
    get(type) {
        switch (type) {
            case 'totalFee':
                for (const [label, fees] of Object.entries(this.amountDetails['fees'])) {
                    let acceptedLabel = this.chargeToSender ? ['system', 'sms', 'other_charges', 'delivery', 'sending'] : ['system', 'sms', 'other_charges', 'delivery', 'sending', 'receiving'];

                    if (acceptedLabel.includes(label)) {
                        this.amount += (typeof fees === 'object' ? (fees && 'fee' in fees ? fees?.['fee'] : this.__compileFees(fees)) : fees) as number;
                    }
                }
            break;
            case 'processingFee':
                for (const [label, fees] of Object.entries(this.amountDetails['fees'])) {
                    let acceptedLabel = this.chargeToSender ? ['sending'] : ['sending', 'receiving'];

                    if (acceptedLabel.includes(label)) {
                        this.amount += (typeof fees === 'object' ? fees?.['fee'] : fees) as number;
                    }
                }
            break;
            case 'otherCharges':
                for (const [label, fees] of Object.entries(this.amountDetails['fees'])) {
                    switch (label) {
                        case 'sms': case 'other_charges':
                            const {biller_fee, ...other_charges} = fees as any;

                            this.amount += (typeof fees === 'object' ? this.__compileFees(other_charges) : fees) as number;
                        break;
                    }
                }
            break;
            case 'billerFee':
                this.amount = +(this.amountDetails['fees']?.other_charges?.biller_fee || 0);
            break;
            case 'deliveryFee':
                this.amount = this.__compileFees(this.amountDetails['fees']['delivery']) as number
            break;
            case 'fixSenderFee':
                for (const [label, fees] of Object.entries(this.amountDetails['fees'])) {
                    if (['sms', 'delivery'].includes(label)) {
                        this.amount += (typeof fees === 'object' ? (fees && 'fee' in fees ? fees?.['fee'] : this.__compileFees(fees)) : fees) as number;
                    } else if (label == 'other_charges') {
                        const {dispatcher_fee} = fees as any;

                        if (dispatcher_fee) {
                            this.amount += dispatcher_fee as number;
                        }
                    }
                }
            break;
            default:
                type = type.split('.');

                if (typeof this.amountDetails[type[this.loopCounter]] === 'object') {
                    this.loopCounter++;

                    Object.entries(this.amountDetails[type[this.loopCounter - 1]]).forEach(fees => {
                        if (fees[0] === type[this.loopCounter]) {
                            this.amount = fees[1] as number;
                        }
                    });
                } else if (this.amountDetails.hasOwnProperty(type[this.loopCounter])) {
                    this.amount = this.amountDetails[type[this.loopCounter]];
                }
        }

        return this;
    }

    /**
     * Returns the value as money/amount format
     * 
     * @returns {string|number}
     */
    toAmount() {
        return (Math.round(this.amount * 100) / 100).toLocaleString(undefined, {minimumFractionDigits: 2});
    }

    /**
     * Returns the value as currency format
     * 
     * @param {boolean} isReversed - Identifies if the format should be "Currency - Amount" or vice-versa. Default: false
     * @returns {string}
     */
    toCurrency(isReversed = false) {
        switch (this.amountDetails['currency']) {
            case 'PHP':
                return `₱${(Math.round(this.amount * 100) / 100).toLocaleString(undefined, {minimumFractionDigits: 2})}`;
            default:
                if (!isReversed) {
                    return `${(Math.round(this.amount * 100) / 100).toLocaleString(undefined, {minimumFractionDigits: 2})} ${this.amountDetails['currency']}`;
                } else {
                    return `${this.amountDetails['currency']} ${(Math.round(this.amount * 100) / 100).toLocaleString(undefined, {minimumFractionDigits: 2})}`;
                }
        }
    }

    /**
     * Hides the amount. Converted to "0.00"
     * 
     * @returns {string}
     */
    toHide(forceRemove = false) {
        if (forceRemove) {
            return undefined;
        }

        switch (this.amountDetails['currency']) {
            case 'PHP':
                return `₱0.00`;
            default:
                return `0.00 ${this.amountDetails['currency']}`;
        }
    }
}

export class FormatString {
    string: string;

    /**
     * Formats string
     * 
     * @param {string} string - Identifies the string to be formatted
     */
    constructor(string) {
        this.string = string || '';
    }

    /**
     * Converts the string to the provided `symbol` if the string is more than 25 characters
     * 
     * @param {string} symbol - Provide any character you desire to filter the string
     * @returns {string} Returns filtered string with the provided symbol
     */
    toShorten(symbol) {
        if (this.string.length > 25) {
            let firstSet    = this.string.slice(0, 7),
                lastSet     = this.string.slice(-8);

            return `${firstSet}${symbol.repeat(10)}${lastSet}`;
        }

        return this.string;
    }

    /**
     * Converts the string to render as the provided `type`. Ex. Image
     * 
     * @param {string} type - Declare the `type` to be converted with. Accepted values: (`image`)
     * @returns {(Element|string)} Returns an element or string depending the the provided `type`
     */
    convertAs(type) {
        switch (type) {
            case 'image':
                return <img src={this.string} alt="jpt" /> as unknown as string
        }

        return this.string;
    }

    /**
     * Converts the string to be readable and grammatically corrected
     * 
     * @param {string} type - Declare the `type` to correct the grammar. Accepted values: (`apostrophize`, `capitalize`)
     * @returns {string} Returns string formatted to the declared grammar format
     */
    grammarly(type) {
        switch (type) {
            case 'apostrophize':
                if (this.string) {
                    return `${this.string}'${this.string.slice(-1) != 's' ? 's' : ''}`;
                }
            break;
            case 'capitalize':
                if (this.string) {
                    return `${this.string.charAt(0).toUpperCase()}${this.string.slice(1)}`;
                }
            break;
            case 'capitalize-all':
                if (this.string) {
                    return this.string.replace(/\b\w/g, (match) => match.toUpperCase());
                }
            break;
        }

        return this.string;
    }

    /**
     * Converts string (Camel Case) to Snake Case format
     * 
     * @returns {string} Returns the string with the correct snake case format
     */
    toSnakeCase() {
        let specialCaseRegex    = ['RFID'].join('|'),
            regexValue          = new RegExp(`${specialCaseRegex}|[A-Z]`, 'g');

        return this.string.replace(/\s/g, '').replace(regexValue, (match, offset) => {
            return (offset === 0) ? match.toLowerCase() : `_${match.toLowerCase()}`;
        });
    }
}

export class InitiateProperty {
    object: object;
    result: object;

    constructor(object) {
        this.object = object;
        this.result = {};
    }

    add(newObject, position = -1) {
        let counter = 0;

        for (var prop in this.object) {
            if (this.object.hasOwnProperty(prop)) {
                if (counter === position) {
                    for (const [key, value] of Object.entries(newObject)) {
                        this.result[key] = value;
                    }
                }

                this.result[prop] = this.object[prop];

                counter++;
            }
        }

        for (const [key, value] of Object.entries(newObject)) {
            if (!(key in this.result)) {
                this.result[key] = value;
            }
        }

        return this.result;
    }
}

export class StoreCookie {
    cookie      : Cookies;
    data        : any;
    cookieValue : any;

    /**
     * Stores Cookie to the Browser
     */
    constructor() {
        this.cookie = new Cookies();
    }

    /**
     * Encodes given data to Base64 then removes special characters
     * 
     * @returns {string} Returns a somewhat Base64 encoded to be provided as Cookie name
     */
    _encode() {
        return window.btoa(JSON.stringify(this.data)).replace(/[^a-zA-Z0-9]/g, '');
    }

    /**
     * Encrypts the given data for the Cookie value
     * 
     * @returns {string} Returns the encrypted data to store as the Cookie value
     */
    _encrypt() {
        return AES.encrypt(JSON.stringify(this.data), process.env.REACT_APP_PAYMENT_SIGNATURE_TOKEN, {
            path    : '/',
            secure  : true,
            maxAge  : 7948800,
        }).toString();
    }

    /**
     * Encodes the given data to match the stored decrypted Cookie value
     * 
     * @returns {boolean} Returns `true` if the given data matched the stored Cookie, hence, `false`
     */
    _decrypt() {
        return JSON.stringify(this.data) == AES.decrypt(this.cookieValue, process.env.REACT_APP_PAYMENT_SIGNATURE_TOKEN).toString(enc.Utf8);
    }

    /**
     * Sets the Cookie in the browser
     * 
     * @param {object} data - Value (as object) to be set in the cookie
     * @returns {void}
     */
    set(data) {
        this.data = data;

        return this.cookie.set(this._encode(), this._encrypt());
    }

    /**
     * Returns value from the stored cookie
     * 
     * @param {object} data - Value (as object) to be encoded to get Cookie name
     * @returns {object} Returns object value stored in cookie
     */
    get(data) {
        this.data           = data;
        this.cookieValue    = this.cookie.get(this._encode());

        return this.cookieValue ? this._decrypt() : false;
    }
}

/**
 * Prevents `useEffect` to run on initial render
 * 
 * @param {React} didMount - Indentifies if the effect mounted on the specific function
 * @param {func} func - `useEffect` function call
 * @param {Array} deps - `useEffect` dependencies
 */
export const MountedEffect = (didMount, func, deps) => {
    React.useEffect(() => {
        if (didMount.current) {
            return func();
        } else {
            didMount.current = true;
        }
    }, deps);
};

/**
 * Re-renders when given global variable changes
 * 
 * @param {func} func - Function call
 * @param {string} variable - Global variable
 */
export const MountedGlobalEffect = (func, variable) => {
    React.useEffect(() => {
        (async () => {
            while (!window[variable]) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }

            if (window[variable]) {
                window[variable] = false as any;

                return func();
            }
        })();
    }, []);
};

/**
 * Generates error message readable for Users
 * 
 * @param {string} errorMessage - Identifies error message given by the client
 * @param {object} isSystemFailure - Identifies if error persists in our System
 * @returns {string} Returns status depending on the API responses
 */
export const errorMessageGenerator = (errorMessage, isSystemFailure) => {
    switch (errorMessage) {
        case 'AUTHENTICATION_FAILED':
            return 'Card Authentication Failed';
    }

    if (isSystemFailure?.status || false) {
        switch (isSystemFailure?.caller) {
            case 'amount':
                return 'Invalid Amount';
            case 'sourceOfFunds':
                return 'Connection Error with the Selected Source of Fund';
        }
    }

    return errorMessage;
}

/**
 * Fetch Source Of Funds API Requests
 * 
 * @param {any} dispatch - React's `dispatch` external function
 * @param {Array} payAttr - Payment Attributes
 * @param {any} rest - Additional Parameters
 */
export const GenerateSourceOfFunds = (dispatch, payAttr, { ...rest }) => {
    let merchantID = rest?.endUser.coreData.merchantID;

    switch (rest?.endUser.coreData.cardProcessor.PaymentProvider.short_name) {
        case 'Xendit':
            merchantID = window.Xendit ? (!!window.Xendit._getPublishableKey() ? merchantID : null) : null;
        break;
    }

    dispatch(paymentPageRequestActions.fetchModalAPI({
        method      : 'POST',
        url         : rest?.apiPath,
        parameters  : {
            username            : rest?.endUser.user.partnerUsername || rest?.endUser.username,
            intent              : 'send_money',
            merchant_id         : merchantID,
            location            : payAttr.PaymentPayload ? (payAttr.PaymentPayload['sender_details']['coordinates'] || '') : '',
            depository_account  : rest?.endUser.coreData.depositoryAccount.code,
            depository_method   : rest?.endUser.coreData.depositoryAccount.depository_method_code,
            selection           : rest?.insertSelection,
            form_data           : rest?.formData || {},
        },
    }));
};
