import { selectAuth } from "app/common/auth/selectors";
import React, { memo } from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Load Images
import logo from 'assets/img/logo.svg';

const Header = memo(() => {
    const auth = useSelector(selectAuth);
    return (
        <header className="header">
            <div className="container">
                <a href="/">
                    <img src={logo} alt="JustPayto" className="logo"/>
                </a>
                <div className="menu icon icon-menu pointer" data-menu-toggle></div>
                <nav data-menu>
                    {/*
                        NOTE: Hidden for the meantime. // By Julio Motol, (February 8th, 2019 9:16am)

                        <div><a href="#">About</a></div>
                        <div><a href="#">For Individuals</a></div>
                        <div><a href="#">For Businesses</a></div>
                        <div><a href="#">Bills Payment</a></div>
                        <div><a href="#">Request Payment</a></div>
                        <section className="divider"></section>
                        @if(Auth::guest())
                            <div><a href="{{ route('login') }}">Log In</a></div>
                            <div><a href="{{ route('early-access') }}">Sign Up</a></div>
                        @else
                            <div><a href="{{ route('user-dashboard') }}">My Account</a></div>
                            <div><a href="{{ route('logout') }}">Log Out</a></div>
                        @endif
                    */}
                    {
                        !auth?.access_token ? <>
                            <div><a href="/login">Log In</a></div>
                            <section className="divider"></section>
                            <div><Link to="/early-access">Sign Up</Link></div>
                        </>:<>
                            <div><Link to="/user-dashboard/account">My Account</Link></div>
                            <section className="divider"></section>
                            <div><Link to="/logout">Log Out</Link></div>
                        </>
                    }
                </nav>
            </div>
        </header>
    )
})

export default Header;