import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo, useEffect, useState } from "react";
import ButtonLoader from "app/components/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "app/common/auth/selectors";
import InputField from "app/components/InputField";
import { userDashboardPageActions } from "../../slice";
import { GeneralComponent } from "app/common/slice";
import { useHistory } from "react-router-dom";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";

const DeleteAccountModal = memo((props: DefaultModalProps) => {

    const dispatch = useDispatch();
    const history  = useHistory();
    const user     = useSelector(selectUserInfo);
    const action   = 'delete';

    const [loading, setLoading] = useState(false);
    const [step, setStep]       = useState('warning');
    const [otp, setOtp]         = useState('');
    const [isEmpty, setEmpty]   = useState(false);
    const [error, setError]     = useState(false);

    const mobile_number = () => user?.mobile_number?.number.slice(-2)

    const onContinue = () => {
        switch (step) {
            case 'warning':
                setLoading(true)
                dispatch(userDashboardPageActions.sendOtp(action));
                setStep('otp');
                setLoading(false)
                break;
            case 'otp':
                const validOtp = otp.length == 6
                setError(!validOtp);
                setEmpty(!otp)
                if (!isEmpty && validOtp) {
                    setLoading(true)
                    dispatch(userDashboardPageActions.validateOtp({otp, action,
                        callback: result => {
                            setError(result === false)
                            if (result) {
                                props.state[1](false)
                                dispatch(userDashboardPageActions.removeActionTime())
                                dispatch(GeneralComponent.removeAuth())
                                localStorage.removeItem("auth")
                                history.push("/")
                            } else {
                                setLoading(false)
                            }
                        }
                    }));
                }
                break;
            default: break;
        }
    }

    const reset = () => {
        setOtp('')
        setEmpty(false)
        setError(false)
        setLoading(false)
        setStep('warning')
    }

    useEffect(() => setLoading(false), [user])

    return (
        <Modal {...props} disableBackdropClick disableEscapeKeyDown
            style   = "action-box content-box account-alert larger_modal center"
            onClose = {reset}
        >
            <h2>Permanently<br/>Delete Account</h2>
            <ErrorOutlineOutlined style={{ fontSize: 150, color: red[600] }}/>
            <div className="body">
                {{
                    warning: <>
                        <p>You are about to Permanently Delete your Account.</p><br/>
                        <p>When you delete your JustPayto account, you won't have access to your account information, and won't be able to retrieve the transaction information done with your Payment Page.</p>
                    </>,
                    otp: <>
                        <div>
                            <p>Enter the verification code sent to **** *** **{mobile_number()}</p><br/>
                        </div>
                        <section className="pseudo-form">
                            <InputField
                                max           = {6}
                                error         = {isEmpty || error}
                                error_message = {
                                    isEmpty? "Code is required":
                                    error && otp.length < 6 ? "Code must be 6 characters":
                                    error? "Wrong verification code": ""
                                }
                                type          = 'text'
                                name          = 'mobile_code'
                                value         = {otp}
                                placeholder   = 'e.g. EA0123'
                                style         = {{inputContainer: 'icon icon-password'}}
                                onChange      = {e => {
                                    let value = e.target.value;
                                    setError(false)
                                    if (!value.match(/[^0-9a-zA-Z]/i)) {
                                        setEmpty(!value);
                                        setOtp(value.toUpperCase());
                                    }
                                }}
                            />
                        </section>
                    </>
                }[step]}
            </div>
            <ButtonLoader hasLoader
                loading = {loading}
                name    = 'continue'
                style   = 'filled blue'
                text    = "Continue"
                onClick = {onContinue}
            />
        </Modal>
    )
})

export default DeleteAccountModal