import { selectUserInfo } from "app/common/auth/selectors";
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import SuccessModal from "app/components/Modal/SuccessModal";
import { ContactlessInfo } from "app/containers/Onboarding/types";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import * as _ from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from 'react-router-dom';
import { clients } from "utils/options";
import AccountInfo from "./AccountInfo";
import BusinessInformation from "./BusinessInformation";
import Contactless from "./Contactless";
import DepositoryAccount from "./DepositoryAccount";
import PaymentPage from "./PaymentPage";
import PersonalInformation from "./PersonalInformation";
import Section from "./Section";
import SocialMedia from "./SocialMedia";
import SupportingDocuments from "./SupportingDocuments";


const MyAccount = memo(() => {
    const dispatch      = useDispatch()
    const userDashboard = useSelector(selectUserDashboard)
    const user          = useSelector(selectUserInfo)
    const isBusiness    = user?.account_type?.type == 'business'
    const isClient      = clients.includes(user?.username || "")
    const isApi         = user?.username && user?.groups?.includes(user.username)

    const [form, setForm]                               = useState({})
    const [prompt, setPrompt]                           = useState(false);
    const [announcement_url, setAnnouncementUrl]        = useState(user?.announcement_url)
    const [businessInformation, setBusinessInformation] = useState({})
    const [personalInformation, setPersonalInformation] = useState({})
    const [paymentPage, setPaymentPage]                 = useState({})
    const [socialMedia, setSocialMedia]                 = useState({})
    const [contactless, setContactless]                 = useState<ContactlessInfo>()
    const [successModal, setSuccessModal]               = useState(false)

    const [loader, setLoader] = useState(false);

    const updateActionTime = () => dispatch(userDashboardPageActions.updateActionTime())

    useEffect(() => {
        updateActionTime()
        setForm({
            announcement_url,
            ...businessInformation,
            ...personalInformation,
            ...paymentPage,
            ...socialMedia,
            contactless
        })
        setPrompt(
            user?.name              != businessInformation['name'] ||
            user?.address           != businessInformation['address'] ||
            user?.pickup_location   != businessInformation['pickup_location'] ||
            !_.isEqual(user?.mobile_number, personalInformation['mobile_number']) ||
            user?.description       != paymentPage['description'] ||
            user?.profile_photo     != paymentPage['profile_photo'] ||
            user?.background_url    != paymentPage['background_url'] ||
            user?.social_media_links!= socialMedia['social_media_links'] ||
            (contactless !== undefined && !_.isEqual(user?.contactless, contactless))
        )
    }, [
        announcement_url,
        businessInformation,
        personalInformation,
        paymentPage,
        socialMedia,
        contactless
    ])
    
    useEffect(() => {
        setLoader(false)
        setSuccessModal(userDashboard.updateUserResult === true);
    }, [userDashboard.updateUserResult])

    window.onbeforeunload = prompt? (_ => ""): null

    return <>
        <Prompt when={prompt} message={"Changes you made may not be saved."}/>
        <div className="pseudo-form my-account" jpt-form="my-account"> 
            {(isBusiness || isClient) && <BusinessInformation onChange={data => setBusinessInformation(data)}/>}
            <PersonalInformation onChange={data => setPersonalInformation(data)}/>
            {!isApi && <AccountInfo/>}
            {!isApi && !isClient && <DepositoryAccount/>}
            {!isApi && <PaymentPage onChange={data => setPaymentPage(data)}/>}
            {!isApi && <SocialMedia onChange={data => setSocialMedia(data)}/>}
            {!isApi && isBusiness && <Section title="ANNOUNCEMENT WINDOW">
                <InputField 
                    type        = "text"
                    name        = "announcement_url"
                    label       = "Website"
                    placeholder = "www.yourannouncement.com"
                    value       = {announcement_url}
                    onChange    = {e => setAnnouncementUrl(e.target.value)}
                    style       = {{
                        inputContainer    : 'icon icon-link',
                        container: 'full-width'
                    }}
                    // tooltip = {
                    //     <div className="body">
                    //         <p>Use this window to show announcements, promos, ads, or surveys for your customers. Provide the URL of a web page, an image, or a form you want to use</p>
                    //     </div>
                    // }
                />
            </Section>}
            {!isApi && isBusiness && !isClient && <Contactless onChange={form => setContactless(form)}/>}
            {!isApi && !isClient && <SupportingDocuments />}
            <section className="footer">
                <ButtonLoader submit hasLoader
                    loading = {loader}
                    name  = "update_account"
                    style = "filled blue"
                    text  = "Update"
                    onClick = {() => {
                        setLoader(true)
                        dispatch(userDashboardPageActions.updateAccount(form))
                    }}
                />
            </section>
            <SuccessModal
                state     = {[successModal, setSuccessModal]}
                className = "customizations-success-modal larger_modal"
                message   = "Successfully Updated Account"
                onClose   = {() => dispatch(userDashboardPageActions.resetUpdateUser())}
            />
        </div>
    </>
})

export default MyAccount;