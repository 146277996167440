import React, { memo, ReactNode } from "react";

interface Props {
    title?   : string
    children?: ReactNode
}

const Section = memo(({title, children}: Props) => {
    return <>
        <section className="divider"><span>{title}</span></section>
        <section className="input-group">
            {children}
        </section>
    </>
})

export default Section