
import { selectUserInfo } from "app/common/auth/selectors";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// General components
import { CircularProgress } from "@material-ui/core";
import TransactionModal from "../../modals/TransactionModal";

// Sub components
import FilterButton from "app/components/FilterButton";
import FilterDate from "app/components/FilterDate";
import ExcelDownload from "./ExcelDownload";
import LottoDownload from "./LottoDownload";
import TableBody from "./TableBody";
import TableHeaders from "./TableHeaders";
import TablePagination from "./TablePagination";

// UserDashboardPage,
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import { TransactionData } from "app/containers/UserDashboardPage/types";
import {
    sort,
    SORT_DESC
} from "app/containers/UserDashboardPage/utils";
import { Link } from "react-router-dom";
import DeliveryModal from "../../modals/DeliveryModal";
import CollectionReport from "./CollectionReport";

interface Props {
    asComponent?: boolean
}

const Reports = memo(({asComponent}: Props) => {
// Selectors
    const user          = useSelector(selectUserInfo);
    const userDashboard = useSelector(selectUserDashboard);
// Page States
    // Filters
    const [all, setAll]                 = useState(false);
    const [last7days, setLast7days]     = useState(true);
    const [last20days, setLast20days]   = useState(false);
    const [search, setSearch]           = useState(false);
    const [searchValue, setSearchValue] = useState('');
    // Date Range Filter
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate]     = useState('');
    // Sorting
    const [sortBy, setSortBy]       = useState('timestamp');
    const [sortOrder, setSortOrder] = useState(SORT_DESC);
    const sortClass = i => i == sortBy? sortOrder : ''
    // Modal
    const [transactionModal, setTransactionModal] = useState(false);
    const [deliveryModal, setDeliveryModal]       = useState(false);
    const [modalTransaction, setModalTransaction] = useState<any>();

    const [page, setPage]           = useState(1);
    const [selectAll, setSelectAll] = useState(false);

// Computed Values
    const rows                                            = asComponent? 11: 21
    const account_type                                    = user?.account_type?.type
    const [transactions, setTransactions]                 = useState(userDashboard.transactions || []);
    const [selectedTransactions, setSelectedTransactions] = useState<TransactionData[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<TransactionData[]>([]);
    const [pages, setPages]                               = useState<number[]>([])
    
    const [end, setEnd]     = useState(moment().format("YYYY-MM-DD"))
    const [start, setStart] = useState('')

    const jpt_report_filename        = !search && selectedTransactions.length == filteredTransactions.length? `Transaction Report (${start} - ${end})`: `Transaction Report (Custom)`
    const collection_report_filename = !search && selectedTransactions.length == filteredTransactions.length? `Download Collection Report (${start} - ${end})`: `Download Collection Report (Custom)`

    const dispatch = useDispatch();

    useEffect(() => setTransactions(userDashboard.transactions || []), [userDashboard.transactions])

    useEffect(() => {
        setStart((
            last7days ? moment().subtract(6, 'days'):
            last20days? moment().subtract(19, 'days'): 
            fromDate && toDate? moment(fromDate):
            moment(transactions.slice(-1)[0]?.timestamp)
        ).format("YYYY-MM-DD"))
        if (all || last7days || last20days ||(
            fromDate && toDate
        )) {
            dispatch(userDashboardPageActions.getTransactions({
                filter: (
                    last7days?  'last7days' : 
                    last20days? 'last20days': 
                    undefined
                ),
                start: fromDate,
                end  : toDate
            }))
        }
}, [last7days, last20days, fromDate, toDate])

    useEffect(() => {
        const selected = transactions.filter(t => t.selected);

        const lastTransaction      = page*rows-1
        const firstTransaction     = lastTransaction-(rows-1)
        const sortedTransactions   = sort(transactions, sortBy, sortOrder)
        const filter = t => (
            (
                !searchValue || (
                    t.sender_details?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    t.sender_details?.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    t.payment_id?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
                )
            )
            // ) && (
            //     all ||
            //     moment(t.updated_at).startOf('day').isSame(moment(start)) ||
            //     moment(t.updated_at).startOf('day').isBetween(moment(start), moment(end)) ||
            //     moment(t.updated_at).startOf('day').isSame(moment(end))
            // )
        )
        setPages(Array.from({length: Math.ceil(sortedTransactions.filter(filter).length/rows)}, (_, i) => i + 1))

        const filteredTransactions = sortedTransactions.filter(filter)
        setFilteredTransactions(filteredTransactions)
        setSelectedTransactions(selected.length > 0? selected: filteredTransactions)
    }, [start, end, page, search, sortBy, sortOrder, transactions, userDashboard.transactions])

    useEffect(() => setPage(1), [all, start, end, search])

    useEffect(() => {
        if (userDashboard.tab == 'reports') {
            document.querySelector("section.container")?.scrollIntoView()
        }
    }, [userDashboard.tab])

    useEffect(() => {
        if (userDashboard.transactions) {
            setModalTransaction(userDashboard.transactions?.find(transaction => transaction.payment_id == modalTransaction?.payment_id))
        }
    }, [userDashboard.transactions])

// Functions
    const resetFilter = () => {
        setAll(false);
        setLast7days(false);
        setLast20days(false);
        setFromDate('');
        setToDate('');
    }

    const setFilter = (set, value) => {
        resetFilter();
        set(value);
    }

    const setRangeFilter = (set, value) => {
        const from = fromDate;
        const to   = toDate;
        resetFilter();
        setFromDate(from);
        setToDate(to);
        set(value);
    }

    const toggleTransaction = (transactionNo) => {
        const t = [...transactions].map(
            transaction =>
                transaction.payment_id == transactionNo?
                {...transaction, selected: !transaction.selected}: transaction
        )
        setTransactions(t);
        setSelectAll(t && t.every(transaction => transaction.selected));
    }
    const toggleAll = () => {
        setTransactions([...filteredTransactions].map(
            transaction => ({...transaction, selected: !selectAll})
        ));
        setSelectAll(!selectAll);
    }

    useEffect(() => {dispatch(userDashboardPageActions.updateActionTime())}, [transactionModal])

// Main Render
    return (<>
        <section jpt-panel="reports" className="reports">
            {!asComponent && <section className="divider"><span>RECENT ACTIVITY</span></section>}
            <div className="dataTables_wrapper no-footer">
                <div className="dataTables_filter">
                    <section className="date-filter-1">
                        <FilterButton status={all} set={setAll} setFilter={setFilter} text="All"/>
                        <FilterButton calendar startDate={start} endDate={end} status={last7days} set={setLast7days} setFilter={setFilter} text="Last 7 days"/>
                        <FilterButton calendar startDate={start} endDate={end} status={last20days} set={setLast20days} setFilter={setFilter} text="Last 20 days"/>
                    </section>
                    <span>or</span>
                    <section className="date-filter-2">
                        <FilterDate direction="from" placeholder="From" value={fromDate} set={setFromDate} setRangeFilter={setRangeFilter}/>
                        <FilterDate direction="to"   placeholder="To"   value={toDate}   set={setToDate}   setRangeFilter={setRangeFilter}/>
                    </section>
                    <span>or</span>
                    <label>
                        <input type="text" placeholder="Name, Email or Transaction ID" value={searchValue} onChange={e => setSearchValue(e.target.value.trim())}/>
                    </label>
                    <div className="search-icon" onClick={() => setSearch(!search)}></div>
                </div>
                <div className="dataTables_scrollBody">
                    {userDashboard.transactions === undefined?
                        <div style={{textAlign: 'center'}}>
                            <CircularProgress />
                        </div>:
                        <table jpt-table="transactions" className="dataTable no-footer" role="grid">
                            <TableHeaders
                                asComponent  = {asComponent}
                                selectAll    = {selectAll}
                                toggleAll    = {toggleAll}
                                sortBy       = {sortBy}
                                sortOrder    = {sortOrder}
                                sortClass    = {sortClass}
                                setSortOrder = {setSortOrder}
                                setSortBy    = {setSortBy}
                            />
                            <TableBody
                                asComponent          = {asComponent}
                                transactions         = {filteredTransactions.slice((page*rows-1)-(rows-1), page*rows-1)}
                                account_type         = {account_type}
                                toggleTransaction    = {toggleTransaction}
                                openTransactionModal = {transaction => {
                                    setModalTransaction(transaction);
                                    setTransactionModal(true);
                                }}
                                openDeliveryModal    = {transaction => {
                                    setModalTransaction(transaction);
                                    setDeliveryModal(true);
                                }}
                            />
                        </table>
                    }
                </div>
            </div>
            {!asComponent?
                <section className="footer">
                    <TablePagination
                        page    = {page}
                        pages   = {pages}
                        setPage = {setPage}
                    />
                    {user?.username == 'lotto'?
                        <LottoDownload
                            filename = {jpt_report_filename}
                            data     = {selectedTransactions}
                            total    = {filteredTransactions.length}
                            loading  = {userDashboard.transactions === undefined}
                        />:
                        <ExcelDownload
                            filename = {jpt_report_filename}
                            data     = {selectedTransactions}
                            total    = {filteredTransactions.length}
                            loading  = {userDashboard.transactions === undefined}
                        />
                    }
                    {
                        user?.account_type?.business?.category == 'utilities' &&
                        user?.account_type?.business?.subcategory == 'Water' &&
                        <CollectionReport
                            filename = {collection_report_filename}
                            data     = {selectedTransactions}
                            total    = {transactions.length}
                            loading  = {userDashboard.transactions === undefined}
                        />
                    }
                </section>:
                <section className="overview-footer">
                    <Link to="/user-dashboard/transactions" onClick={() => dispatch(userDashboardPageActions.setTab('reports'))}>More Transactions</Link>
                </section>
            }
        </section>
        <TransactionModal state = {[transactionModal, setTransactionModal]} transaction = {modalTransaction} />
        <DeliveryModal state = {[deliveryModal, setDeliveryModal]} transaction = {modalTransaction} />
    </>)
});

export default Reports;