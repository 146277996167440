import { createStyles, Avatar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectUser as loggedinUser } from 'app/common/auth/selectors';
import { GeneralComponent, reducer as GeneralReducer } from 'app/common/slice';
import MainLayout from 'app/components/MainLayout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { reducer as CallbackReducer } from 'redux-oidc';
import 'styles/sass/general.scss';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import generalSaga from './common/saga';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import BankVerification from './containers/BankVerification';
import HomePage from './containers/HomePage';
import Onboarding from './containers/Onboarding';
import { PaymentPage } from './containers/PaymentPage/Loadable';
import Restricted from './containers/Restricted';
import NewWindowHandler from './containers/NewWindowHandler';
import PaymentButton from './containers/Demo/PaymentButton';
import { paymentPageSaga } from './containers/saga';
import { selectUser as endUser, selectLoadingUser as loadingEndUser, selectDisburse } from './containers/selectors';
import { paymentPageActions, reducer as PaymentPageReducer, sliceKey } from './containers/slice';
import Subdomain from './containers/Subdomain';
import TermsConditions from './containers/TermsConditions';
import TwoFAPage from './containers/UserAuthentication/2FAPage';
import ForgotPasswordPage from './containers/UserAuthentication/ForgotPasswordPage';
import GetEarlyAccessPage from './containers/UserAuthentication/GetEarlyAccessPage';
import LoginPage from './containers/UserAuthentication/LoginPage';
import ResetPasswordPage from './containers/UserAuthentication/ResetPasswordPage';
import UserDashboardPage from './containers/UserDashboardPage';
import Analytics from './containers/UserDashboardPage/components/tabs/Analytics';
import Customizations from './containers/UserDashboardPage/components/tabs/Customizations';
import MyAccount from './containers/UserDashboardPage/components/tabs/MyAccount';
import Overview from './containers/UserDashboardPage/components/tabs/Overview';
import Settings from './containers/UserDashboardPage/components/tabs/Settings';
import Transactions from './containers/UserDashboardPage/components/tabs/Transactions';
import { userDashboardPageActions } from './containers/UserDashboardPage/slice';
import { WEB_ASSETS } from 'app/common/common';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
            '& > span': {
                display: 'inline-block',
                fontFamily: theme.palette['baseFont'],
                fontSize: theme.palette['fontSize']['XL'],
                fontWeight: theme.palette['fontWeight']['heavy'],
                color: theme.palette['color']['primary']['black'],
                letterSpacing: '5px',
                clipPath: 'inset(0 2.5ch -4px 0)',
                animation: '$dots 1s steps(4) infinite',
            },
            '& .MuiAvatar': {
                '&-root': {
                    width: '100px',
                    height: '100px',
                },
            },
        },
        '@keyframes dots': {
            'to': {
                clipPath: 'inset(0 -1ch -4px 0)',
            },
        },
    }),
);

export const Routes = () => {
    useInjectReducer({ key: 'oidc', reducer: CallbackReducer });
    useInjectReducer({ key: 'general', reducer: GeneralReducer });
    useInjectReducer({ key: sliceKey, reducer: PaymentPageReducer });
    useInjectSaga({ key: sliceKey, saga: paymentPageSaga });
    useInjectSaga({ key: 'general', saga: generalSaga });

    const classes           = useStyles();
    const getLoggedinUser   = useSelector(loggedinUser);
    const getEndUser        = useSelector(endUser);
    const disburse          = useSelector(selectDisburse);
    const endUserIsLoading  = useSelector(loadingEndUser);
    const dispatch          = useDispatch();
    const isAuthenticated   = () => getLoggedinUser.user !== undefined && getLoggedinUser.user != null;  //&& !getLoggedinUser.user?.expired;

    const Logout = () => {
        dispatch(userDashboardPageActions.removeActionTime())
        dispatch(GeneralComponent.removeAuth())
        dispatch(GeneralComponent.setUserInfo())

        localStorage.removeItem("auth")
        
        return <Redirect to="/login"/>;
    };

    const PaymentRoute = ({ component: Component, restricted, ...rest }: any) => {
        const params = useParams()

        React.useEffect(() => {
            if (!disburse && getEndUser.user) {
                dispatch(paymentPageActions.getDisbursementStatus());
            }
        }, [getEndUser.user])

        React.useEffect(() => {
            if (!restricted && !getEndUser.accessToken) {
                dispatch(paymentPageActions.getAccessToken({
                    username        : params['username'],
                    isTopBarHidden  : !restricted,
                }));
            }
        }, [restricted, getEndUser.accessToken])

        return endUserIsLoading ? (
            <div className={classes.loading}>
                {/* <span>Loading...</span> */}
                <Avatar
                    variant="square"
                    src={`${WEB_ASSETS}/img/icons/processing.gif`}
                />
            </div>
        ) : (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated() && restricted ? (
                        <Redirect to={getLoggedinUser.page} />
                    ) : (
                        <MainLayout currentUser={getEndUser || {}}>
                            <Component {...props} />
                        </MainLayout>
                    )
                }
            />
        );
    };

    React.useEffect(() => {
        dispatch(GeneralComponent.getIp());
        dispatch(GeneralComponent.getCountries());
    }, [])

    const HomeSubdomain         = Component => <Subdomain name='home'><Component/></Subdomain>
    const OnboardingSubdomain   = Component => <Subdomain name='onboarding'><Component/></Subdomain>
    const DashboardSubdomain    = Component => <Subdomain name='dashboard'><Component/></Subdomain>

    return (
        <>
            <Switch>
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/"                   render={() => HomeSubdomain(HomePage)}/>
                <Route exact path="/terms-conditions"   render={() => HomeSubdomain(TermsConditions)}/>
                <Route exact path="/verify"             render={() => OnboardingSubdomain(BankVerification)}/>
                <Route exact path="/early-access"       render={() => OnboardingSubdomain(GetEarlyAccessPage)}/>
                <Route exact path="/signup"             render={() => OnboardingSubdomain(Onboarding)}/>
                <Route exact path="/login"              render={() => DashboardSubdomain(LoginPage)}/>
                <Route exact path="/login/2FA"          render={() => DashboardSubdomain(TwoFAPage)}/>
                <Route exact path="/reset-password"     render={() => DashboardSubdomain(ResetPasswordPage)}/>
                <Route exact path="/forgot-password"    render={() => DashboardSubdomain(ForgotPasswordPage)}/>

                <Route path="/user-dashboard" render={() =>
                    <Subdomain name="dashboard">
                        <UserDashboardPage>
                            <Route exact path="/user-dashboard"             component={Overview} />
                            <Route path="/user-dashboard/transactions"      component={Transactions} />
                            <Route path="/user-dashboard/account"           component={MyAccount} />
                            <Route path="/user-dashboard/customizations"    component={Customizations} />
                            <Route path="/user-dashboard/settings"          component={Settings} />
                            <Route path="/user-dashboard/invoice"           component={Settings} />
                            <Route path="/user-dashboard/analytics"         component={Analytics} />
                        </UserDashboardPage>
                    </Subdomain>
                }/>
                <Route exact path="/redirect/restricted" component={Restricted}/>
                <Route exact path="/redirect/post-message" component={NewWindowHandler}/>
                <Route exact path="/demo/payment-button/:username" component={PaymentButton}/>

                <Route path="/:username" render={() =>
                    <Subdomain name="www">
                        <PaymentRoute component={PaymentPage} restricted={false} />
                    </Subdomain>
                }/>
                <Route component={NotFoundPage} />
            </Switch>
        </>
    );
};
