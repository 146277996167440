import React, { memo } from "react";
import Modal, { DefaultModalProps } from '.';
import ButtonLoader from "../ButtonLoader";
import success_icon from 'assets/img/success.svg';

interface Props extends DefaultModalProps {
    message: any
}

const GreatModal = memo((props: Props) => {
    return (
        <Modal
            state = {props.state}
            style = 'action-box content-box success great'
        >
            <h1 className="center">Great</h1>
            <img className="logo" src={success_icon} alt="Success Icon"/>
            <p className="center">{props.message}</p>
            <section className="social-links">
                <ButtonLoader
                    route  = "https://www.facebook.com/JustPayto-PH-2289070334640914/"
                    target = "_blank"
                    rel    = "noreferrer"
                    name   = "jpt_fb"
                    style  = "filled icon social icon-facebook"
                />
                <ButtonLoader
                    route  = "https://www.twitter.com/justpayto"
                    target = "_blank"
                    rel    = "noreferrer"
                    name   = "jpt_twitter"
                    style  = "filled icon social icon-twitter"
                />
                <ButtonLoader
                    route  = "https://www.instagram.com/justpayto"
                    target = "_blank"
                    rel    = "noreferrer"
                    name   = "jpt_instagram"
                    style  = "filled icon social icon-instagram"
                />
                <ButtonLoader
                    route  = "https://www.linkedin.com/company/justpayto"
                    target = "_blank"
                    rel    = "noreferrer"
                    name   = "jpt_instagram"
                    style  = "filled icon social icon-linkedin"
                />
            </section>
        </Modal>
    )
});

export default GreatModal;