import React from 'react';
import { useParams } from 'react-router-dom';

function PaymentButton() {
    const params    = useParams();
    const divRef    = React.useRef(null) as any;

    /**
     * Initiate Variables
     */
    const isOnLocal = Boolean(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

    /**
     * Initiate Functions
     */
    const externalScriptDependency = (identifier, source) => {
        if (!document.getElementById(`script-${ identifier }`)) {
            const script = document.createElement('script');

            script.id       = `script-${ identifier }`;
            script.async    = true;
            script.type     = 'text/javascript';
            script.src      = source;

            document.body.appendChild(script);
        }
    };

    React.useEffect(() => {
        if (divRef.current) {
            let baseUrl = 'http://127.0.0.1:8000',
                env     = 'localhost';

            if (!isOnLocal) {
                switch (process.env.REACT_APP_ENV) {
                    case 'dev':
                        baseUrl = 'https://api-dev.justpayto.ph';
                        env     = 'dev';
                    break;
                    case 'prod':
                        baseUrl = 'https://api.justpayto.ph';
                        env     = 'live'
                    break;
                }
            }

            divRef.current?.setAttribute('class', 'jpt-button');
            divRef.current?.setAttribute('username', params['username']);
            divRef.current?.setAttribute('env', env);

            externalScriptDependency('paymentbutton', `${baseUrl}/connect/api/v3/payment-button.js`);
        }
    }, []);

    return (
        <>
            <div ref={divRef}></div>
        </>
    );
}

export default PaymentButton;
