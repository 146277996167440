import React from 'react';
import { formatDatetime, numberFormat } from 'utils';

const amount_length = amount => numberFormat(amount || 0).length
const amountFormat = (amount, max) => {
    const number       = numberFormat(amount)
    const no_of_spaces = max - number.length + 1
    const spaces = new Array(no_of_spaces).join("\u00a0\u00a0")
    return spaces + number
}
const formatCurrency = (max, currency, amount) => (
    currency == 'PHP'?
    `₱ ${amountFormat(amount, max)}`:
    `${amountFormat(amount, max)} ${currency}`
)

const ApiDetails = ({transaction}) => {
    const transactionDate = formatDatetime(transaction?.updated_at, 'MMMM DD, YYYY (hh:mm A)')

    let currency = transaction?.amount_details?.currency,
        max      = amount_length(transaction?.amount_sent);

    const enduser = {
        cash_in : transaction?.sender_details,
        cash_out: {
            ...transaction?.receiver_details,
            ...transaction?.source_of_funds
        }
    }[transaction?.intent || '']
    const bank_details = transaction?.intent == 'cash_out'? transaction?.sender_details?.depository_info: transaction?.receiver_details?.bank_details
    const details = {
        cash_in: {
            user_details: <EndUserDetails enduser = {enduser}/>,
            breakdown   : <Breakdown transaction  = {transaction}/>
        },
        cash_out: {
            user_details: <EndUserDetails enduser = {enduser}/>,
            breakdown   : <Breakdown transaction  = {transaction}/>
        },
        deposit: {
            user_details: <DepositoryDetails bankDetails={bank_details} transaction={transaction}/>,
            breakdown   : <></>
        },
        top_up: {
            user_details: <DepositoryDetails bankDetails={bank_details} transaction={transaction}/>,
            breakdown   : (
                <tr>
                    <td>Top-up Amount:</td>
                    <td>{formatCurrency(max, currency, +(transaction?.amount_details?.gross || 0))}</td>
                </tr>
            )
        },
    }
    return (
        <table>
            <tbody>
                {details[transaction?.intent || '']?.user_details}
                <tr>
                    <td>Transaction Date:</td>
                    <td>{transactionDate}</td>
                </tr>
                <tr>
                    <td>Transction Number:</td>
                    <td>{transaction?.payment_id}</td>
                </tr>
                <tr className="spacer"></tr>
                {details[transaction?.intent || '']?.breakdown}
            </tbody>
        </table>
    )
}

const EndUserDetails = ({enduser}: any) => {
    return <>
        <tr>
            <td>Full Name:</td>
            <td>{enduser?.name}</td>
        </tr>
        <tr>
            <td>Email:</td>
            <td>{enduser?.email}</td>
        </tr>
        <tr>
            <td>Mobile Number:</td>
            <td>{
                typeof enduser?.mobile === 'string'?
                enduser?.mobile: (
                    (enduser?.mobile?.prefix || '') + 
                    (enduser?.mobile?.number || '')
                )
            }</td>
        </tr>
        <tr>
            <td>Residential Address:</td>
            <td>{enduser?.residential_address}</td>
        </tr>
        <tr>
            <td>Nationality:</td>
            <td>{enduser?.nationality}</td>
        </tr>
        <tr>
            <td>Date of Birth:</td>
            <td>{enduser?.birth?.date}</td>
        </tr>
        <tr>
            <td>Place of Birth:</td>
            <td>{enduser?.birth?.place}</td>
        </tr>
        <tr>
            <td>Sex:</td>
            <td>{enduser?.gender}</td>
        </tr>
        <tr>
            <td>Govt Issued ID:</td>
            <td><img src={enduser?.government_id} width="100%"/></td>
        </tr>
        <tr>
            <td>Signature:</td>
            <td><img src={enduser?.e_signature} width="100%"/></td>
        </tr>
        <tr className="spacer"></tr>
    </>
}

const DepositoryDetails = ({bankDetails, transaction}) => {
    return <>
        <tr>
            <td>Settlement Bank:</td>
            <td>{
                bankDetails?.name ||
                bankDetails?.bank
            }</td>
        </tr>
        <tr>
            <td>Account Name:</td>
            <td>{bankDetails?.account_name}</td>
        </tr>
        <tr>
            <td>Account Number:</td>
            <td>{bankDetails?.account_number}</td>
        </tr>
        <tr>
            <td>Reference Number:</td>
            <td>{
                transaction?.additional_details?.reference_number ||
                transaction?.additional_details?.payout_info?.reference_number
            }</td>
        </tr>
        <tr className="spacer"></tr>
    </>
}

const Breakdown = ({transaction}) => {
    const {
        source_of_funds,
        amount_details,
    } = transaction;
    /**
     * Format Amounts With currency
     */
    let currency        = amount_details?.currency,
        max             = amount_length(transaction?.amount_sent),
        amount_paid     = formatCurrency(max, currency, +(transaction?.amount_sent || 0)),
        total_amount    = formatCurrency(max, currency, +(transaction?.payout_amount || 0)),
        system_fee      = formatCurrency(max, currency, +(amount_details?.fees.system || 0)),
        processing_fee  = formatCurrency(max, currency, +(transaction?.processing_fee || 0)),
        sending_fee     = formatCurrency(max, currency, +(amount_details?.fees?.sending?.fee || 0)),
        receiving_fee   = formatCurrency(max, currency, +(amount_details?.fees?.receiving?.fee || 0)),
        fulfillment_fee = formatCurrency(max, currency, 0),
        other_charges   = formatCurrency(max, currency, +(transaction?.partner_fee || 0)),
        charge_fees_to  = `- charge to ${transaction?.charge_fees_to == 'sender'? 'sender' : 'recipient'}`;
    // END

    return <>
        <tr>
            <td>Amount Paid</td>
            <td>{amount_paid} (via {source_of_funds?.service_name})</td>
        </tr>
        <tr>
            <td>Fees:</td>
            <td>
                <span>{system_fee}</span> (system fee) {charge_fees_to}
            </td>
        </tr>
        <tr>
            <td></td>
            <td>
                <span>{processing_fee}</span> (sending processing fee) {charge_fees_to}
            </td>
        </tr>
        {/* <tr>
            <td></td>
            <td>
                <span>{receiving_fee}</span> (receiving processing fee) {charge_fees_to}
            </td>
        </tr> */}
        {!!transaction?.partner_fee && <tr>
            <td></td>
            <td>
                <span>{other_charges}</span> (other charges) {charge_fees_to}
            </td>
        </tr>}
        <tr className="spacer"></tr>
        <tr>
            <td>Total Amount:</td>
            <td>{total_amount}</td>
        </tr>
    </>
}

export default ApiDetails;