import { TWITTER_API } from "app/common/core_api/resources";
const API_URL = "https://api.twitter.com";

const useTwitterLogin = () => {
    const login = () => {
        fetch(`${TWITTER_API}/request-token`, {
            method : "POST",
            headers: {"Content-Type": "application/json"},
            body   : JSON.stringify({callback: window.location.href})
        })
        .then(res => res.json())
        .then(res => {
            if (res.request_token) {
                window.onbeforeunload = null;
                window.location.href = `${API_URL}/oauth/authenticate?oauth_token=${res.request_token}`;
            }
        })
    }

    const getAccessToken = async ({oauth_verifier, oauth_token}) => {
        let url = new URL(`${TWITTER_API}/access-token`)
            url.searchParams.append('oauth_verifier', oauth_verifier)
            url.searchParams.append('oauth_token', oauth_token)

        return await fetch(url.toString(), {method: "POST"}).then(res => res.json())
    }
    
    const getUserInfo = async ({oauth_verifier, oauth_token}) => {
        let url     = new URL(`${TWITTER_API}/user`),
            fields  = "name,username,description,profile_image_url,url",
            reponse = await getAccessToken({oauth_verifier, oauth_token});
        Object.entries({...reponse, fields}).forEach(([key, value]) => url.searchParams.append(key, value as string))
        return await (
            fetch(url.toString())
                .then(res => res.json())
                .then(res => res.data)
        )
}

    return {login, getAccessToken, getUserInfo}
}

export default useTwitterLogin