import { createSelector } from '@reduxjs/toolkit';
import { RootState }      from 'types';
import { initialState }   from './slice';

const selectDomain = (state: RootState) =>
    state.landingPage || initialState;

export const selectLandingPage = createSelector(
    [selectDomain],
    landingPageState => landingPageState,
);
