// Assets
import ButtonLoader from 'app/components/ButtonLoader';
import bg from 'assets/img/background/justpayto-philippines-payments-4.jpg';

// Styles
import 'styles/sass/signup.scss';

import { Backdrop, CircularProgress } from '@material-ui/core';
import useLinkedIn from 'app/components/SocialLogin/useLinkedIn';
import useTwitterLogin from 'app/components/SocialLogin/useTwitterLogin';
import React, { memo, useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { useQuery } from 'utils/useQuery';
import MasterPage from "../../MasterPage";
import { signUpActions } from '../slice';
import BusinessModal from './BusinessModal';
import IndividualModal from './IndividualModal';
import { useStyles } from './styles';

const AccountSelection = memo(() => {
    const dispatch        = useDispatch();
    const query           = useQuery();
    const individualModal = useState(false);
    const businessModal   = useState(false);
    const classes         = useStyles();
    const twitter         = useTwitterLogin();
    const linkedin        = useLinkedIn();

    const [checkingRedirect, setCheckingRedirect] = useState(false);

    useEffect(() => {
        checkTwitterRedirect()
        checkLinkedInRedirect()
    }, [])

    const checkTwitterRedirect = () => {
        let oauth_verifier = query.get('oauth_verifier'),
            oauth_token    = query.get('oauth_token');

        if (oauth_verifier && oauth_token) {
            setCheckingRedirect(true)
            twitter.getUserInfo({oauth_verifier, oauth_token}).then(data => {
                let name = data.name.split(' ');
                submitUserInfo({
                    name              : data.name,
                    lastName          : name.pop(),
                    firstName         : name.join(' '),
                    username          : data.username,
                    description       : data.description,
                    profile_photo     : data.profile_image_url,
                    social_media_links: {
                        facebook: '', linkedin: '', instagram: '',
                        twitter : data.username,
                        website : data.url
                            ?.replaceAll('https://', '')
                            ?.replaceAll('http://', '') || ''
                    }
                })
            }).finally(() => setCheckingRedirect(false));
        }
    }

    const checkLinkedInRedirect = () => {
        let code = query.get('code');
        if (code) {
            setCheckingRedirect(true)
            linkedin.getUser({code}).then(data => data &&
                submitUserInfo({
                    name         : data.name,
                    firstName    : data.given_name,
                    lastName     : data.family_name,
                    profile_photo: data.picture,
                })
            ).finally(() => setCheckingRedirect(false))
        }
    }
    
    const submitUserInfo = ({
        firstName,
        lastName,
        birthdate,
        profile_photo,
        social_media_links,
        username,
        description,
    }: any) => {
        dispatch(signUpActions.setAccountType('individual'))
        dispatch(signUpActions.setBasicInfo({firstName, lastName, birthdate}))
        dispatch(signUpActions.setSocialMedia(social_media_links || {}))
        dispatch(signUpActions.setAccountInformation({
            username: username || '',
            password: ''
        }))
        dispatch(signUpActions.setPaymentPage({
            description   : description || '',
            profile_photo : profile_photo || '',
            background_url: ''
        }))
        dispatch(signUpActions.nextStep())
        dispatch(signUpActions.saveState())
    }

    return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
        <MasterPage title = "Sign Up - Select Account Type" backgroundImage = {bg} hasHeader>
            <section className={classes.cardContainerWrapper}>
                <section className={classes.cardContainer}>
                    <p className={classes.cardHeader}>Create your account. It's free to Sign Up.</p>
                    <section className={classes.cardAccountTypeContainer}>
                        {/* INDIVIDUAL */}
                        <section className={classes.cardAccountType}>
                            <div className={classes.cardAccountTypeHeaderContainer}>
                                <p className={classes.cardAccountTypeHeaderTitle}>For Individuals</p>
                                <p className={classes.cardAccountTypeHeaderSubtitle}>Great for splitting bills, receiving allowance & paying for dinner.</p>
                            </div>
                            <section>
                                <p className={classes.cardAccountTypeBodyTitle}>Receive money from anyone. Directly where you want it.</p>
                                <p className={classes.cardAccountTypeBodyContent}>Tell friends and family your personal link whenever you talk to them - chat, text, email or post. They click the link, they enter the amount, you receive the money.</p>
                            </section>
                            <section className={classes.cardAccountTypeFooterContainer}>
                                <hr />
                                <section className={classes.cardAccountTypeFooter}>
                                    <span className={classes.cardAccountTypeFeeAmount}>10</span>
                                    <span className={classes.cardAccountTypeFeeCurrency}>PHP</span>
                                    <p className={classes.cardAccountTypeFeeNote}>*standard bank fees per transaction.</p>
                                </section>
                                <ButtonLoader
                                    name  = 'individual_button'
                                    style = {`filled blue ${classes.button}`}
                                    text  = 'GET STARTED'
                                    onClick = {() => individualModal[1](true)}
                                />
                            </section>
                            <IndividualModal state = {individualModal} />
                        </section>
                        {/* BUSINESS */}
                        <section className={classes.cardAccountType}>
                            <div className={classes.cardAccountTypeHeaderContainer}>
                                <p className={classes.cardAccountTypeHeaderTitle}>For Businesses</p>
                                <p className={classes.cardAccountTypeHeaderSubtitle}>Perfect for online sellers, merchants & entrepreneurs.</p>
                            </div>
                            <section>
                                <p className={classes.cardAccountTypeBodyTitle}>Always ready to make a sale. Get paid anywhere, anytime.</p>
                                <p className={classes.cardAccountTypeBodyContent}>It used to be that getting a merchant account would be the hardest part of trying to sell on the internet and receiving credit card payments. We just fixed that! You're welcome!</p>
                            </section>
                            <section className={classes.cardAccountTypeFooterContainer}>
                                <hr />
                                <section className={classes.cardAccountTypeFooter}>
                                    <p className={classes.cardAccountTypeFooterBusiness}>
                                        Accept payment of any kind - credit card, debit card, online banking, fund transfer from any banks, over-the-counter, even bitcoins.
                                        <p className={classes.cardAccountTypeBusinessFees}>10 PHP + standard bank fees per transaction.</p>
                                    </p>
                                </section>
                                <ButtonLoader
                                    name  = 'business_button'
                                    style = {`filled blue ${classes.button}`}
                                    text  = 'GET STARTED'
                                    onClick = {() => businessModal[1](true)}
                                />
                            </section>
                            <BusinessModal state = {businessModal} />
                        </section>
                    </section>
                </section>
            </section>
            <Backdrop open={checkingRedirect} style={{zIndex: 1}}>
                <CircularProgress color="primary" />
            </Backdrop>
        </MasterPage>
    </GoogleReCaptchaProvider>
    )
})

export default AccountSelection;