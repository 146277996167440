import { CircularProgress, Grid, Typography } from "@material-ui/core"
import { selectUserInfo } from "app/common/auth/selectors"
import ButtonLoader from "app/components/ButtonLoader"
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Label, Line, Tooltip } from "recharts"

interface Props {
    title     : string
    total?    : {
        main : string | number,
        date1: string | number,
        date2: string | number,
    }
    name?     : string
    mainKey   : string
    key1?     : string
    key2?     : string
    data?     : object[]
    formatter?: Function
    frequency : string
}

const LineGraph = ({title, total, name, mainKey, data, formatter, frequency}: Props) => {
    const dispatch                  = useDispatch();
    const user                      = useSelector(selectUserInfo);
    const [isPremium, setIsPremium] = useState(false);
    const labelFormatter            = (_, payload) => {
        if (!payload) {
            return ''
        }
        const date = (
            frequency == "hourly"?
            moment(payload[0]?.payload?.date1?.slice(-5), ['hh:mm']).format('h:mm A'):
            frequency == "daily"? <>
                {payload[0]?.payload?.date1} vs {payload[0]?.payload?.date2}
            </>:
            payload[0]?.payload?.date
        )

        return payload[0]?.payload?.date || date;
    }

    const openChangePlanModal = () => dispatch(userDashboardPageActions.setChangePlan())

    useEffect(() => setIsPremium(!!(
        user?.groups?.includes('Premium') ||
        user?.groups?.includes('Partners')
    )), [user])

    return (
        !data?
        <Grid item xs={12}>
            <CircularProgress />
        </Grid>:
        <Grid container className="standard-grid">
            <Grid item xs={12}>
                <Typography variant="h5">{title}</Typography><br/>
                <Typography variant="h3" color="primary">{formatter && total?.main? formatter(+(total?.main || 0)): total?.main}</Typography>
                {/* {!total && <><Typography variant="h3">
                    <span className="text-primary">{total.date1}</span> | {total.date2}
                </Typography></>} */}
            </Grid>
            <Grid item xs={12} style={{position: "relative"}}>
                <ResponsiveContainer className={isPremium? '': 'blur'} width={'108%'} height={180}>
                    <LineChart
                        data   = {data}
                        margin = {{
                            top   : 30,
                            right : 60,
                            left  : 60,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="4 0" horizontal={false}  />
                        <CartesianGrid />
                        <XAxis padding="no-gap" tickLine={false} label='' tick={{stroke: '#ccc'}} tickFormatter={_ => ''}/>
                        <YAxis hide/>
                        
                        <Tooltip formatter={formatter} labelFormatter={labelFormatter} />
                        <Label />
                        <Line type="linear" name={name || mainKey} dataKey={mainKey} stroke="#0099DD" />
                        <Line type="linear" dataKey={`${mainKey}1`} stroke="#0099DD" />
                        <Line type="linear" dataKey={`${mainKey}2`} stroke="#888888" />
                    </LineChart>
                </ResponsiveContainer>
                {!isPremium && <ButtonLoader
                    style   = "filled round blue upgrade-button"
                    text    = "Upgrade"
                    onClick = {openChangePlanModal}
                />}
            </Grid>
        </Grid>
    )
}

export default LineGraph