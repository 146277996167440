import React, { memo, useCallback, useState } from 'react';
import Modal, { DefaultModalProps } from '.';
import ButtonLoader from '../ButtonLoader';
import Cropper from 'react-easy-crop'
import { useStyles } from 'app/containers/Onboarding/AccountSetup/styles';

interface Props extends DefaultModalProps {
    image?: string
    onSave?: (image) => void
    onClose?: () => void
}

const ImageEditor = memo(({state, image, onSave}: Props) => {
    const classes = useStyles();
    const editor = useImageEditor();

    const [fit, setFit] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const cropImage = () => {
        editor.getCroppedImg(image, croppedAreaPixels, rotation).then(
            image => onSave && onSave(image)
        )
    }

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    return (
        <Modal state = {state} style="image-editor-container" dialogClassname={classes.smallModal} disableBackdropClick disableEscapeKeyDown>
            <section className="pseudo-form image-editor-modal">
                <h2>Edit Image</h2>
                <section className="cropper-container">
                    <div className={`bg ${fit}`}>
                        <Cropper
                            image            = {image}
                            crop             = {crop}
                            rotation         = {rotation}
                            zoom             = {zoom}
                            minZoom          = {1}
                            aspect           = {1 / 1}
                            onCropChange     = {setCrop}
                            onRotationChange = {setRotation}
                            onCropComplete   = {onCropComplete}
                            onZoomChange     = {setZoom}
                            onMediaLoaded={mediaSize => setFit(mediaSize.naturalHeight < mediaSize.naturalWidth ? 'height' : 'width')}
                        />
                    </div>
                </section>
                <section className="image-editor-controls">
                    <button className="bordered blue icon icon-zoom-in"  type="button" onClick={() => setZoom(zoom+0.1)}></button>
                    <button className="bordered blue icon icon-zoom-out" type="button" onClick={() => zoom-0.1>=1 ? setZoom(zoom-0.1): setZoom(1)}></button>
                    <button className="bordered blue icon icon-rotate-left"  type="button" onClick={() => setRotation(rotation-90)}></button>
                    <button className="bordered blue icon icon-rotate-right" type="button" onClick={() => setRotation(rotation+90)}></button>
                </section>
                <section className="footer">
                    <ButtonLoader hasLoader
                        onClick = {cropImage}
                        name  = 'image_editor_submit'
                        style = 'filled blue'
                        text  = 'Save'
                    />
                </section>
            </section>
        </Modal>
    )
});

const useImageEditor = () => {
    
    const createImage = (url) =>
        new Promise<HTMLImageElement>((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.src = url
        }
    )

    const getRadianAngle = (degreeValue) => {

        return (degreeValue * Math.PI) / 180
    }

    const rotateSize = (width, height, rotation) => {

        const rotRad = getRadianAngle(rotation)
      
        return {
            width : Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) => {

        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        
        if (!ctx) {
            return null
        }
        
        const rotRad = getRadianAngle(rotation)
        
        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        )
        
        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight
        
        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)
        
        // draw rotated image
        ctx.drawImage(image, 0, 0)
        
        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        )
        
        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
        
        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0)
        
        // As Base64 string
        return new Promise<string>((resolve, reject) => resolve(canvas.toDataURL('image/jpeg')));
        
        // As a blob
        // return new Promise<string>((resolve, reject) => {
        //     canvas.toBlob(file => file && resolve(URL.createObjectURL(file)), 'image/png')
        // })
    }

    return {createImage, getRadianAngle, rotateSize, getCroppedImg}
}

export default ImageEditor;