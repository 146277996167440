import React, { memo, useEffect, useState } from "react";
import Progress from 'app/components/Progress';
import ButtonLoader from "app/components/ButtonLoader";
import BasicInformation from "../AccountInfo/BasicInformation";
import AccountInformation from "../AccountInfo/AccountInformation";
import PaymentPage from "../AccountInfo/PaymentPage";
import SocialMedia from "../AccountInfo/SocialMedia";
import DepositoryDetails from "../DepositoryDetails";
import { useDispatch, useSelector } from "react-redux";
import { selectSignUpState } from "../../selectors";
import { signUpActions } from "../../slice";
import Documents from "../Documents";
import ProcessingModal from "./ProcessingModal";
import ConfirmModal from "app/components/Modal/ConfirmModal";
import { useStyles } from "../styles";

const Review = memo(() => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const signup = useSelector(selectSignUpState);

    const [showProcessing, setShowProcessing] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        // Resets to uploading of documents step after page reload
        // Note: Documents are lost on reload (needs reupload)
        if (
            signup.documentStatus == 'complete' && (
                !signup.user_info?.document_meta?.front?.file ||
                !signup.user_info?.document_meta?.back?.file ||
                !signup.user_info?.document_meta?.selfie?.file ||
                !signup.user_info?.document_meta?.signature?.file ||
                !signup.user_info?.document_meta?.type || (
                    signup.user_info?.account_type?.type == 'business' && (
                        !signup.user_info?.document_meta?.business_documents ||
                        signup.user_info?.document_meta?.business_documents?.filter(doc => !!doc?.file).length < 4
                    )
                )
            )
        ) {
            dispatch(signUpActions.setDocumentStatus('blank'))
            dispatch(signUpActions.goToStep('documents'))
        }
    }, [])

    const completeDepositoryAccount = () => {
        dispatch(signUpActions.goToStep('depository_account'));
        dispatch(signUpActions.setDepositoryStatus('blank'));
        dispatch(signUpActions.setDocumentStatus('blank'));
    }

    const completeIdentityVerification = () => {
        dispatch(signUpActions.goToStep('documents'));
        dispatch(signUpActions.setDocumentStatus('blank'));
    }

    const onConfirm = () => dispatch(signUpActions.createAccount());

    useEffect(() => {
        setShowProcessing(signup.creatingAccount || false);
    }, [signup.creatingAccount])

    return <>
        <ConfirmModal state = {[confirmModal, setConfirmModal]} onConfirm = {onConfirm} message = "Are you sure you want to continue?"/>
        <section className="header">
            <h3 className={classes.header}>Here's what we know about you.</h3>
            <div className={classes.subheader}>Before we go ahead, let's take a look at what you have provided us about you. Anything seems off? Here's your chance to make necessary changes.</div>
        </section>
        <BasicInformation forReview />
        <AccountInformation forReview />
        <PaymentPage forReview />
        <SocialMedia forReview />
        <Progress status="complete" style="wrapped">
            <p>By submitting the required info in this step, you have reserved the Payment Address - <span data-username>JUSTPAY.TO/{signup.username?.toUpperCase()}</span></p>
        </Progress>

        {
            signup.depositoryStatus == 'complete'? <>
                <DepositoryDetails forReview formData = {signup.user_info?.depository_details || {}}/>
                <Progress status="complete" style="wrapped">
                    <p>By submitting the required info in this step, your Payment Page is now available at - <span data-username>JUSTPAY.TO/{signup.username?.toUpperCase()}</span></p>
                </Progress>
            </>:<>
                <section className={classes.divider}><span>DEPOSITORY ACCOUNT</span></section>
                <Progress status="incomplete" style="wrapped" action={
                    <p className="blue pointer" data-complete-depository onClick={completeDepositoryAccount}>Complete this step</p>
                }>
                    <p>You have skipped this step. Payment Page is pending. You have 30 days to complete this step to continue using <span data-username>JUSTPAY.TO/{signup.username?.toUpperCase()}</span></p>
                </Progress>
            </>
        }

        {
            signup.documentStatus == 'complete'?<>
                <Documents forReview formData = {signup.user_info?.document_meta} account_type={signup.user_info?.account_type?.type || ""} business_type={signup.user_info?.account_type?.business.type} />
                <Progress status="complete" style="wrapped">
                    <p>By submitting the required info in this step, you're one step closer to receiving money at - <span data-username>JUSTPAY.TO/{signup.username?.toUpperCase()}</span></p>
                </Progress>
            </>:<>
            <section className={classes.divider}><span>IDENTITY VERIFICATION</span></section>
                <Progress status="incomplete" style="wrapped" action={
                    <p className="blue pointer" data-complete-documents onClick={completeIdentityVerification}>Complete this step</p>
                }>
                    <p>You have skipped this step. You won't be able to receive payments. You have 30 days to complete this step to continue using <span data-username>JUSTPAY.TO/{signup.username?.toUpperCase()}</span></p>
                </Progress>
            </>
        }
        <section className="footer">
            <section className="buttons">
                <ButtonLoader
                    name  = 'review_back'
                    style = 'bordered blue'
                    text  = 'Back'
                    onClick = {() => dispatch(signUpActions.previousStep())}
                />
                <ButtonLoader
                    name  = 'create_account'
                    style = 'filled blue'
                    text  = 'Create Account'
                    hasLoader
                    // onClick = {() => setConfirmModal(true)}
                    onClick = {() => onConfirm()}
                />
            </section>
            <div className={classes.footer}>By clicking "Create Account", you agree to our <a href="/terms-conditions" target="_blank">Terms &amp; Conditions</a>.</div>
        </section>
        <ProcessingModal state={[showProcessing, setShowProcessing]}/>
    </>
})

export default Review;