import React, { memo, useState } from "react";

interface Props {
    key?     : string,
    open?    : boolean,
    name?    : string,
    list?    : string[],
    search?  : string,
    onSelect?: (value: string) => void
}

const AccordionOption = memo((props: Props) => {
    const [open, setOpen] = useState(props.open);
    const onClick = (item) => {
        if (props.onSelect) {
            props.onSelect(item)
        }
    }
    return (
        <div className="dropdown-item">
            <div className="accordion" onClick={() => setOpen(!open)}>
                <span className={`ui-selectmenu-icon ui-icon ui-icon-triangle-1-s caret ${open? 'open': ''}`}></span>
                <span>{props.name}</span>
            </div>
            <div className={`accordion-panel ${open? 'active': ''}`}>{
                props.list && props.list.map(item =>
                    (!props.search || item.toLowerCase().includes(props.search.toLowerCase())) &&
                    <div className="dropdown-item pointer" onClick={() => onClick(item)}>{item}</div>
                )
            }</div>
        </div>
    )
})

export default AccordionOption;