import { selectUserInfo } from "app/common/auth/selectors";
import { SORT_ASC, SORT_DESC } from "app/containers/UserDashboardPage/utils";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { clients } from "utils/options";
import TableHead from './TableHead';

const tableHeaders = (type, isApi) => [
    {
        name: 'timestamp',
        className: "date sorting bold",
        label: "Date",
        children : undefined
    }, {
        name: 'transactionNo',
        className: "transaction-no sorting bold",
        label: "Transaction No.",
        children : undefined
    }, {
        name: 'type',
        className: "text sorting bold",
        label: "Type",
        children : undefined
    }, {
        name: 'name',
        className: "text sorting bold",
        label: isApi? "Name": "Customer Name",
        children : undefined
    }, {
        name: 'message',
        className: "text sorting bold",
        label: type == 'business'? "Purpose / Instructions": "Message / Order / Items / Ref #",
        children : undefined
    }, {
        name: 'fulfillment_method',
        className: "text sorting bold",
        label: "Fulfillment Method",
        children : undefined
    }, {
        name: 'status',
        className: "status sorting bold",
        label: "Status",
        children : undefined
    }, {
        name: 'amount',
        className: "amount sorting bold",
        label: "Amount",
        children : undefined
    }, {
        name: 'fees',
        className: "amount sorting bold",
        label: "Fees",
        children : undefined
    }, {
        name: 'net',
        className: "amount sorting bold",
        label: "Net",
        children : undefined
    }
]

interface Props {
    asComponent
    selectAll
    toggleAll
    sortBy
    sortOrder
    sortClass
    setSortOrder
    setSortBy
}

const TableHeaders = memo(({asComponent, selectAll, toggleAll, sortBy, sortOrder, sortClass, setSortOrder, setSortBy}: Props) => {
    const user     = useSelector(selectUserInfo);
    const type     = user?.account_type?.type;
    const isApi    = user?.username && user?.groups?.includes(user.username);
    const isP2P    = !isApi && ![...clients].includes(user?.username || '');
    const selected = selectAll ? 'selected' : '';

    const classname    = (header, i) => `${header.className} ${sortClass(i)}`
    const sortSelected = (header) => sortBy == header.name
    const sort         = (header) => () => {
        if (sortBy == header.name) {
            setSortOrder(sortOrder == SORT_ASC? SORT_DESC: SORT_ASC)
        }
        setSortBy(header.name)
    }

    return <thead>
        <tr role="row" className={selected}>
            {!asComponent && <TableHead className="check-box">
                <input type="checkbox" onClick={toggleAll}/>
            </TableHead>}
            {tableHeaders(type, isApi).map((header, i) => (
                (header.name != 'fulfillment_method' || (isP2P && type == 'business')) &&
                (header.name != 'type' || isApi) &&
                (header.name != 'message' || isP2P) &&
                <TableHead
                    label        = {header.label}
                    className    = {classname(header, i)}
                    onClick      = {sort(header)}
                    sortSelected = {sortSelected(header)}
                    sortOrder    = {sortOrder}
                >
                    {header.children}
                </TableHead>
            ))}
        </tr>
    </thead>
})

export default TableHeaders