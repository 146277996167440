/* eslint-disable */
import { PayloadAction } from '@reduxjs/toolkit';
import IP from 'app/common/types/IP';
import { createSlice } from 'utils/@reduxjs/toolkit';
import SignUp, { DepositoryDetails, DocFile, DocumentMeta, MobileNumber, SocialMediaLinks } from './types';

export const initialState: SignUp = {
    suggestedUsernames: [],
    verifying: false,
    step: 'sign_up',
    depositoryStatus: 'blank',
    documentStatus  : 'blank',
    checkingEmail   : true,
};

const signUpSlice = createSlice({
    name: 'signUp',
    initialState,
    reducers: {
        setLockOutExpiry(state, action: PayloadAction<number | undefined>) {
            state.lockOutExpiration = action.payload;
        },
        saveState(state) {
            const data = JSON.parse(JSON.stringify({...state}))
            const type = data.user_info?.document_meta?.type || '';
            if (data.user_info) {
                data.user_info.document_meta = {
                    type,
                    front    : {file: ""},
                    back     : {file: ""},
                    selfie   : {file: ""},
                    signature: {file: ""},
                    business_documents: [{file: ""},{file: ""},{file: ""},{file: ""}],
                    authorization_letter: {file: ""},
                }
            }
            sessionStorage.setItem('onboarding', JSON.stringify(data))
        },
        setState(state, action:PayloadAction<SignUp>) {
            return action.payload
        },
        checkEmail(state, action: PayloadAction<{email: string, access_code: string}>){},
        doneCheckEmail(state, action: PayloadAction<boolean>) {
            state.checkingEmail = false;
            state.validEmail    = action.payload;
        },
        getDepositoryService(state, action: PayloadAction<string>){},
        setDepositoryService(state, action: PayloadAction<{additional_meta: object}>){
            state.depositoryService = action.payload;
        },
        getSuggestedUsernames(state, action: PayloadAction<{
            firstname: string,
            lastname: string,
            number: string
        }>) {},
        setSuggestedUsernames(state, action: PayloadAction<string[]>) {
            state.suggestedUsernames = action.payload;
        },
        ifUsernameExists(state, action: PayloadAction<string>) {},
        usernameExists(state, action: PayloadAction<boolean | undefined>) {
            state.usernameExists = action.payload;
        },
        goToStep(state, action: PayloadAction<string>) {
            state.step = action.payload;
        },
        nextStep(state) {
            state.step = (
                state.step == 'sign_up'            ? 'account_info':
                state.step == 'account_info'       ? 'depository_account':
                state.step == 'depository_account' ? 'documents':
                state.step == 'documents'          ? 'review': ''
            )
        },
        previousStep(state) {
            state.step = (
                state.step == 'review'             ? 'documents':
                state.step == 'documents'          ? 'depository_account':
                state.step == 'depository_account' ? 'account_info':
                state.step == 'account_info'       ? 'sign_up': ''
            )
        },
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setAccountType(state, action: PayloadAction<string>) {
            if (!state.user_info) {
                state.user_info = {}
            }
            if (!state.user_info.account_type) {
                state.user_info.account_type = {
                    type: action.payload,
                    business: {
                        type: '',
                        category: '',
                        subcategory: ''
                    }
                }
            } else {
                state.user_info.account_type.type = action.payload
            }
        },
        setBasicInfo(state, action: PayloadAction<{
            firstName?   : string
            lastName?    : string
            mobileNumber?: MobileNumber,
            middleName?  : string,
            address?     : string,
            nationality? : string,
            birthdate?   : string,
            birthplace?  : string,
        }>) {
            state.firstName = action.payload.firstName
            state.lastName  = action.payload.lastName
            if (!state.user_info) {
                state.user_info = {}
            }
            if (!state.user_info.name) {
                state.user_info.name = `${action.payload.firstName} ${action.payload.lastName}`
            }
            state.user_info.mobile_number = action.payload.mobileNumber
            state.user_info.middle_name   = action.payload.middleName
            state.user_info.address       = action.payload.address
            state.user_info.nationality       = action.payload.nationality
            state.user_info.birth_date    = action.payload.birthdate
            state.user_info.birth_place   = action.payload.birthplace
        },
        setBusinessInfo(state, action: PayloadAction<{
            businessType?       : string
            businessCategory?   : string
            businessSubcategory?: string
            businessName?       : string
        
        }>) {
            if (!state.user_info) {
                state.user_info = {}
            }
            state.user_info.name = action.payload.businessName
            if (state.user_info.account_type) {
                state.user_info.account_type.business = {
                    type       : action.payload.businessType || '',
                    category   : action.payload.businessCategory || '',
                    subcategory: action.payload.businessSubcategory || '',
                }
            }
        },
        confirmPassword(state, action: PayloadAction<boolean>) {
            state.passwordConfirmed = action.payload;
        },
        setAccountInformation(state, action: PayloadAction<{
            username: string,
            password: string
        }>) {
            state.username = action.payload.username
            state.password = action.payload.password
        },
        setPaymentPage(state, action: PayloadAction<{
            description   : string
            profile_photo : string
            background_url: string
        }>) {
            if (!state.user_info) {
                state.user_info = {}
            }
            state.user_info.description    = action.payload.description
            state.user_info.profile_photo  = action.payload.profile_photo
            state.user_info.background_url = action.payload.background_url
        },
        setSocialMedia(state, action: PayloadAction<SocialMediaLinks>) {
            if (!state.user_info) {
                state.user_info = {}
            }
            state.user_info.social_media_links = action.payload;
        },
        sendOtp(state) {
            state.sendingOtp = true;
            state.verificationExpiry = undefined;
        },
        failedOtp(state) {
            state.sendingOtp = false;
        },
        otpSent(state, action: PayloadAction<string>) {
            state.otpSent            = true;
            state.sendingOtp         = false;
            state.verificationExpiry = action.payload;
        },
        verifyCodes(state, action: PayloadAction<{
            emailCode: string,
            smsCode: string
        }>) {
            state.verifying = true;
        },
        verificationResult(state, action: PayloadAction<{email: boolean, sms: boolean} | undefined>) {
            state.verifying = false;
            state.verificationResult = action.payload;
        },
        setDepositoryStatus(state, action: PayloadAction<string>) {
            state.depositoryStatus = action.payload
            if (state.user_info) {
                state.user_info.registration_status = {
                    depository: action.payload == 'complete'? 'review': 'pending',
                    documents : 'pending'
                }
            }
        },
        setDepositoryDetails(state, action: PayloadAction<DepositoryDetails>) {
            if (!state.user_info) {
                state.user_info = {}
            }
            state.user_info.depository_details = action.payload;
        },
        setDocumentStatus(state, action: PayloadAction<string>) {
            state.documentStatus = action.payload
            if (state.user_info) {
                state.user_info.registration_status = {
                    documents : action.payload == 'complete'? 'review': 'pending',
                    depository: state.user_info.registration_status? state.user_info.registration_status.depository: 'pending'
                }
            }
        },
        setDocumentMeta(state, action: PayloadAction<DocumentMeta>) {
            if (!state.user_info) {
                state.user_info = {}
            }
            state.user_info.document_meta = action.payload;
        },
        setSignature(state, action: PayloadAction<DocFile>) {
            if (!state.user_info) {
                state.user_info = {}
            }
            if (!state.user_info.document_meta) {
                state.user_info.document_meta = {type: ''}
            }
            state.user_info.document_meta.signature = action.payload;
        },
        createAccount(state) {
            state.creatingAccount = true;
        },
        accountCreated(state, action: PayloadAction<boolean>) {
            state.creatingAccount = false;
            state.accountCreated = action.payload;
        },
        setIp(state, action: PayloadAction<IP>) {
            state.ip_info = action.payload
        },
        cancel() {}
    },
});

export const {
    actions: signUpActions,
    reducer,
    name: sliceKey,
} = signUpSlice;
