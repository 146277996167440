import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSignUpState } from "./selectors";
import AccountSelection from "./AccountSelection";
import AccountSetup from "./AccountSetup";
import { reducer, signUpActions, sliceKey as key } from "./slice";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { useQuery } from "utils/useQuery";
import signupSaga from "./saga";
import SuccessSignup from "../SuccessSignup";
import { useHistory } from "react-router-dom";

import "styles/sass/onboarding.scss";

const Onboarding = () => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga: signupSaga });
    const query    = useQuery();
    const history  = useHistory();
    const dispatch = useDispatch();
    const signUp   = useSelector(selectSignUpState);
    const [loading, setLoading] = useState(true);
    
    window.onbeforeunload = e => { return ''; }

    const updateOnboardingState = state => {
        sessionStorage.setItem('onboarding', state)
        dispatch(signUpActions.setState(state))
    }

    useEffect(() => {
        let code        = query.get('code'),
            email       = code && sessionStorage.getItem('email') || query.get('email'),
            access_code = code && sessionStorage.getItem('access_code') || query.get('access_code') || '';

        if (email && access_code) {
            dispatch(signUpActions.checkEmail({email, access_code}));
            dispatch(signUpActions.setEmail(email));
        } else {
            history.push("/")
        }
    }, [])

    useEffect(() => {
        dispatch(signUpActions.saveState());
        setLoading(false)
    },[signUp])

    useEffect(() => {
        if (signUp.validEmail === false) {
            history.push("/login")
        }
    }, [signUp.validEmail])

    return (
        signUp.checkingEmail || loading? <></>:
        signUp.accountCreated?
        <SuccessSignup/>:
        <div className="onboarding">{
            signUp.step == "sign_up" && !signUp.user_info?.account_type?.type ?
            <AccountSelection/>:
            <AccountSetup/>
        }</div>
    )
}

export default Onboarding;