import { PayloadAction } from '@reduxjs/toolkit';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
    ContainerState,
    CoreData,
    User,
} from './types';

// The initial state of the PaymentPage container
export const initialState: ContainerState = {
    accessToken: null,
    username: null,
    user: null,
    coreData: {},
    isTopBarHidden: false,
    loading: true,
};

const paymentPageSlice = createSlice({
    name: 'paymentPage',
    initialState,
    reducers: {
        getAccessToken(
            state,
            action: PayloadAction<{
                username: string,
                isTopBarHidden: boolean,
            }>,
        ) {
            state.username = action.payload.username;
            state.isTopBarHidden = action.payload.isTopBarHidden;
        },
        getUserInformation(
            state,
            action: PayloadAction<{
                accessToken: string,
                user: User,
                coreData: CoreData,
                loading: boolean,
            }>,
        ) {
            state.accessToken = action.payload.accessToken;
            state.user = action.payload.user;
            state.coreData = action.payload.coreData;
            state.loading = action.payload.loading;
        },
        getDisbursementStatus(state, action: PayloadAction<{status: string} | undefined>) {
            state.disburse = action.payload?.status == 'on';
        },
        apiError(state, action: PayloadAction<CoreApiErrorType>) {},
    },
});

export const {
    actions: paymentPageActions,
    reducer,
    name: sliceKey,
} = paymentPageSlice;
