import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo, useEffect, useState } from "react";
import ButtonLoader from "app/components/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "app/common/auth/selectors";
import { userDashboardPageActions } from "../../slice";
import { GeneralComponent } from "app/common/slice";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";

const ReactivateAccountModal = memo((props: DefaultModalProps) => {

    const dispatch = useDispatch();
    const user     = useSelector(selectUserInfo);

    const [loading, setLoading] = useState(false);

    const onContinue = () => {
        setLoading(true)
        dispatch(userDashboardPageActions.updateActionTime())
        dispatch(userDashboardPageActions.reactivate(
            result => {
                if (result !== undefined) {
                    dispatch(GeneralComponent.getUserInfo())
                } else if (result === 401) {
                    dispatch(userDashboardPageActions.error('idle'))
                    dispatch(GeneralComponent.removeAuth())
                    dispatch(GeneralComponent.setUserInfo())
                    localStorage.removeItem('auth')
                } else {
                    setLoading(false)
                }
            }
        ));
    }

    useEffect(() => {
        if(props.state[0]) {
            setLoading(false)
            props.state[1](user?.status == 'deactivated')
        }
    }, [user?.status])

    return (
        <Modal {...props} disableBackdropClick disableEscapeKeyDown
            style   = "action-box content-box account-alert larger_modal center"
            onClose = {() => setLoading(false)}
        >
            <h2>Reactivate Account</h2>
            <ErrorOutlineOutlined style={{ fontSize: 150, color: red[600] }}/>
            <div className="body">
                <p>You are about to Reactivate your Account.</p><br/>
                <p>Your Payment Page will be enabled, and  be able to start<br/>accepting payment again.</p>
            </div>
            <ButtonLoader hasLoader
                loading = {loading}
                name    = 'continue'
                style   = 'filled blue'
                text    = "Continue"
                onClick = {onContinue}
            />
        </Modal>
    )
})

export default ReactivateAccountModal