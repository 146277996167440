import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { selectAccessToken, selectUserInfo } from 'app/common/auth/selectors';
import { PAGE_STATUS, PRMOTIONAL_CONTENT } from 'app/common/core_api/resources';
import { GeneralComponent } from 'app/common/slice';
import ButtonLoader from 'app/components/ButtonLoader';
import InputField from 'app/components/InputField';
import SuccessModal from 'app/components/Modal/SuccessModal';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { checkDimension } from 'utils';
import { clients } from 'utils/options';
import { selectUserDashboard } from '../../selectors';
import { userDashboardPageActions } from '../../slice';
import ToggleSwitch from '../ToggleSwitch';
import DeactivateAccountModal from '../modals/DeactivateAccountModal';
import DeleteAccountModal from '../modals/DeleteAccountModal';
import ReactivateAccountModal from '../modals/ReactivateAccountModal';
import ResetPasswordModal from '../modals/ResetPasswordModal';

const Settings = memo(() => {
    const dispatch      = useDispatch();
    const access_token  = useSelector(selectAccessToken);
    const user          = useSelector(selectUserInfo);
    const userDashboard = useSelector(selectUserDashboard);
    const isClient      = clients.includes(user?.username || '');
    const isApi         = user?.username && user?.groups?.includes(user.username);
    const isPremium     = user?.groups?.includes("Premium");
    const disablePaymentMethods = !user?.groups?.includes("Premium") && !isClient;

    const [successModal, setSuccessModal]             = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [reactivateModal, setReactivateModal]       = useState(false);
    const [deactivateModal, setDeactivateModal]       = useState(false);
    const [deleteModal, setDeleteModal]               = useState(false);
    const [prompt, setPrompt]                         = useState(false);
    const [is_twofa_enabled, setTwoFAStatus]          = useState(user?.is_twofa_enabled);
    const [is_sms_enabled, setSmsStatus]              = useState(user?.is_sms_enabled);

    const [pageStatus, setStatus]                     = useState<any>();
    const [maintenance, setMaintenance]               = useState<boolean>();
    const [shortDescription, setShortDescription]     = useState<string>("");

    const [promotionalImage, setPromotionalImage]           = useState("");
    const [largePromotionalImage, setLargePromotionalImage] = useState(false);
    const [errorPromotionalImage, setErrorPromotionalImage] = useState(false);
    
    const [charge_fees_to, setChargeFeesTo] = useState<'recipient' | 'sender'>(user?.charge_fees_to || "recipient");
    const [payment_methods, setPaymentMethods] = useState(user?.payment_methods || {
        mastercard_visa   : true,
        bank_fund_transfer: true,
        online_banking    : true,
        e_wallet          : true,
        qr_ph             : true,
        over_the_counter  : true,
        others            : true
    });
    const [fulfillment_methods, setFulfillmentMethods] = useState(user?.fulfillment_methods || {
        instore_purchase: true,
        pick_up         : true,
        delivery        : true,
    });

    const [loadingStatus, setLoadingStatus] = useState(true);

    const getStatus = () => {
        setLoadingStatus(true)
        fetch(
            PAGE_STATUS.replace(":username", user?.username || ""),
            {headers: {'Authorization': `Bearer ${access_token}`}}
        )
        .then(resp => resp.json())
        .then(data => {
            setStatus(data)
            setMaintenance(data.status == 'off')
            setPromotionalImage(data.icon)
            setShortDescription(data.message)
        })
        .finally(() => setLoadingStatus(false))
    }

    const updateStatus = (status) => {
        setLoadingStatus(true)
        fetch(
            PAGE_STATUS.replace(":username", user?.username || ""),
            {
                method : 'PUT',
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type' : 'application/json'
                },
                body   : JSON.stringify({
                    type: 'object',
                    data: {...pageStatus, status}
                })
            }
        ).finally(getStatus)
    }
    
    const getPromotionalImage = () => {
        fetch(
            PRMOTIONAL_CONTENT.replace(":username", user?.username || ""),
            {headers: {'Authorization': `Bearer ${access_token}`}}
        )
        .then(resp => resp.json())
        .then(data => setPromotionalImage(data))
    }

    const setPaymentMethod = (method) => {
        const methods = {...payment_methods}
        methods[method] = !methods[method]
        setPaymentMethods(methods)
    }

    const setFulfillmentMethod = (method) => {
        const methods = {...fulfillment_methods}
        methods[method] = !methods[method]
        setFulfillmentMethods(methods)
    }

    const updateSettings = () => {
        dispatch(userDashboardPageActions.updateSettings({
            is_twofa_enabled,
            is_sms_enabled,
            charge_fees_to,
            payment_methods,
            fulfillment_methods,
            page_status: {
                ...pageStatus,
                icon: promotionalImage,
                message: shortDescription
            },
        }))
    }

    const updateActionTime = () => dispatch(userDashboardPageActions.updateActionTime())

    const setImage = async (stateSetter, errorSetter, largeErrorSetter, file) => {
        stateSetter('');
        if (file.size > 500000) {
            largeErrorSetter(true);
            return;
        }
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.5,
            useWebWorker: true
        });
        const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
        const isValidSize = await checkDimension(imageUri)
        stateSetter(isValidSize ? imageUri: undefined)
        errorSetter(!isValidSize)
        largeErrorSetter(false);
    }

    const setChargeFees = () => setChargeFeesTo(charge_fees_to == 'recipient'? 'sender': 'recipient')

    useEffect(() => {
        if (isClient) {
            getStatus()
        }
    }, [])

    useEffect(() => {
        if(!userDashboard.error) {
            setSuccessModal(userDashboard.updatingSettings === false)
            dispatch(GeneralComponent.getUserInfo())
        }
    }, [userDashboard.error, userDashboard.updatingSettings])

    useEffect(() => {
        updateActionTime()
        setPrompt(
            is_twofa_enabled     != user?.is_twofa_enabled ||
            is_sms_enabled       != user?.is_sms_enabled ||
            charge_fees_to       != user?.charge_fees_to ||
            payment_methods      != user?.payment_methods ||
            fulfillment_methods  != user?.fulfillment_methods
        )
    }, [
        is_twofa_enabled,
        is_sms_enabled,
        charge_fees_to,
        payment_methods,
        fulfillment_methods
    ])

    window.onbeforeunload = prompt? (_ => ""): null
    const v2 = process.env.REACT_APP_USE_KEYCLOAK == 'True'

    const openChangePlanModal = () => dispatch(userDashboardPageActions.setChangePlan())

    return <>
        <Prompt when={prompt} message={"Changes you made may not be saved."}/>
        <ResetPasswordModal     state={[resetPasswordModal, setResetPasswordModal]}/>
        <ReactivateAccountModal state={[reactivateModal, setReactivateModal]}/>
        <DeactivateAccountModal state={[deactivateModal, setDeactivateModal]}/>
        <DeleteAccountModal     state={[deleteModal, setDeleteModal]}/>
        <section className="pseudo-form settings">
            <section className="divider"><span>ACCOUNT SETTINGS</span></section>
            {v2 && user?.status == 'deactivated' && !isClient && <Grid container className="container-grid your-acount setting">
                <Grid item xs={12}>
                    <Typography variant='h5'>Your Account</Typography><br/>
                    {user?.status == 'deactivated'?
                        <p>
                            You are currently <span className="bold">Deactivated</span>
                        </p>:
                        <p>
                            You are currently on <span className="bold" style={{textTransform: "capitalize"}}>{isPremium? "Premium": "Standard"} {user?.account_type?.type} Account</span><br/>
                            {isPremium && <>
                                This plan's next renewal date is on: {moment(user?.last_renewal_date).add(user?.is_trial? 90: 30, 'days').format("MMMM DD, YYYY")}<br/>
                                <p className="italic">₱ 1,000.00 will be automatically deducted to a payout amount to renew plan.</p>
                            </>}
                        </p>
                    }
                    <section className="divider"></section>
                    {
                        user?.status == 'deactivated'?
                        <p>
                            To Reactivate your account, click <a onClick={() => updateActionTime() && setReactivateModal(true)}>here</a>
                        </p>:
                        isPremium? <p>
                            <a onClick={openChangePlanModal}>Change your account</a> - Don't need the additional features a Premium plan offers?<br/>
                            You can quickly switch to a Standard plan easily, anytime
                        </p>:<p>
                            <a onClick={openChangePlanModal}>Upgrade your account</a> - Add additional features to help you and your business.<br/>
                            You can quickly quickly upgrade to a Premium plan easily, anytime.
                        </p>
                    }
                </Grid>
            </Grid>}
            <Grid container className="two-fa setting">
                <Grid item xs={12}>
                    <Typography variant='h5'>Two Factor Authentication</Typography><br/>
                    <p>
                        Two Factor Authentication is a security process where you provide two forms of ID to login to your JustPayto account. It helps keep your account safe, by having you provide not only your username and password, but also a security code sent to your registered mobile number.
                        (applicable SMS charges may apply)
                    </p>
                    
                    <InputField type="checkbox" title="Enable" name="2fa" checked={is_twofa_enabled} onChange={() => setTwoFAStatus(!is_twofa_enabled)}/>
                </Grid>
            </Grid>
            {!isApi && !isClient && <Grid container className="sms-notification setting">
                <Grid item xs={12}>
                    <Typography variant='h5'>SMS Notifications</Typography><br/>
                    <p>
                        JustPayto offers several automated SMS notifications, along side Email notifications, to keep you updated on your account activity faster. This includes notifications when you receive money, when money is successfully transferred to your nominated depository account, and when we detect possible account intrusion.
                        (applicable SMS charges may apply)
                    </p>
                    <InputField type="checkbox" title="Enable" name="sms" readonly={!isPremium && !isClient} checked={isPremium && is_sms_enabled}  onChange={() => setSmsStatus(!is_sms_enabled)}/>
                </Grid>
            </Grid>}
            {v2 && !isApi && !isClient && <Grid container className="fees setting">
                <Grid item xs={12}>
                    <Typography variant='h5'>Who pays the fees?</Typography><br/>
                    <p>
                        Change who would pay the fees (bank fees + system fees + other fees) associated with your transaction. You can pass your fees on the customer by including the fee into the final change amount legal. It is important to insure that this action complies with any applicable laws that pertain to your business. If you are unsure whether such laws apply, consult with your counsel.
                    </p>
                    <InputField type="radio" name="charge_fees_to" readonly={!isPremium && !isClient} checked={charge_fees_to == 'recipient'} onChange={setChargeFees} title="Recipient - Fees will be deducted to the amount deposited to your depository account." />
                    <InputField type="radio" name="charge_fees_to" readonly={!isPremium && !isClient} checked={charge_fees_to == 'sender'}    onChange={setChargeFees}    title="Sender - Fees will be added to the amount your customer will pay." />
                </Grid>
            </Grid>}
            {v2 && !isApi && <Grid container className="payment-method setting">
                <Grid item xs={12}>
                    <Typography variant='h5'>Payment Methods</Typography><br/>
                    <p>
                        Enable or disable the payment methods you prefer to accept. Your customer (the sender) can only choose from the payment methods you enable.
                    </p>
                    <InputField type="checkbox" name="mastercard_visa"    readonly={disablePaymentMethods} checked={disablePaymentMethods || payment_methods.mastercard_visa}    onChange={() => setPaymentMethod("mastercard_visa")}    title="Credit/Debit Cards - any card with a Visa or Mastercard logo"/>
                    <InputField type="checkbox" name="bank_fund_transfer" readonly={disablePaymentMethods} checked={disablePaymentMethods || payment_methods.bank_fund_transfer} onChange={() => setPaymentMethod("bank_fund_transfer")} title="Bank Transfers - direct debit or fund transfer from a bank account"/>
                    <InputField type="checkbox" name="online_banking"     readonly={disablePaymentMethods} checked={disablePaymentMethods || payment_methods.online_banking}     onChange={() => setPaymentMethod("online_banking")}     title="Online Banking - transfer funds/payment through a bank's web portal or app"/>
                    <InputField type="checkbox" name="e_wallet"           readonly={disablePaymentMethods} checked={disablePaymentMethods || payment_methods.e_wallet}           onChange={() => setPaymentMethod("e_wallet")}           title="E-wallets - digital cash, e-wallet, or crypto"/>
                    <InputField type="checkbox" name="over_the_counter"   readonly={disablePaymentMethods} checked={disablePaymentMethods || payment_methods.over_the_counter}   onChange={() => setPaymentMethod("over_the_counter")}   title="Over-the-counter - receive funds/payment from banks or remittance centers"/>
                    <InputField type="checkbox" name="qr_ph"              readonly={disablePaymentMethods} checked={disablePaymentMethods || payment_methods.qr_ph}              onChange={() => setPaymentMethod("qr_ph")}              title="QR Ph - scan QR to any partner supported by QR Ph"/>
                    <InputField type="checkbox" name="others"             readonly={disablePaymentMethods} checked={disablePaymentMethods || payment_methods.others}             onChange={() => setPaymentMethod("others")}             title="Others - other ways of accessing and using funds"/>
                </Grid>
            </Grid>}
            {v2 && !isApi && user?.account_type?.type == 'business' &&
                <Grid container className="fulfillment-method setting">
                    <Grid item xs={12}>
                        <Typography variant='h5'>Fulfillment Methods</Typography><br/>
                        <p>
                            Enable or disable the fulfillment methods you prefer to provide. Your customer can only choose from the payment methods you enable.
                        </p>
                        <InputField type="checkbox" name="instore_purchase"  readonly={!isPremium} checked={!isPremium || fulfillment_methods.instore_purchase}  onChange={() => setFulfillmentMethod("instore_purchase")}  title="For Dine-in / On-site - payment is done within your physical establishment"/>
                        <InputField type="checkbox" name="pick_up"           readonly={!isPremium} checked={!isPremium || fulfillment_methods.pick_up}           onChange={() => setFulfillmentMethod("pick_up")}           title="For Pick-up - your customer can choose time to pick-up the purchased product"/>
                        <InputField type="checkbox" name="delivery"          readonly={!isPremium} checked={!isPremium || fulfillment_methods.delivery}          onChange={() => setFulfillmentMethod("delivery")}          title="For Delivery - our delivery partners will take care of the delivery to your customer"/>
                    </Grid>
                </Grid>
            }
            <Grid container className="reset-password setting">
                <Grid item xs={12}>
                    <Typography variant='h5'>Reset Password</Typography><br/>
                    <p>
                        <a onClick={() => setResetPasswordModal(true)}>Reset password now</a> - you will receive email confirmation, and instruction how to proceed.
                    </p>
                </Grid>
            </Grid>
            {v2 && !['deactivated', 'deleted'].includes(user?.status || '') && <Grid container className="container-grid setting">
                <Grid item xs={12}>
                    <Typography variant='h5'>Deactivate {!isClient && !isApi && 'or delete'} your Payment Page</Typography><br/>
                    <p>
                        If you want to temporarily stop accepting payment, you can deactivate your payment page.<br/>
                        If you want to permanently delete your JustPayto account, let us know.
                    </p><br/>
                    <p>
                        <a href="#" onClick={() => updateActionTime() && setDeactivateModal(true)}>Temporarily Deactivate Account</a> - Your Payment Page will be disabled and you won't be able to accept payment. Your Payment address will still work, and you will still have access to your account dashboard.
                    </p><br/>
                    {!isApi && !isClient && <p>
                        <a href="#" onClick={() => updateActionTime() && setDeleteModal(true)}>Permanently Delete Account</a> - When you delete your JustPayto account, you won't be able to retrieve the transaction information done with your Payment Page.
                    </p>}
                </Grid>
            </Grid>}
            {
                isClient && <>{
                    !pageStatus?
                    <CircularProgress/>: <>
                    <Grid container className="status setting">
                        <Grid item xs={12}>
                            <Typography variant='h5'>Status</Typography><br/>
                            {/* <InputField type="checkbox" title="Live" name="status_live" checked={status == 'live'} onChange={() => setStatus(status == 'live'? 'maintenance': 'live')}/>
                            <InputField type="checkbox" title="Maintenance" name="status_maintenance" checked={status == 'maintenance'} onChange={() => setStatus(status == 'live'? 'maintenance': 'live')}/> */}
                            <Box marginX="0.5rem">
                                <Typography variant="body1">Your Payment Page is <strong>{pageStatus?.status == 'off'? "under maintenance": "live"}</strong></Typography>
                            </Box>
                            <Box marginX="2rem">
                                {
                                    loadingStatus?
                                    <CircularProgress/>:
                                    <ToggleSwitch name="status" title="Under Maintenance" checked={pageStatus?.status == 'off'} onChecked={() => updateStatus(pageStatus.status == 'on'? 'off': 'on')}/>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className="promotional setting">
                        <Grid item xs={12}>
                            <Typography variant='h5'>Promotional Image</Typography><br/>
                            <InputField
                                type          = 'file'
                                name          = 'promotional_image'
                                accept        = 'image/*'
                                style         = {{container: 'full-width'}}
                                instruction   = 'At least 100 x 100, maximum file size 500kb'
                                value         = {promotionalImage}
                                error         = {largePromotionalImage || errorPromotionalImage}
                                error_message = {
                                    largePromotionalImage ? "Image file size is too large":
                                    errorPromotionalImage ? "Image size is too small": ""
                                }
                                onChange = {e => e.target.files && setImage(
                                    setPromotionalImage,
                                    setErrorPromotionalImage,
                                    setLargePromotionalImage,
                                    e.target.files[0]
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="description setting">
                        <Grid item xs={12}>
                            <Typography variant='h5'>Short Description</Typography><br/>
                            <InputField 
                                type             = "textarea"
                                name             = "short_description"
                                label            = {<span className="right">{shortDescription.length || 0}/140</span>}
                                value            = {shortDescription}
                                style            = {{container: 'full-width'}}
                                onTextAreaChange = {e => setShortDescription(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    </>
                }</>
            }
            <div className="pseudo-form settings">
                <section className="footer">
                    <ButtonLoader submit hasLoader
                        onClick = {updateSettings}
                        loading = {userDashboard.updatingSettings}
                        style   = 'filled blue'
                        text    = 'Update'
                    />
                </section>
            </div>
        </section>
            <SuccessModal
                state     = {[successModal, setSuccessModal]}
                className = "customizations-success-modal larger_modal"
                message   = "Updated Settings"
                onClose   = {() => dispatch(userDashboardPageActions.resetUpdateSettings())}
            />
    </>
})

export default Settings;
