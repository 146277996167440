import { PayloadAction }                from '@reduxjs/toolkit';
import { delay, call, put, takeLatest } from 'redux-saga/effects';
import { request }                      from 'utils/request';
import { FORGOT_PASSWORD }              from 'app/common/core_api/resources';
import { ForgotPasswordPageActions }    from './slice';

export function* submitRequest(action: PayloadAction<string>) {
    try {
        let options: RequestInit = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(action.payload)
        }
        let result = yield call(request, FORGOT_PASSWORD, options);
        yield put(ForgotPasswordPageActions.requestResult(result));
    } catch (err) {
        console.log(err)
        yield put(ForgotPasswordPageActions.requestResult({
            error : "An unexpected error occured",
            result: false
        }))
    }
}

function* forgotPasswordSaga() {
    yield takeLatest(ForgotPasswordPageActions.submitRequest.type, submitRequest);
}

export default forgotPasswordSaga;