import React, { memo } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            position: "absolute",
            textAlign: "center",
            maxWidth: "500px",
            padding: "0 20px",
            height: "100%",
            minHeight: "480px",
            left: "0",
            right: "0",
            margin: "auto",
            "& > div": {
                margin: "auto",
                height: "fit-content",
                overflow: "auto",
                minHeight: "400px",
            },
            "& p": {
                margin: "30px 0"
            },
        }
    }),
);
const Restricted = memo(() => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div>
                <Typography variant="h1">Oops!</Typography>
                <Typography>The network you are connected to have been temporarily blocked because of suspicious activity. If you are connected to a VPN, a proxy, or using tor, retry after disconnecting from it.</Typography>
                <Typography>While we know privacy, security and speed is important to you, we need to restrict certain actions to protect you, our users, and our community.</Typography>
                <Typography>Tell us if you think we made a mistake by messaging us through our support chat.</Typography>
            </div>
        </div>
    )
})

export default Restricted