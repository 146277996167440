
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from ".";
import { selectLandingPage } from "app/containers/HomePage/selectors";
import { landingPageActions } from "app/containers/HomePage/slice";

interface IAnnouncementModal {
    src?: string
    modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const AnnouncementModal = memo(({src: srcDoc, modalState}: IAnnouncementModal) => {

    useEffect(() => {
        if (srcDoc) {
            modalState[1](!!srcDoc);
        }
    }, [srcDoc])

    return (
        <Modal
            state      = {modalState}
            style      = 'information'
            headerText = 'Announcement'
            buttonText = 'Close'
        >
            <iframe src={srcDoc} width="500px" height="500px" title="announcement"></iframe>
        </Modal>
    )
});

export default AnnouncementModal;