import React, { memo } from "react";

import "styles/sass/snackbar.scss";

interface Props {
    show?: boolean
    children?: React.ReactNode
}

const Snackbar = memo(({show, children}: Props) => {
    return (
        <div className={`snackbar ${show? "show": ""}`}>
            {children}
        </div>
    )
})

export default Snackbar