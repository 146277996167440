import React, { memo, ReactNode } from "react";
import { Helmet } from "react-helmet-async";

// Components
import Header from "app/components/Header";
import Footer from "app/components/Footer";

// Load Images
import feature_image from 'assets/img/feature_image.png';

interface Props {
    styles?         : ReactNode
    meta?           : ReactNode
    children?       : ReactNode
    redirect?       : string
    title?          : string
    description?    : string
    backgroundImage?: string
    hasHeader?      : boolean
    hasFooter?      : boolean
}

const MasterPage = memo((props: Props) => {
    const {
        // Header Tags
        styles, meta, title, description, 
        // Content
        children, backgroundImage,
        // Props
        hasHeader, hasFooter,
    } = props;
    return (
    <>
        <Helmet>
            <title>{title} | { process.env.REACT_APP_NAME }</title>
            <meta name="description" content={description}/>
            {
                title?
                <meta property="og:title" content={`${title} | ${ process.env.REACT_APP_NAME }`}/>:
                <meta property="og:title" content={process.env.REACT_APP_NAME}/>
            }
            <meta property="og:description" content={description}/>
            <meta property="og:type"        content="website"/>
            <meta property="og:url"         content={window.location.href}/>
            <meta property="og:image"       content={feature_image}/>
            {meta}
            <link rel="manifest" href="/manifest.json"/>
            {styles}
        </Helmet>
        {hasHeader && <Header/>}
        <main id="main" className={backgroundImage && "has-background"} style={{backgroundImage: `url(${backgroundImage})`}}>
            {children}
        </main>
        {hasFooter && <Footer/>}
    </>
    )
})

export default MasterPage;