import React, { memo } from "react";
import Modal, { DefaultModalProps } from '.';
import ButtonLoader from "../ButtonLoader";
import warning_icon from 'assets/img/warning.svg';

import 'styles/sass/dialog.scss';

interface Props extends DefaultModalProps {
    message?: string
    onConfirm: () => void
    onClose?: () => void
}

const SuccessModal = memo((props: Props) => {
    return (
        <Modal
            disableEscapeKeyDown
            disableBackdropClick
            disableCloseButton
            state = {props.state}
            style = 'action-box content-box success confirm-modal'
        >
            <h1 className="center">Confirm</h1>
            <img className="logo" src={warning_icon} alt="Error Icon"/>
            <p className="center">{props.message || ''}</p>
            <ButtonLoader hasLoader cssStyle={{margin: "8px 0"}}
                name  = 'continue'
                style = 'filled blue'
                text  = 'Continue'
                onClick = {props.onConfirm}
            />
            <ButtonLoader hasLoader cssStyle={{margin: "8px 0"}}
                name  = 'cancel'
                style = 'bordered blue'
                text  = 'Cancel'
                onClick = {props.onClose || (() => props.state[1](false))}
            />
        </Modal>
    )
});

export default SuccessModal;