import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { memo } from "react";

interface Props {
    text?        : string
    placeholder? : string
    icon?        : string
    options?     : any
    style?       : {container: string}
    onTextChange?: (value) => void
    onIconChange?: (value) => void
}

const IconSelectField = memo(({text, placeholder, icon, options, style, onTextChange, onIconChange}: Props) => {
    const [open, setOpen] = useState(false);

    return (
        <section className = {`${style?.container} input-box`}>
            <section className={`input-prepend icon right`}>
                <img className="input-icon pointer" src={icon} onClick={() => setOpen(!open)}/>
                <input
                    type        = "text"
                    placeholder = {placeholder}
                    value       = {text}
                    onChange    = {e => onTextChange && onTextChange(e.target.value)}
                />
                {/* Options */}
                <div className={ `absolute-container right ${open? 'open': ''}`}>
                    <section
                        className = "selection icons"
                        onClick   = {e => e.stopPropagation()}
                        onFocus   = {() => setOpen(true)}
                    >
                        <Grid container className="dropdown body">{
                            options && Object.keys(options).map(key => (
                                <Grid item xs={4}>
                                    <img src={options[key]} width="25px" onClick={() => {
                                        if (onIconChange) {
                                            setOpen(false)
                                            onIconChange(options[key])
                                        }
                                    }}/>
                                </Grid>
                            ))
                        }</Grid>
                    </section>
                </div>
            </section>
        </section>
    )
})

export default IconSelectField