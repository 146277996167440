import { partners } from 'app/containers/HomePage';
import { landingPageSaga } from 'app/containers/HomePage/saga';
import { selectLandingPage } from 'app/containers/HomePage/selectors';
import { landingPageActions, reducer, sliceKey } from 'app/containers/HomePage/slice';
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from 'utils';
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";

// Components
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import ErrorModal from 'app/components/Modal/ErrorModal';
import GreatModal from 'app/components/Modal/GreatModal';
import { Link } from "react-router-dom";
import MasterPage from "../MasterPage";

// Assets
import bg from 'assets/img/background/justpayto-philippines-payments-2.jpg';
import logo from 'assets/img/landing-page/jpt-logo.svg';

// Styles
import 'styles/sass/invites.scss';

const GetEarlyAccessPage = memo(() => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: landingPageSaga });
    // const partnerSlugs = ['autosweeprfid', 'bayadcenter', 'beep', 'meralco', 'kload', 'globe', 'smart', 'pldt', 'maynilad']
    const dispatch     = useDispatch();
    const landingPage  = useSelector(selectLandingPage);
    
    const successModal                      = useState(false);
    const errorModal                        = useState(false);
    const urlParams                         = new URLSearchParams(window.location.search);
    const [email, setEmail]                 = useState('');
    const [validEmail, setValidEmail]       = useState(true);
    const [emptyEmail, setEmptyEmail]       = useState(false);
    const [gettingAccess, setGettingAccess] = useState(false);
    const [emailExists, setExists]          = useState(landingPage.checkEmailResult !== undefined && !landingPage.checkEmailResult);
    const [existingEmail, setExistingEmail] = useState('');
    const [verifyError, setVerifyError]     = useState(landingPage.invalidEmail);

    const Partners = () => (
        <section className="partners">
        {
            partners.map(partner =>
                <a href={`/${partner.slug}`} title={partner.name} target="_blank" rel="noreferrer">
                    <figure className="avatar">
                        <img src={partner.logo_url} alt={partner.name} /> 
                    </figure>
                </a>
            )
        }
        </section>
    )

    const getEarlyAccess = async () => {
        setEmptyEmail(!email);
        if (!email) return;
        setGettingAccess(true);
        setVerifyError(false)
        let isValid = validateEmail(email);
        setValidEmail(isValid);
        dispatch(landingPageActions.getEarlyAccess(email))
        // if (isValid) {
        //     verifyEmail(email, (result, error) => {
        //         if (result) {
        //             dispatch(landingPageActions.getEarlyAccess(email))
        //         } else {
        //             setVerifyError(error)
        //             setGettingAccess(false);
        //         }
        //     })
        // } else {
        //     setGettingAccess(false);
        // }
    }

    useEffect(() => {
        if (landingPage.signUpResult !== undefined) {
            setGettingAccess(false);
            if (!landingPage.signUpResult) {
                setExistingEmail(email);
                setExists(true);
            } else if (landingPage.signUpResult) {
                setEmail('')
                successModal[1](true);
            }
        }
    }, [landingPage.signUpResult])

    useEffect(() => {
        if (successModal[0]) {
            setEmail('');
        }
    }, [successModal])

    // Checks if page redirects from payment page 
    useEffect(() => {
        if (urlParams.get('username')) {
            errorModal[1](true);
        }
    }, []);

    useEffect(() => {
        setVerifyError(landingPage.invalidEmail);
        setGettingAccess(false);
    }, [landingPage.invalidEmail])

    return (<>
        <GreatModal state = {successModal} message = {<>You're on the waitlist.<br/>Please check your email</>}/>
        <ErrorModal state={errorModal} className={urlParams.get('status') as string} title="Error" children={<><span>{urlParams.get('username')}</span> is not found. Make it yours!</>}/>
        <MasterPage hasHeader
            title = "Early Access"
            backgroundImage = {bg}
        >
            <section className="action-box-wrapper">
                <section className="invites action-box content-box small">
                    <img className="logo" src={logo} alt={process.env.APP_NAME}/>
                    <h3>We are currently in our private beta.<br />Sign Up to get our invitation.</h3>
                    <div jpt-form="invites" className="pseudo-form">
                        <InputField loader
                            valid       = {!(emailExists || emptyEmail || verifyError) && !!email && validEmail}
                            error       = {emailExists || emptyEmail || !!verifyError || !validEmail}
                            value       = {email}
                            type        = "email"
                            name        = "email"
                            placeholder = "Your Email"
                            max         = {64}
                            onChange    = {e => {
                                setVerifyError(false)
                                setExists(existingEmail == e.target.value)
                                setEmail(e.target.value)
                                setEmptyEmail(!e.target.value)
                                setValidEmail(validateEmail(e.target.value));
                            }}
                            style       = {{inputContainer: 'icon icon-email'}}/>
                        <div className="error-container active" data-error-container>
                            <small id="email-error" className="error">
                            {
                                emptyEmail ? 'Email is required':
                                !validEmail || verifyError ? 'Please enter a valid email address':
                                emailExists ? 'Email is already taken':''
                            }
                            </small>
                        </div>
                        <ButtonLoader submit hasLoader
                            loading = {gettingAccess}
                            name  = 'early-access'
                            style = 'filled blue'
                            text  = 'Get Early Access'
                            onClick = {() => getEarlyAccess()}
                        />
                    </div>
                    <section className="divider"><span>OR</span></section>
                    <h3>Start Paying Bills</h3>
                    <Partners/>
                    <p className="biller_link">
                        <Link to="/#partners" className="close">
                            See all Billers
                        </Link>
                    </p>
                </section>
            </section>
        </MasterPage>
    </>)
})

export default GetEarlyAccessPage;