import ButtonLoader from "app/components/ButtonLoader";
import Modal, { DefaultModalProps } from "app/components/Modal";
import React, { memo, useEffect, useState } from "react";
import { formatDatetime, numberFormat } from "utils";

// Styles
import { useDispatch, useSelector } from "react-redux";
import 'styles/sass/user-dashboard.scss';
import { fulfillment_methods } from "utils/options";
import { selectUserDashboard } from "../../selectors";
import { userDashboardPageActions } from "../../slice";
import DeliveryConfirmModal from "./DeliveryConfirmModal";

interface Props extends DefaultModalProps {
    transaction?: any
}

const DeliveryModal = memo(({state, transaction}: Props) => {
    const dispatch        = useDispatch();
    const userDashboard   = useSelector(selectUserDashboard)

    let is_petron   = transaction?.is_petron,
        is_business = transaction?.receiver_details?.account_type == 'business',
        is_donation = transaction?.is_donation,
        is_client   = transaction?.is_partner,
        sender      = is_business? 'Customer': 'Sender';

    /**
     * Transaction Details
     */
    const source_of_funds = transaction?.source_of_funds;
    const amount_details  = transaction?.amount_details;
    const sender_details  = transaction?.sender_details;
    const order_details   = transaction?.additional_details?.order_details;
    // END

    /**
     * Format Amounts With Currency
     */
    const max_amount_length = numberFormat(transaction?.amount_sent).length
    const amountFormat = (amount) => {
        const number       = numberFormat(amount)
        const no_of_spaces = max_amount_length - number.length + 1
        const spaces = new Array(no_of_spaces).join("\u00a0\u00a0")
        return spaces + number
    }
    const formatCurrency  = (amount) => (
        amount_details?.currency == 'PHP'?
        `₱ ${amountFormat(amount)}`:
        `${amountFormat(amount)} ${amount_details?.currency}`
    )
    // END

    let amount_sent      = formatCurrency(+(transaction?.amount_sent || 0)),
        other_charges    = formatCurrency(+(transaction?.other_charges || 0)),
        system_fee       = formatCurrency(+(amount_details?.fees?.system || 0)),
        sending_fee      = formatCurrency(+(amount_details?.fees?.sending?.fee || 0)),
        receiving_fee    = formatCurrency(+(amount_details?.fees?.receiving?.fee || 0)),
        fullfillment_fee = formatCurrency(
            +(amount_details?.fees?.delivery?.actual_fee || 0) +
            +(amount_details?.fees?.delivery?.system || 0)
        );
    
    let sender_name    = is_business && !is_donation? "payer": "sender",
    charge_fees_to = `- charge to ${is_client || transaction?.charge_fees_to == 'sender'? sender_name : 'recipient'}`;

    const headers = {
        not_ready: {
            title: "Order/Item ready for Delivery",
            text : <span>Our partner courier will pick-up the order/item for you</span>
        },
        for_pickup: {
            title: "Courier is on the way",
            text : <span>Our partner courier will <span className="highlight">pick-up</span> the order/item from you</span>
        },
        for_delivery: {
            title: "Courier is on the way",
            text : <span>Our partner courier will <span className="highlight">deliver</span> the order/item to your customer</span>
        },
        delivered: {
            title: "Delivery Successful",
            text : <span>Our partner courier successfully delivered the order/item</span>
        },
        canceled: {
            title: "Delivery cancelled",
            text : ""
        },
    }

    const titleColor = (
        order_details?.delivery_status == 'not_ready'? 'red':
        order_details?.delivery_status == 'canceled' ? 'red':
        order_details?.delivery_status == 'delivered' ? 'green': 'blue'
    )

    const [cancel, setCancel] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [loading, setLoading] = useState<boolean | undefined>()

    useEffect(() => {
        if (loading === true) {
            setLoading(false)
        }
        state[1](!!userDashboard.transactions && !!transaction)
    }, [userDashboard.transactions])
    useEffect(() => {
        if (loading === false) {
            setLoading(undefined)
        }
    }, [loading])

    const closeModal = () => state[1](false);
    const updateDelivery = () => {
        if (order_details?.delivery_status != 'canceled' && order_details?.delivery_status != 'delivered') {
            setLoading(true)
            dispatch(userDashboardPageActions.updateDelivery({
                transactionNo: transaction?.payment_id || '',
                action: (
                    cancel? "cancel":
                    order_details?.delivery_status == "not_ready"   ? "order"   :
                    order_details?.delivery_status == "for_pickup"  ? "pick-up" :
                    order_details?.delivery_status == "for_delivery"? "complete":
                    ""
                )
            }))
        } else {
            closeModal()
        }
    }

    return <>
        <Modal state = {state} style = 'transaction larger_modal'>
            <h2 className={`status ${titleColor}`}>{headers[order_details?.delivery_status || '']?.title}</h2>
            <h3 className="status-details">
                {headers[order_details?.delivery_status || '']?.text}
                <small className="transaction-number">
                    Transaction Number: <span>{transaction?.payment_id}</span>
                </small>
            </h3>
            <section className="divider"></section>
            <div className="message">
                <h3>
                    <span>{sender_details?.message}</span>
                </h3>
                <div>- {sender_details?.name}</div>
            </div>
            <section className="divider"></section>
            <table>
                <tbody>
                    <tr>
                        <td>{sender} Name:</td>
                        <td>{sender_details?.name}</td>
                    </tr>
                    <tr>
                        <td>{sender} Email:</td>
                        <td>{sender_details?.email}</td>
                    </tr>
                    <tr>
                        <td>{sender} Phone:</td>
                        <td>{
                            transaction?.sender_mobile_number
                        }</td>
                    </tr>
                    {is_business && <tr>
                        <td>Fulfillment Method:</td>
                        <td>{is_petron ? "Regular Payment": fulfillment_methods[order_details?.method || '']}</td>
                    </tr>}
                    {order_details?.method == "pick_up" && <tr>
                        <td>Pickup Time:</td>
                        <td>{order_details?.pickup_time}</td>
                    </tr>}
                    {is_business && order_details?.method == "delivery" && <tr>
                        <td>Delivery Address:</td>
                        <td style={{whiteSpace: 'normal'}}>{order_details?.shipping_address}</td>
                    </tr>}
                    <tr className="spacer"></tr>
                    <tr>
                        <td>Message/Instructions:</td>
                        <td style={{whiteSpace: 'normal'}}>{sender_details?.message}</td>
                    </tr>
                    <tr className="spacer"></tr>
                    <tr>
                        <td>Transaction Date:</td>
                        <td>{formatDatetime(transaction?.updated_at, 'MMMM DD, YYYY (hh:mm A)')}</td>
                    </tr>
                    <tr>
                        <td>Transction Number:</td>
                        <td>{transaction?.payment_id}</td>
                    </tr>
                    <tr className="spacer"></tr>
                    <tr>
                        <td>Amount Paid:</td>
                        <td>{amount_sent} (via {source_of_funds?.service_name})</td>
                    </tr>
                    <tr>
                        <td>Fees:</td>
                        <td>
                            <span>{system_fee}</span> (system fee) {charge_fees_to}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <span>{sending_fee}</span> (sending processing fee) {charge_fees_to}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <span>{receiving_fee}</span> (receiving processing fee) {charge_fees_to}
                        </td>
                    </tr>
                    {
                        is_business && <tr>
                            <td></td>
                            <td>
                                <span>{fullfillment_fee}</span> (Fulfillment fee) - charge to {sender_name}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td></td>
                        <td>
                            <span>{other_charges}</span> (Other Fee) - charge to {sender_name}
                        </td>
                    </tr>
                    <tr className="spacer"></tr>
                    <tr>
                        <td>Total Amount:</td>
                        <td>{formatCurrency(+(transaction?.payout_amount || 0))}</td>
                    </tr>
                </tbody>
            </table>
            {
                order_details?.delivery_status == 'not_ready'? <>
                    <ButtonLoader hasLoader
                        name   = "close_transaction_modal"
                        style  = "filled green"
                        text   = "PICK UP NOW"
                        onClick = {() => {
                            setCancel(false)
                            setConfirmModal(true)
                            closeModal()
                        }}
                    />
                    <ButtonLoader hasLoader
                        name   = "close_transaction_modal"
                        style  = "filled red"
                        text   = "NOT YET"
                        onClick = {closeModal}
                    />
                    <ButtonLoader hasLoader
                        name   = "close_transaction_modal"
                        style  = "text red"
                        text   = "Cancel Delivery"
                        onClick = {() => {
                            setCancel(true)
                            setConfirmModal(true)
                            closeModal()
                        }}
                    />
                </>:
                <ButtonLoader hasLoader
                    loading = {loading}
                    name    = "close_transaction_modal"
                    style   = "filled blue"
                    text    = "OK"
                    onClick = {() => closeModal()}
                />
            }
        </Modal>
        <DeliveryConfirmModal
            cancel      = {cancel}
            state       = {[confirmModal, setConfirmModal]}
            transaction = {transaction}
            onClose     = {() => userDashboard.transactions && state[1](true)}
            onConfirm   = {updateDelivery}
            onCancel    = {() => {
                setConfirmModal(false)
                setCancel(false)
                state[1](true)
        }}/>
    </>
})

export default DeliveryModal;