import { createUserManager } from 'redux-oidc';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';

const userManagerConfig: UserManagerSettings = {
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    client_secret: process.env.REACT_APP_OIDC_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_OIDC_CALLBACK_URL,
    post_logout_redirect_uri: process.env.REACT_APP_OIDC_SIGNOUT_CALLBACK_URL,
    response_type: process.env.REACT_APP_OIDC_RESOURCE_TYPE,
    scope: process.env.REACT_APP_OIDC_SCOPE,
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
