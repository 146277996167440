import Modal, { DefaultModalProps } from "app/components/Modal";
import ErrorModal from "app/components/Modal/ErrorModal";
import { selectResetPasswordPage } from "app/containers/UserAuthentication/ResetPasswordPage/selectors";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Assets
import success_icon from 'assets/img/success.svg';
import bg   from 'assets/img/background/justpayto-philippines-payments-3.jpg';
import logo from 'assets/img/landing-page/jpt-logo.svg';
import InputField from "app/components/InputField";
import ButtonLoader from "app/components/ButtonLoader";
import { reducer, ResetPasswordPageActions, sliceKey } from "app/containers/UserAuthentication/ResetPasswordPage/slice";
import { selectUserInfo } from "app/common/auth/selectors";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import saga from "app/containers/UserAuthentication/ResetPasswordPage/saga";

const ResetPasswordModal = memo((props: DefaultModalProps) => {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga });
    const dispatch = useDispatch();
    const user = useSelector(selectUserInfo);
    const resetPasswordState = useSelector(selectResetPasswordPage);

    const errorModalState                 = useState(false);
    const [success, setSuccess]           = useState(false);
    const [newPassword, setNewPassword]   = useState('');
    const [repeatPass, setRepeatPass]     = useState('');
    const [newPassEmpty, setNewPassEmpty] = useState(false);
    const [matchedPass, setMatchedPass]   = useState(true);
    const [loading, setLoading]           = useState(false);
    
    const resetPass = () => {
        setLoading(true);
        setNewPassEmpty(!newPassword);
        setMatchedPass(newPassword === repeatPass);
        if (newPassword && newPassword === repeatPass) {
            dispatch(ResetPasswordPageActions.resetPassword({
                username: user?.username, newPassword
            }))
        } else {
            setLoading(false)
        }
    }

    // Reset Password Results
    useEffect(() => {
        if (
            resetPasswordState.result !== undefined &&
            resetPasswordState.result !== null &&
            !resetPasswordState.result
        ) {
            errorModalState[1](true);
        } else if (resetPasswordState.result) {
            setSuccess(true);
        }
    }, [resetPasswordState.result, resetPasswordState.error])

    const reset = () => {
        setSuccess(false)
        setNewPassword('')
        setRepeatPass('')
        setNewPassEmpty(false)
        setMatchedPass(true)
        setLoading(false)
        dispatch(ResetPasswordPageActions.clearError())
    }

    return (
        <Modal {...props} disableEscapeKeyDown disableBackdropClick style="larger_modal reset-password" onClose={reset}>
            <ErrorModal state = {errorModalState} message = {resetPasswordState.error || ''}/>
            {
                !success ?
                <section className="action-box form">
                    <img className="logo" src={logo} alt={process.env.REACT_APP_NAME}/>
                    <div className="pseudo-form">
                        <h1>Reset Password</h1>
                        <InputField loader
                            type        = 'password'
                            name        = 'password'
                            placeholder = 'New Password'
                            style       = {{inputContainer: 'icon icon-password'}}
                            error = {newPassEmpty}
                            value = {newPassword}
                            onChange = {e => {
                                setNewPassword(e.target.value)
                                setNewPassEmpty(!e.target.value)
                            }}
                        />
                        <InputField loader
                            type        = 'password'
                            name        = 'password_confirmation'
                            placeholder = 'Repeat New Password'
                            style       = {{inputContainer: 'icon icon-password'}}
                            error = {!matchedPass}
                            value = {repeatPass}
                            onChange = {e => {
                                setRepeatPass(e.target.value)
                                setMatchedPass(e.target.value === newPassword)
                            }}
                        />

                        <div className="error-container" data-error-container>
                        {
                            newPassEmpty ? "New password is required":
                            !matchedPass ? "Password does not match": ''
                        }
                        </div>
                        <section className="footer">
                            <ButtonLoader hasLoader
                                loading = {loading}
                                name  = 'reset'
                                style = 'filled blue'
                                text  = 'Reset'
                                onClick = {resetPass}
                            />
                        </section>
                    </div>
                </section> :
                <section className="action-box success">
                    <h1 className="center">Great</h1>
                    <img className="logo" src={success_icon} alt="Success Icon"/>
                    <h3 className="center">You have successfully updated<br/>your password.</h3>
                </section>
            }
        </Modal>
    )
})

export default ResetPasswordModal