import { Grid, LinearProgress, Typography } from "@material-ui/core";
import FilterButton from 'app/components/FilterButton';
import FilterDate from "app/components/FilterDate";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { numberFormat, roundOff } from "utils";
import { depository_accounts } from "utils/options";

const SalesCharts = memo(({component}: any) => {
    const userDashboard = useSelector(selectUserDashboard);
    const dispatch      = useDispatch();

    const [data, setData]             = useState<any[]>([]);
    const [salesData, setSalesData]   = useState<any[]>([]);
    const [all, setAll]               = useState(false);
    const [last7days, setLast7days]   = useState(true);
    const [last20days, setLast20days] = useState(false);
    const [fromDate, setFromDate]     = useState('');
    const [toDate, setToDate]         = useState('');

    const [cashIn, setCashIn]   = useState(0);
    const [cashOut, setCashOut] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [fees, setFees]       = useState(0);

    const [totalCashIn, setTotalCashIn] = useState(0);
    const [totalCashOut, setTotalCashOut] = useState(0);

    const [salesByPayment, setSalesByPayment] = useState<any>();
    const [salesByAccount, setSalesByAccount] = useState<any>();

    const computeCashinRevenue  = amount => amount? 3/100*amount+10: 0
    const computeCashinFees     = amount => amount? 1/100*amount+10: 0
    const computeCashoutRevenue = amount => amount? 2/100*amount+15: 0
    const computeCashoutFees    = amount => amount? 1/100*amount+10: 0

    const resetFilter = () => {
        setAll(false);
        setLast7days(false);
        setLast20days(false);
        setFromDate('');
        setToDate('');
    }

    const setFilter = (set, value) => {
        resetFilter();
        set(value);
    }

    const setRangeFilter = (set, value) => {
        const from = fromDate;
        const to   = toDate;
        setFromDate(from);
        setToDate(to);
        set(value);
    }

    const filterData = () => {
        if (fromDate && toDate) {
            setAll(false);
            setLast7days(false);
            setLast20days(false);
        }
        let data = userDashboard.salesData || []
        if (last7days) {
            let start = moment().subtract(7, 'days').startOf('day'),
                end   = moment().endOf('day');
            data = data.filter(d => moment(d['date']).isBetween(start, end))
        } else if (last20days) {
            let start = moment().subtract(20, 'days').startOf('day'),
                end   = moment().endOf('day');
            data = data.filter(d => moment(d['date']).isBetween(start, end))
        } else if (fromDate && toDate) {
            let start = moment(fromDate).startOf('day'),
                end   = moment(toDate).endOf('day');
            data = data.filter(d => moment(d['date']).isBetween(start, end, 'days', '[]'))
        }
        setData(data)
    }

    const getSalesData = () => {
        if (!userDashboard.salesData) {
            dispatch(userDashboardPageActions.getSales());
        }
    }

    useEffect(getSalesData, [])

    useEffect(filterData, [all, last7days, last20days, fromDate, toDate, userDashboard.salesData])

    useEffect(() => {
        setSalesData(data?.map(d => ({
            date    : moment(d['date']).format('MM.DD.YY'),
            cash_in : d['cash_in'].amount,
            cash_out: d['cash_out'].amount,
        })))
        let cash_in_amount  = 0,
            cash_in_count   = 0,
            cash_out_amount = 0,
            cash_out_count  = 0,
            revenue  = 0,
            fees     = 0,
            cashInCategorySales: any = {},
            bank_accounts = {
                ...depository_accounts.bank,
                ...depository_accounts.e_wallet,
                ...depository_accounts.otc
            },
            settlement_accounts = Object.keys(bank_accounts).reduce((obj, acc) => ({...obj, [acc]: {count: 0, amount: 0}}), {})
        for (const d of data) {
            cash_in_amount  += d['cash_in'].amount
            cash_out_amount += d['cash_out'].amount
            cash_in_count   += d['cash_in'].count
            cash_out_count  += d['cash_out'].count
            revenue  += (
                computeCashinRevenue(d['cash_in'].amount) +
                computeCashoutRevenue(d['cash_out'].amount)
            )
            fees += (
                computeCashinFees(d['cash_in'].amount) +
                computeCashoutFees(d['cash_out'].amount)
            )
            if (!component) {
                for (const category in d['payment_categories']) {
                    if (!cashInCategorySales[category]) {
                        cashInCategorySales[category] = {...d['payment_categories'][category]}
                    } else {
                        cashInCategorySales[category].count += d['payment_categories'][category].count
                        cashInCategorySales[category].amount += d['payment_categories'][category].amount
                    }
                }
                for (const account in d['settlement_accounts']) {
                    settlement_accounts[account].count  += d['settlement_accounts'][account].count
                    settlement_accounts[account].amount += d['settlement_accounts'][account].amount
                }
            }
        }
        settlement_accounts = Object.keys(settlement_accounts).map(k => ({
            bank  : k,
            name  : bank_accounts[k],
            count : settlement_accounts[k].count,
            amount: settlement_accounts[k].amount,
        })).sort((a, b) => (
            a.count > b.count? -1:
            a.count < b.count?  1: 0
        ))
        const others = (settlement_accounts as any).slice(5).reduce((others, acc) => ({
            ...others,
            count: others.count + acc.count,
            amount: others.amount + acc.amount,
        }), {
            name: 'Others',
            count: 0,
            amount: 0
        })
        settlement_accounts = [...(settlement_accounts as any).slice(0, 5), others]
        setCashIn(cash_in_amount)
        setCashOut(cash_out_amount)
        setRevenue(revenue)
        setFees(fees)
        setTotalCashIn(cash_in_count || 1)
        setTotalCashOut(cash_out_count || 1)
        setSalesByPayment(cashInCategorySales)
        setSalesByAccount(settlement_accounts)
    }, [data])

    return (
        <Grid container spacing={3} className="container-grid">
            <Grid container className="standard-grid" style={{padding: '1rem'}}>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h5">Cash-in ({totalCashIn || 0})</Typography><br />
                            <Typography variant="h4">₱{ numberFormat(cashIn) }</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h5">Cash-out ({totalCashOut || 0})</Typography><br />
                            <Typography variant="h4">₱{ numberFormat(cashOut) }</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h5">Revenue ({(totalCashIn || 0) + (totalCashOut || 0)})</Typography><br />
                            <Typography variant="h4">₱{ numberFormat(revenue) }</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h5">Fees ({(totalCashIn || 0) + (totalCashOut || 0)})</Typography><br />
                            <Typography variant="h4">₱{ numberFormat(fees) }</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <div className="dataTables_filter bar-chart" style={{flexWrap: 'nowrap', whiteSpace: 'nowrap'}}>
                        <section className="date-filter-1">
                            <FilterButton status={all}         set={setAll}        setFilter={setFilter} text="All"/>
                            <FilterButton status={last7days}   set={setLast7days}  setFilter={setFilter} text="Last 7 days"/>
                            <FilterButton status={last20days}  set={setLast20days} setFilter={setFilter} text="Last 20 days"/>
                        </section>
                        <span>OR</span>
                        <section className="date-filter-2">
                            <FilterDate direction="from" placeholder="From" value={fromDate} setRangeFilter={setRangeFilter} set={setFromDate}/>
                            <FilterDate direction="to"   placeholder="To"   value={toDate}   setRangeFilter={setRangeFilter} set={setToDate}/>
                        </section>
                    </div>
                </Grid>
            </Grid>
            <div style={{width: '100%', padding: '1rem'}}>
                <ResponsiveContainer height={300}>
                    <BarChart data={salesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip formatter={(value, name) => [numberFormat(value), name]} />
                        <Legend align="left" iconType="circle" />
                        <Bar dataKey="cash_in" fill="#2174C2" name="Cash In" barSize={8} />
                        <Bar dataKey="cash_out" fill="#66C0E9" name="Cash Out" barSize={8} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            {!component && <>
                <Grid item xs={12} className="bar-chart">
                    <Grid container className="standard-grid" style={{width: "100%"}}>
                        <Typography variant="h5">Cash-In by Payment Type</Typography>
                        <Grid item xs={12} container>
                            <Grid item xs={12} className="bar-graph credit-debit-card">
                                <Grid container style={{height: 'fit-content'}}>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">Credit/Debit Card</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByPayment?.mastercard_visa?.count || 0)/totalCashIn*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByPayment?.mastercard_visa?.amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByPayment?.mastercard_visa?.count || 0)/totalCashIn*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph bank-transfer">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">Bank Transfer</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByPayment?.bank_fund_transfer?.count || 0)/totalCashIn*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByPayment?.bank_fund_transfer?.amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByPayment?.bank_fund_transfer?.count || 0)/totalCashIn*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph online-banking">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">Online Banking</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByPayment?.online_banking?.count || 0)/totalCashIn*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByPayment?.online_banking?.amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByPayment?.online_banking?.count || 0)/totalCashIn*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph e-wallet-crypto">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">E-Wallet/Crypto</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByPayment?.e_wallet?.count || 0)/totalCashIn*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByPayment?.e_wallet?.amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByPayment?.e_wallet?.count || 0)/totalCashIn*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph over-the-counter">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">Over-the-Counter</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByPayment?.over_the_counter?.count || 0)/totalCashIn*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByPayment?.over_the_counter?.amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByPayment?.over_the_counter?.count || 0)/totalCashIn*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph others">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">Others</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByPayment?.others?.count || 0)/totalCashIn*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByPayment?.others?.amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByPayment?.others?.count || 0)/totalCashIn*100)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="bar-chart">
                    <Grid container className="standard-grid" style={{width: "100%"}}>
                        <Typography variant="h5">Cash-Out by Payment Type</Typography>
                        <Grid item xs={12} container>
                            <Grid item xs={12} className="bar-graph credit-debit-card">
                                <Grid container style={{height: 'fit-content'}}>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">{salesByAccount?.[0].name}</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByAccount?.[0].count || 0)/totalCashOut*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByAccount?.[0].amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByAccount?.[0].count || 0)/totalCashOut*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph bank-transfer">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">{salesByAccount?.[1].name}</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByAccount?.[1].count || 0)/totalCashOut*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByAccount?.[1].amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByAccount?.[1].count || 0)/totalCashOut*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph online-banking">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">{salesByAccount?.[2].name}</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByAccount?.[2].count || 0)/totalCashOut*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByAccount?.[2].amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByAccount?.[2].count || 0)/totalCashOut*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph e-wallet-crypto">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">{salesByAccount?.[3].name}</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByAccount?.[3].count || 0)/totalCashOut*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByAccount?.[3].amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByAccount?.[3].count || 0)/totalCashOut*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph over-the-counter">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">{salesByAccount?.[4].name}</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByAccount?.[3].count || 0)/totalCashOut*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByAccount?.[3].amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByAccount?.[3].count || 0)/totalCashOut*100)}/>
                            </Grid>
                            <Grid item xs={12} className="bar-graph others">
                                <Grid container>
                                    <Grid item xs={9}><Typography variant="h6" color="textSecondary">{salesByAccount?.[5].name}</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">{roundOff((salesByAccount?.[5].count || 0)/totalCashOut*100)}%</Typography></Grid>
                                    <Grid item xs><Typography variant="h6" color="textSecondary" align="right">₱ {numberFormat(salesByAccount?.[5].amount || 0)}</Typography></Grid>
                                </Grid>
                                <LinearProgress variant="determinate" value={roundOff((salesByAccount?.[5].count || 0)/totalCashOut*100)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </Grid>
    )
})

export default SalesCharts;