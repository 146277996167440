import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.paymentPage || initialState;

export const selectDisburse = createSelector(
    [selectDomain],
    paymentPageState => paymentPageState.disburse
)

export const selectLoadingUser = createSelector(
    [selectDomain],
    paymentPageState => paymentPageState.loading,
);

export const selectUser = createSelector(
    [selectDomain],
    paymentPageState => {
        // Stores current state to a new Object without the properties
        let newState = Object.create(paymentPageState);

        // Get current values to new state
        newState = paymentPageState;

        // Returns state without the properties
        return newState;
    },
);
