import React, { memo } from 'react';

interface Props {
    route?    : string
    target?   : string
    rel?      : string
    name?     : string
    style?    : string
    cssStyle? : object
    submit?   : boolean
    hasLoader?: boolean
    loading?  : boolean
    disabled? : boolean
    text?     : string | JSX.Element
    onClick?  : (e: any) => void
}

const Button = memo((props: Props) => {
    const {name, cssStyle, style, submit, hasLoader, loading, disabled, text} = props;
    return (
        <button
            jpt-button = {name}
            disabled   = {disabled || loading}
            className  = {`button ${style} ${loading && 'loading'}`}
            type       = {submit? 'submit' : 'button'}
            style      = {cssStyle}
            onClick    = {props.onClick}
        >
            <section>
                {
                    hasLoader && (
                        <span className="spinner-container">
                            <div className="spinner"></div>
                        </span>
                    )
                }
                <span className="label">{text}</span>
            </section>
        </button>
    )
});

const ButtonLoader = memo((props: Props) => {
    const {route, target, rel} = props;
    return (
        route?
        <a href={route} target={target} rel={rel}>
            <Button {...props}/>
        </a>:
        <Button {...props}/>
    )
});

export default ButtonLoader;