import { CircularProgress } from "@material-ui/core";
import React, { memo } from "react";
import Modal, { DefaultModalProps } from ".";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const LoadingModal = memo(({state}: DefaultModalProps) => {
    const classes = useStyles();
    return <Modal
        disableBackdropClick
        disableEscapeKeyDown
        disableCloseButton
        state={state}
        dialogClassname={classes.hide}
        modalClassname={classes.hide}
    >
        <CircularProgress className={classes.loader}/>
    </Modal>
})


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hide: {
            width: 0,
            overflow: "visible",
            padding: 0
        },
        loader: {
            position: "absolute",
            left: "-20px"
        }
    })
)

export default LoadingModal