import { selectAuth } from "app/common/auth/selectors";
import { GeneralComponent } from "app/common/slice";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDashboard } from "./selectors";
import { userDashboardPageActions } from "./slice";

const useAuth = () => {
    const dispatch      = useDispatch();
    const auth          = useSelector(selectAuth);
    const userDashboard = useSelector(selectUserDashboard);

    const [initialized, initialize] = React.useState(false);

    /**
     * Get Stored Auth
     */
    React.useEffect(() => {
        let auth = (localStorage.getItem('auth') || '{}') as any;
            auth = JSON.parse(auth);

        dispatch(GeneralComponent.setAuth(auth));
    }, [])
    // END

    /**
     * On Auth Update, if first load, refresh token else get user info
     */
    React.useEffect(() => {
        if (auth?.access_token)  {
            dispatch(userDashboardPageActions.updateActionTime());
            if (initialized) {
                dispatch(GeneralComponent.getUserInfo());
            } else {
                dispatch(GeneralComponent.refreshToken(undefined));
                initialize(true);
            }
        }
    }, [auth])
    // END

    /**
     * Set timer for idle time
     */
    const [timer, setTimer] = React.useState<number>();
    React.useEffect(() => {
        // On idle time changed, reset time if already exists
        timer && clearInterval(timer);

        if (userDashboard.lastActionTime) {
            const interval = setInterval(() => {
                if (moment().startOf('second').isSameOrAfter(userDashboard.lastActionTime)) {
                    // On idle, clear all
                    dispatch(userDashboardPageActions.error('idle'));
                    dispatch(GeneralComponent.removeAuth());
                    dispatch(GeneralComponent.setUserInfo());
                    localStorage.removeItem('auth');
                    clearInterval(interval);
                    setTimer(undefined);
                }
            }, 1000)
            setTimer(interval)
        }
    }, [userDashboard.lastActionTime])
    // END

    return auth;
}

export default useAuth;