export const FRONTEND_URL       = `${process.env.REACT_APP_CORE_API_URL}/frontend/api/v3`
export const ENDUSER_API        = `${FRONTEND_URL}/enduser`;
export const METROBANK_API      = `${ENDUSER_API}/metrobank`;
export const XENDIT_API         = `${ENDUSER_API}/xendit`;
export const BRANKAS_API        = `${ENDUSER_API}/brankas`;
export const ALLBANK_API        = `${ENDUSER_API}/allbank`;
export const BEEP_API           = `${FRONTEND_URL}/beep`;
export const BAYADCENTER_API    = `${FRONTEND_URL}/bayadcenter`;
export const AUTOSWEEP_API      = `${FRONTEND_URL}/autosweeprfid`;
export const EASYTRIP_API       = `${FRONTEND_URL}/easytriprfid`;
export const TWITTER_API        = `${FRONTEND_URL}/twitter`;
export const LINKEDIN_API       = `${FRONTEND_URL}/linkedin`;

export const KEYCLOAK_MASTER_TOKEN          = `${process.env.REACT_APP_KEYCLOAK_MASTER_URL}/protocol/openid-connect/token`;
export const KEYCLOAK_PEER_TO_PEER_TOKEN    = `${process.env.REACT_APP_OIDC_AUTHORITY}protocol/openid-connect/token`;
export const KEYCLOAK_PEER_TO_PEER_USERS    = `${process.env.REACT_APP_KEYCLOAK_PEER_TO_PEER_URL}/users`;
export const KEYCLOAK_PEER_TO_PEER_GROUPS   = `${process.env.REACT_APP_KEYCLOAK_PEER_TO_PEER_URL}/groups`;

export const GET_REALM                  = `${ENDUSER_API}/get/realm`;
export const GET_MERCHANT_ID            = `${ENDUSER_API}/get/merchant-id`;
export const GET_ANNOUNCEMENT           = `${ENDUSER_API}/get/announcement`;
export const GET_CARD_PROCESSOR         = `${ENDUSER_API}/get/card-processor`;
export const GET_DEPOSITORY_ACCOUNT     = `${ENDUSER_API}/get/depository-account`;
export const GET_SOURCE_OF_FUNDS        = `${ENDUSER_API}/get/source-of-funds`;
export const GET_DISBURSEMENT_STATUS    = `${ENDUSER_API}/get/disbursement-status`;
export const STORE_INTENT_REPORT        = `${ENDUSER_API}/post/store-intent`;
export const FETCH_COUNTRY_DETAILS      = `${ENDUSER_API}/get/country`;
export const STORE_SENDER_KYC           = `${ENDUSER_API}/post/sender/store-kyc`;
export const STORE_FILE_UPLOAD          = `${ENDUSER_API}/post/store-file/:email/:file_type`;
export const SEND_KYC_OTP               = `${ENDUSER_API}/send/otp/:type`;
export const VALIDATE_KYC_OTP           = `${ENDUSER_API}/validate/otp/:type`;
export const STORE_PAYMENT_INFORMATION  = `${ENDUSER_API}/post/store-payment`;
export const PROCESS_CALLBACK           = `${ENDUSER_API}/callbackhandler`;
export const PROCESS_PAYMENT            = `${ENDUSER_API}/process-payment`;
export const SEND_NOTIFICATION          = `${ENDUSER_API}/payment-notification`;
export const PAYMENT_TOKEN              = `${ENDUSER_API}/payment-token/:token`;
export const CALCULATE_DELIVERY         = `${ENDUSER_API}/delivery/calculate-fee`;
export const BOOK_DELIVERY              = `${ENDUSER_API}/delivery/book/:payment_id`;

export const MCC_CREDIT_INITIATE_SESSION    = `${METROBANK_API}/credit/v2/initiate-session`;
export const MCC_CREDIT_PROCESS_CHECKOUT    = `${METROBANK_API}/credit/v2/process-checkout`;
export const MCC_CALLBACK                   = `${process.env.REACT_APP_CORE_BASE_URL}/v3/enduser/metrobank/callback`;

export const XENDIT_CREDIT_CREATE_AUTHORIZATION = `${XENDIT_API}/credit/create-authorization`;
export const XENDIT_DEBIT_TOKENIZE              = `${XENDIT_API}/tokenize`;

export const BRANKAS_DEBIT_TOKENIZE = `${BRANKAS_API}/tokenize`;

export const ALLBANK_QRPH_STATUS = `${ALLBANK_API}/qr/status`;

export const AUTOSWEEP_VALIDATE       = `${AUTOSWEEP_API}/inquire-balance`;
export const AUTOSWEEP_CREATE_PAYMENT = `${AUTOSWEEP_API}/create-payment`;

export const EASYTRIP_CUSTOMER_INFO = `${EASYTRIP_API}/customer-information`;
export const EASYTRIP_RELOAD        = `${EASYTRIP_API}/reload`;

export const BEEP_CARD_STATUS = `${BEEP_API}/card-status`;
export const BEEP_LOAD        = `${BEEP_API}/load`;

export const BAYADCENTER_ACCOUNT = `${BAYADCENTER_API}/:biller/accounts/:account_number`;
export const BAYADCENTER_PAYMENT = `${BAYADCENTER_API}/payments/:transaction_id`;

export const AUTH                     = `${FRONTEND_URL}/auth`;
export const GET_IP                   = `${AUTH}/get-ip`
export const GET_USER                 = `${AUTH}/user/:id`
export const GET_USER_INFO            = `${AUTH}/user-info`
export const TOKEN_LOGIN              = `${AUTH}/token`;
export const REFRESH_TOKEN            = `${AUTH}/refresh`;
export const LOGIN                    = `${AUTH}/login`;
export const SEND_AUTH_OTP            = `${AUTH}/send-otp`;
export const VALIDATE_OTP             = `${AUTH}/validate-otp`;
export const FORGOT_PASSWORD          = `${AUTH}/forgot-password`;
export const CHECK_RESET_PASSWORD_KEY = `${AUTH}/check-reset-password-key`;
export const RESET_PASSWORD           = `${AUTH}/reset-password`;
export const UPDATE_USER              = `${AUTH}/user/:username`

export const ONBOARDING_API        = `${FRONTEND_URL}/onboarding`;
export const COUNTRIES             = `${ONBOARDING_API}/countries`;
export const ONBOARDING_USER       = `${ONBOARDING_API}/:username`;
export const PARTNERS              = `${ONBOARDING_API}/partners`;
export const ANNOUNCEMENT          = `${ONBOARDING_API}/announcement`;
export const CHECK_EMAIL           = `${ONBOARDING_API}/check-email`;
export const SUGGESTED_USERNAMES   = `${ONBOARDING_API}/suggested-usernames`;
export const USERNAME_EXISTS       = `${ONBOARDING_API}/username-exists`;
export const DEPOSITORY_SERVICE    = `${ONBOARDING_API}/depository-service/:code`;
export const GET_EARLY_ACCESS      = `${ONBOARDING_API}/registration/early-access`;
export const SEND_REGISTRATION_OTP = `${ONBOARDING_API}/registration/otp`;
export const VERIFY_CODES          = `${ONBOARDING_API}/registration/verify`;
export const CREATE_ACCOUNT        = `${ONBOARDING_API}/registration/create-account`;
export const CONFIRM_DEPOSIT       = `${ONBOARDING_API}/registration/confirm-deposit`;
export const DOCUMENT_UPLOAD       = `${ONBOARDING_API}/registration/:username/upload-document/:type`;

export const USER_DASHBOARD         = `${FRONTEND_URL}/user-dashboard`;
export const UPDATE_STATUS          = `${USER_DASHBOARD}/:status`
export const TRANSACTIONS           = `${USER_DASHBOARD}/transactions?`
export const OVERVIEW               = `${USER_DASHBOARD}/overview`
export const OVERVIEW_SALES         = `${USER_DASHBOARD}/overview/sales`
export const ANALYTICS              = `${USER_DASHBOARD}/analytics`
export const BARCHART_TRANSACTIONS  = `${USER_DASHBOARD}/analytics/bar-chart`
export const LINE_GRAPHS            = `${USER_DASHBOARD}/analytics/line-graphs/:graph`
export const UPDATE_ACCOUNT         = `${USER_DASHBOARD}/account`
export const DOWNLOAD_DOCUMENT      = `${USER_DASHBOARD}/document/:type`
export const GROSS_SALES            = `${USER_DASHBOARD}/gross-sales?`
export const NEW_CUSTOMERS          = `${USER_DASHBOARD}/new-end-users?`
export const NO_OF_TRANSACTIONS     = `${USER_DASHBOARD}/no-of-transactions/:status?`
export const AVERAGE_REVENUES       = `${USER_DASHBOARD}/average-revenues?`
export const SETTINGS               = `${USER_DASHBOARD}/settings`
export const LAST_PAYOUT_DATE       = `${USER_DASHBOARD}/last-payout-date`
export const UPDATE_DELIVERY        = `${USER_DASHBOARD}/:id/delivery/:action`
export const SEND_ACCOUNT_OTP       = `${USER_DASHBOARD}/otp/send`
export const VALIDATE_ACCOUNT_OTP   = `${USER_DASHBOARD}/otp/validate`
export const REACTIVATE             = `${USER_DASHBOARD}/reactivate`
export const CHANGE_PLAN            = `${USER_DASHBOARD}/change-plan`
export const TOP_UP                 = `${USER_DASHBOARD}/top-up`
export const CUSTOMIZATIONS         = `${USER_DASHBOARD}/clients/:username/fields`
export const CUSTOMIZATION          = `${USER_DASHBOARD}/clients/:username/fields/:field_key`
export const UPLOAD_CLIENT_ASSET    = `${USER_DASHBOARD}/clients/:username/fields/:field_key/upload`
export const CATEGORIES             = `${USER_DASHBOARD}/clients/:username/categories`
export const CATEGORY               = `${USER_DASHBOARD}/clients/:username/categories/:category`
export const CATEGORY_SUBCATEGORIES = `${USER_DASHBOARD}/clients/:username/categories/:category/subcategories`
export const SUBCATEGORIES          = `${USER_DASHBOARD}/clients/:username/subcategories`
export const SUBCATEGORY            = `${USER_DASHBOARD}/clients/:username/subcategories/:id`
export const API_VERSION            = `${USER_DASHBOARD}/clients/:username/options/api_version`
export const PAGE_STATUS            = `${USER_DASHBOARD}/clients/:username/options/page_status`
export const PRMOTIONAL_CONTENT     = `${USER_DASHBOARD}/clients/:username/options/promotional_content`