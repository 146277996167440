import Pageable from "pageable";

export const tryInitPageable = () => {
    const pages = new Pageable("#main", {
        pips: false,
        animation: 800,
        swipeThreshold: 256
    });
    if (document.body.clientWidth > 1024 && !pages.initialised) {
        pages.init();
    } else if (document.body.clientWidth < 1024 && pages.initialised) {
        pages.destroy();
    }
    return pages;
}