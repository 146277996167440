import { Box, Checkbox, CircularProgress, createStyles, FormControlLabel, Grid, makeStyles, Typography } from "@material-ui/core";
import { green, grey, orange, red } from "@material-ui/core/colors";
import { CheckCircle, Error, WatchLater } from "@material-ui/icons";
import { selectAccessToken, selectUser } from "app/common/auth/selectors";
import { TOP_UP } from "app/common/core_api/resources";
import Button from "app/components/Button";
import ErrorModal from "app/components/Modal/ErrorModal";
import SuccessModal from "app/components/Modal/SuccessModal";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import { userDashboardPageActions } from "app/containers/UserDashboardPage/slice";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { numberFormat } from "utils";
import { configHeaders } from "utils/request-config";
import SalesCharts from "../Analytics/api_clients/SalesCharts";

const ApiClientOverview = React.memo(() => {
    const dispatch    = useDispatch();
    const classes     = useStyles();
    const accessToken = useSelector(selectAccessToken);
    const userInfo    = useSelector(selectUser)?.user;
    const dashboard   = useSelector(selectUserDashboard);

    const prefunded      = userInfo?.['prefunded']
    const suggestedTopUp = (
        (prefunded?.['threshold'] || 0) >
        (prefunded?.['balance'] || 0)?
        prefunded?.['threshold'] -
        prefunded?.['balance']: 0
    )

    const {
        cashin: {lastDeposit, nextDeposit, depository},
        cashout: {lastTopUp}
    } = dashboard?.overview;

    const [netSales, setNetSales]                   = React.useState(true)
    const [amount, setAmount]                       = React.useState<number>()
    const [approvingTransfer, setApprovingTransfer] = React.useState(false)
    const [success, setSuccess]                     = React.useState(false);
    const [error, setError]                         = React.useState(false);

    const topUp = () => {
        if (amount) {
            // Insufficient Funds
            if (amount > nextDeposit?.net) {
                setError(true)
                return
            }

            setApprovingTransfer(true)
            let options    = {
                    method : 'POST',
                    headers: configHeaders(accessToken),
                    body   : JSON.stringify({amount})
                };
            fetch(TOP_UP, options).then(
                () => dispatch(userDashboardPageActions.getTransactions())
            ).finally(() => {
                setApprovingTransfer(false)
                setSuccess(true)
            })
        }
    }

    return <>
        <SuccessModal
            state   = {[success, setSuccess]}
            title   = "Top-Up Approved"
            message = {`Transferring of ${amount} from depository account to pre-funded wallet`}
        />
        <ErrorModal
            state   = {[error, setError]}
            title   = "Insufficient Funds"
            message = "Insufficient funds to top-up"
        />
        <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={12} md={6} className="item-grid partial">
                <Grid container className={classes.cashIn}>
                    <Grid item xs={12} md={7} >
                        <Typography variant="h3">Cash-In</Typography><br/>
                        <Typography variant="body1" color="textSecondary">Successful Deposit {lastDeposit?.date && <small>({moment(lastDeposit?.date).format('MMMM DD, YYYY')})</small>}</Typography>
                        <Typography variant="h3"><CheckCircle htmlColor={green[500]}/> ₱{ numberFormat((netSales? lastDeposit?.net : lastDeposit?.gross) || 0) }</Typography><br />
                        <Typography variant="body1" color="textSecondary">Next Deposit <small>({moment(nextDeposit?.date).format('MMMM DD, YYYY')})</small></Typography>
                        <Typography variant="h3"><WatchLater htmlColor={grey[500]}/> ₱{ numberFormat(netSales? nextDeposit?.net: nextDeposit?.gross) }</Typography><br />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box marginLeft="auto" width="fit-content">
                            <FormControlLabel
                                className = "checkbox"
                                label     = "Net Sales"
                                control   = {
                                    <Checkbox
                                        checked  = {netSales}
                                        size     = "small"
                                        onChange = {_ => setNetSales(!netSales)}
                                    />
                            }/>
                        </Box>
                        <br/>
                        {depository?.main_logo_url && <img src={depository?.main_logo_url} width="100%"/>}
                        <p>
                            <small>
                                Settlement Bank: {depository?.name}<br/>
                                Account Name: {depository?.account_name}<br/>
                                Account Number: {depository?.account_number}<br/>
                            </small>
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} className="item-grid partial">
                <Grid container className={classes.cashOut}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">Cash-Out</Typography><br/>
                        <Typography variant="body1" color="textSecondary">Pre-funded Wallet <small>({moment().format("MMMM DD, YYYY")})</small></Typography>
                        <Typography variant="h3">
                            {!suggestedTopUp?
                                <CheckCircle htmlColor={green[500]}/>:
                                <Error htmlColor={orange[500]}/>
                            }
                            {' '}₱{ numberFormat(prefunded?.['balance'] || 0) }
                        </Typography>
                        {!!suggestedTopUp && <Typography className={`subtitle error`}>Wallet low on funds!</Typography>}
                        <br />
                        <Typography variant="body1" color="textSecondary">Last Top-up {lastTopUp?.date && <small>({moment(lastTopUp?.date).format('MMMM DD, YYYY')})</small>}</Typography>
                        <Typography variant="h3"><CheckCircle htmlColor={green[500]}/> ₱{ numberFormat(lastTopUp?.amount || 0) }</Typography><br />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="item-grid partial inner">
                            <Typography>Top-up Pre-funded Wallet</Typography>
                            <Typography className={`subtitle ${suggestedTopUp && 'error'}`}>
                                {!suggestedTopUp?
                                    'Amount to transfer':
                                    `${suggestedTopUp?.toLocaleString(undefined, {
                                        maximumFractionDigits : 2,
                                        minimumFractionDigits : 2,
                                    })} suggested amount to transfer`
                                }
                            </Typography>
                            <br/>
                            <NumberFormat thousandSeparator fixedDecimalScale
                                className         = "amount"
                                variant           = "outlined"
                                placeholder       = "0.00"
                                decimalScale      = {2}
                                allowNegative     = {false}
                                allowLeadingZeros = {false}
                                onChange          = {e => setAmount(+(e.target.value.replaceAll(',', '')))}
                            />
                            <br/>
                            <Button variant="contained" color="primary" fullWidth className="button" onClick={topUp} disabled={approvingTransfer}>
                                {!approvingTransfer? "Approve Transfer": <CircularProgress size={20}/>}
                            </Button>
                            <br/>
                            <Typography className="subtitle">
                                Amount will be deducted to funds to be deposited to your settlement bank, and will be transfered to the agreed bank account as the pre-funded wallet.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <SalesCharts component/>
    </>
})

export const useStyles = makeStyles(() =>
    createStyles({
        cashIn: {
            letterSpacing: 0,
            '& .checkbox .MuiFormControlLabel-label': {
                textAlign: 'right',
                fontSize: '14px',
                color: grey[700]
            }
        },
        cashOut: {
            letterSpacing: 0,
            '& .inner': {
                textAlign: 'center',
            },
            '& .subtitle': {
                fontSize: "12px",
                fontWeight: "normal",
                color: "rgba(0, 0, 0, 0.32)",
                '&.error': {
                    color: red[700]
                }
            },
            '& .button': {
                marginBottom: "1rem"
            },
            '& .amount': {
                marginBottom: "1rem",
                textAlign: "center",
                width: "100%"
            }
        },
    })
);

export default ApiClientOverview;