import { CircularProgress } from "@material-ui/core";
import InputField from "app/components/InputField";
import { selectUserDashboard } from "app/containers/UserDashboardPage/selectors";
import imageCompression from "browser-image-compression";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { checkDimension } from "utils";
import IconSelectField from "../../../../../components/IconSelectField";
import Categories from "./Categories";
import Option from "./option";
import { field_types, icons } from "./options";
import TypeRadioGroup from "./TypeRadioGroup";

interface Props {
    name     : string
    label?   : string
    option   : Option
    account  : string
    children?: React.ReactNode
    onChange?: (option: Option) => void
}

const CustomizationField = memo(({name, label, option, account, onChange}: Props) => {
    const categories = useSelector(selectUserDashboard).categories

    const [largeImage, setLargeImage] = useState(false);
    const [smallImage, setSmallImage] = useState(false);
    
    const _name = `${name}_option3_img_url`

    const setImage = async (option, file) => {
        if (!file || !onChange) {
            return;
        }
        onChange({...option, img_url: undefined})
        if (file.size > 500000) {
            setLargeImage(true);
            return;
        }
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.5,
            useWebWorker: true
        });
        const img_url     = await imageCompression.getDataUrlFromFile(compressedFile)
        const isValidSize = await checkDimension(img_url)
        setSmallImage(!isValidSize)
        setLargeImage(false)
        if (isValidSize) {
            onChange({...option, img_url})
        }
    }

    return <>
        <section className="input-group">
            <div>
                <label>{label}</label>
            </div>
            <TypeRadioGroup
                name     = {name}
                selected = {option?.type}
                account  = {account}
                onChange = {type => onChange && onChange({...option, type})}
            />
            {
                option?.type == 'static'? <>
                    <InputField
                        style       = {{container: 'full-width'}}
                        type        = "text"
                        placeholder = "Type here.."
                        value       = {option?.title}
                        onChange    = {e => onChange && onChange({...option, title: e.target.value})}
                    />
                    <InputField
                        style         = {{container: 'full-width'}}
                        name          = {_name}
                        type          = 'file'
                        accept        = 'image/jpeg, image/jpg, image/png, image/svg'
                        instruction   = 'At least 100 x 100, maximum file size 500kb'
                        error         = {largeImage || smallImage}
                        error_message = {
                            largeImage? "Image file size is too large":
                            smallImage? "Image size is too small":
                            ""
                        }
                        value       = {option?.img_url}
                        onChange    = {e => e.target.files && setImage(option, e.target.files[0])}
                    />
                </>:
                option?.type == 'field'? <>
                    <IconSelectField
                        text         = {option?.title}
                        icon         = {option?.icon}
                        options      = {icons.autosweep}
                        style        = {{container: 'full-width'}}
                        onTextChange = {title => onChange && onChange({...option, title})}
                        onIconChange = {icon => onChange && onChange({...option, icon})}
                    />
                    <InputField
                        style   = {{container: 'full-width'}}
                        options = {Object.keys(field_types).map(type =>
                            <option value={type} selected={type == option?.input_type}>{field_types[type]}</option>
                        )}
                        type           = "select"
                        placeholder    = "Select Validation"
                        value          = {option?.input_type}
                        onSelectChange = {e => onChange && onChange({...option, input_type: e.target.value})}
                    />
                    <InputField
                        style       = {{container: 'full-width'}}
                        type        = "text"
                        value       = {option?.name}
                        placeholder = "Field Name"
                        onChange    = {e => onChange && onChange({...option, name: e.target.value})}
                    />
                    <InputField
                        style    = {{container: 'full-width'}}
                        type     = "text"
                        value    = {option?.tooltip}
                        onChange = {e => onChange && onChange({...option, tooltip: e.target.value})}
                    />
                </>:
                option?.type == 'multiple'? <>
                    <IconSelectField
                        text         = {option?.title}
                        icon         = {option?.icon}
                        options      = {icons.autosweep}
                        style        = {{container: 'full-width'}}
                        onTextChange = {title => onChange && onChange({...option, title})}
                        onIconChange = {icon => onChange && onChange({...option, icon})}
                    />
                    <InputField
                        style   = {{container: 'full-width'}}
                        options = {Object.keys(field_types).map(type =>
                            <option value={type} selected={type == option?.input_type}>{field_types[type]}</option>
                        )}
                        type           = "select"
                        placeholder    = "Select Validation"
                        value          = {option?.input_type}
                        onSelectChange = {e => onChange && onChange({...option, input_type: e.target.value})}
                    />
                    <InputField
                        style    = {{container: 'full-width'}}
                        type     = "text"
                        value    = {option?.tooltip}
                        onChange = {e => onChange && onChange({...option, tooltip: e.target.value})}
                    />
                    {
                        categories?
                        <Categories/>:
                        <div style={{margin: "1rem auto"}}>
                            <CircularProgress />
                        </div>
                    }
                </>:
                option?.type == 'disable'? <>
                    <InputField readonly
                        style       = {{container: 'full-width'}}
                        type        = "text"
                        placeholder = "Type here.."
                        value       = {option?.title}
                        onChange    = {e => onChange && onChange({...option, title: e.target.value})}
                    />
                </>:
                <></>
            }
        </section>
    </>
})

export default CustomizationField