import moment from 'moment';

export function validateEmail(email) {
    if (email) {
        const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
        return !!email.toLowerCase().match(email_regex)
    }
    return false
}

export async function get_ip() {
    let ip_response = await fetch("https://api.ipify.org");
    let ip_address  = await ip_response.text();
    return ip_address;
}

export const numberFormat = number => (Math.round((number + Number.EPSILON) * 100) / 100).toLocaleString('en-US', {minimumFractionDigits: 2})

export const capitalize = text => text? text.charAt(0).toUpperCase() + text.slice(1) : text;

export const title = text => {
    const words = text.split(' ');
    var   title = ""
    for (const word of words) {
        title += capitalize(word) + " ";
    }
    return title.trim()
}

export const formatMobile = mobileNumber => (
    mobileNumber?
    mobileNumber.substring(0, 3) + (
        mobileNumber.length > 3 ? (
            " " + mobileNumber.substring(3, 6) + (
                mobileNumber.length > 6 ? " " + mobileNumber.substring(6): ''
            )
        ) : ''
    ): mobileNumber
)

export const removeSpaces = (str)  => str? str.replaceAll(' ', ''): str;
export const initials     = (name) => name? name.split(' ').map(name => name[0]).join(''): name;

const specialChars = `\`!@#$%^&*()_+-=[]{};':"\\|,.<>/?~ `;
export const hasSpecialChar = str => str.length > 0 && specialChars.split('').some(specialChar => str.includes(specialChar));

export const getExtension = str => str.split('.').pop();

export const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], "blob", {type: mimeString});
}

export const formatDatetime = (string, format) => moment(string).format(format)

export const countryName = code => {
    try {
        return new Intl.DisplayNames(['en'], {type: 'region'}).of(code)
    } catch(err) {
        return code
    }
}

export const roundOff = number => Math.round((number + Number.EPSILON) * 100) / 100

export const checkDimension = (img, height = 100, width = 100) => new Promise((resolve, _) => {
    const image  = new Image();
    image.onload = () => resolve(image.width >= width && image.height >= height ? img : false)
    image.src    = img;
})

export const decodePythonString = (input: string) => {
    try {
        return decodeURIComponent(
            input.replace(/\\x([0-9A-Fa-f]{2})|\\u([0-9A-Fa-f]{4})|&amp;/g, (_, hex, unicode) => {
                if (hex) {
                    return String.fromCharCode(parseInt(hex, 16));
                } else if (unicode) {
                    return String.fromCharCode(parseInt(unicode, 16));
                } else if (_ === '&amp;') {
                    return '&';
                }
                return _;
            }).replace(/\\+/g, '')
        )
    } catch {
        return input;
    }
}
