import React, { memo } from "react";
import Modal, { DefaultModalProps } from "app/components/Modal";
import Progress from "../../components/Progress";
import ButtonLoader from "app/components/ButtonLoader";
import { useSelector } from "react-redux";
import { selectSignUpState } from "./selectors";
import { useStyles } from "./AccountSetup/styles";

const ProgressModal = memo(({state}: DefaultModalProps) => {
    const classes = useStyles();
    const signup = useSelector(selectSignUpState);
    const [open, setOpen] = state;

    return (
        <Modal state = {[open, setOpen]} style = "larger_modal" dialogClassname={classes.progressModal}  disableBackdropClick>
            <section className="pseudo-form">
                <section className="header">
                    <h1 className={classes.header}>Here is your progress</h1>
                    <div className={classes.subheader}>You're doing great! Just a few steps away from creating your Payment Address and Payment Page.</div>
                </section>
                <section className="body">
                    <section className={classes.divider}><span>ACCOUNT INFO</span></section>
                    <Progress status="complete">
                        <p>Congratulations! Your Payment Address has been created.</p>
                    </Progress>
                    <section className={classes.divider}><span>DEPOSITORY ACCOUNT DETAILS</span></section>
                    <Progress status={signup.depositoryStatus}>{ //style={`${depository_account === false && 'hide'}`}>{
                        signup.depositoryStatus === "complete"   ?  <p>Congratulations! Your Payment Page have been created.</p>:
                        signup.depositoryStatus === "incomplete" ?  <p>Skipping this step will prevent you having a Payment Page</p>:
                        <p>Provide a preferred payout to activate your Payment Page.</p>
                    }</Progress>
                    <section className={classes.divider}><span>IDENTITY VERIFICATION</span></section>
                    <Progress status={signup.documentStatus}>{ // style={`${documents === false && 'hide'}`}>{
                        signup.documentStatus === "complete"   ?  <p>Congratulations! Your Payment Page have been activated.</p>:
                        signup.documentStatus === "incomplete" ?  <p>Skipping this step will prevent you receiving money.</p>:
                        <p>Verify your identity to start receiving money.</p>
                    }</Progress>
                </section>
                <section className="footer">
                    <ButtonLoader hasLoader
                        loading = {false}
                        style = 'filled blue'
                        text  = 'Continue'
                        onClick={() => setOpen(false)}
                    />
                </section>
            </section>
        </Modal>
    )
})

export default ProgressModal;