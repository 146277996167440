import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import React, { memo, useEffect, useState } from "react";
import { busines_document_names, valid_documents } from "utils/options";
import imageCompression from 'browser-image-compression';
import SignatureModal from "./SignatureModal";
import { WEB_ASSETS } from "app/common/common";
import { DocFile, DocumentMeta } from "../../types";

import docIcon from 'styles/sass/images/docx.svg';
import pdfIcon from 'styles/sass/images/pdf.svg';
import { useDispatch } from "react-redux";
import { signUpActions } from "../../slice";
import ConfirmModal from "app/components/Modal/ConfirmModal";
import { useStyles } from "../styles";

import esignature from "assets/img/e-signature.png";
import afp from "assets/img/documents/selfie/afp_id.jpg";
import drivers_license from "assets/img/documents/selfie/drivers_license.jpg";
import dswd_certification from "assets/img/documents/selfie/dswd_certification.jpg";
import gsis from "assets/img/documents/selfie/gsis.jpg";
import nbi_clearance from "assets/img/documents/selfie/nbi_clearance.jpg";
import ncwdp from "assets/img/documents/selfie/ncwdp.jpg";
import ofw_id from "assets/img/documents/selfie/ofw_id.jpg";
import passport from "assets/img/documents/selfie/passport.jpg";
import postal_id from "assets/img/documents/selfie/postal_id.jpg";
import prc_id from "assets/img/documents/selfie/prc_id.jpg";
import senior_citizen_card from "assets/img/documents/selfie/senior_citizen_card.jpg";
import SSS from "assets/img/documents/selfie/SSS.jpg";
import umid from "assets/img/documents/selfie/umid.jpg";
import voters_id from "assets/img/documents/selfie/voters_id.jpg";


interface Props {
    account_type? : string
    business_type?: string
    saving?       : boolean
    forReview?    : boolean
    formData      : DocumentMeta | undefined
    next?         : (d: DocumentMeta) => any
    back?         : () => any
    skip?         : () => any
}

const Documents = memo(({account_type, business_type, saving, forReview, formData, next, back: previousStep, skip}: Props) => {
    const signatureState = useState(false);
    const dispatch = useDispatch();
    const classes  = useStyles();

    const large_image = 'Image file size is too large.'
    const large_file  = 'File size is too large.'
    const small_image = 'Image size is too small.'
    const required_field = 'This field is required.'

    // Personal Documents
    const [type, setType]           = useState(formData?.type || '');
    const [front, setFront]         = useState(formData?.front);
    const [back, setBack]           = useState(formData?.back);
    const [signature, setSignature] = useState(formData?.signature);
    const [selfie, setSelfie]       = useState(formData?.selfie);

    const [errorFront, setErrorFront]         = useState(false);
    const [errorBack, setErrorBack]           = useState(false);
    const [errorSignature, setErrorSignature] = useState(false);
    const [errorSelfie, setErrorSelfie]       = useState(false);

    const [errorLargeFront, setErrorLargeFront]         = useState(false);
    const [errorLargeBack, setErrorLargeBack]           = useState(false);
    const [errorLargeSignature, setErrorLargeSignature] = useState(false);
    const [errorLargeSelfie, setErrorLargeSelfie]       = useState(false);

    const [emptyType, setEmptyType]           = useState(false);
    const [emptyFront, setEmptyFront]         = useState(false);
    const [emptyBack, setEmptyBack]           = useState(false);
    const [emptySignature, setEmptySignature] = useState(false);
    const [emptySelfie, setEmptySelfie]       = useState(false);

    // Business Documents
    const [business_documents, setBusinessDocuments] = useState<DocFile[]>(formData?.business_documents || []);
    const [authorization_letter, setAuthLetter]      = useState(formData?.authorization_letter);

    const [errorBusinessDocuments, setErrorBusinessDocuments] = useState([false,false,false,false]);
    const [errorAuthLetter, setErrorAuthLetter]               = useState(false);

    const [emptyBusinessDocuments, setEmptyBusinessDocuments] = useState([false,false,false,false]);
    const [emptyAuthLetter, setEmptyAuthLetter]               = useState(false);
    
    const [errorLargeBusinessDocuments, setErrorLargeBusinessDocuments] = useState([false,false,false,false]);
    const [errorLargeAuthLetter, setErrorLargeAuthLetter]               = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);

    const checkDimension = img => new Promise((resolve, _) => {
        const image  = new Image();
        image.onload = () => resolve(image.width >= 100 && image.height >=100 ? img : false)
        image.src    = img;
    })

    const setBusinessDocument = async (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const docs    = [...business_documents];
        docs  [index] = {file: "", type: ""};
        setBusinessDocuments(docs);
        let file = event.target.files && event.target.files[0]
        if (file) {
            if (file.size > 11000000) {
                const errDocs = [...errorLargeBusinessDocuments];
                errDocs[index] = true;
                setErrorLargeBusinessDocuments(errDocs);
                return;
            }
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                const        docs         = [...business_documents];
                const        errDocs      = [...errorBusinessDocuments];
                const        emptyDocs    = [...emptyBusinessDocuments];
                const        errLargeDocs = [...errorLargeBusinessDocuments];
                docs         [index]      = {
                    file: reader.result,
                    type: file?.name.split('.').pop() || ''
                };
                errDocs      [index]      = false;
                emptyDocs    [index]      = false;
                errLargeDocs [index]      = false;
                setBusinessDocuments(docs);
                setErrorBusinessDocuments(errDocs);
                setEmptyBusinessDocuments(emptyDocs);
                setErrorLargeBusinessDocuments(errLargeDocs);
            })
            reader.readAsDataURL(file);
        }
    }

    const setDocument = async (file, largeErrorSetter, errorSetter, emptyErrorSetter, setter) => {
        setter('');
        if (file) {
            if (file.size > 11000000) {
                largeErrorSetter(true);
                return;
            }
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setter({
                    file: reader.result,
                    type: file?.name.split('.').pop() || ''
                });
                errorSetter(false);
                emptyErrorSetter(false);
                largeErrorSetter(false);
            })
            reader.readAsDataURL(file);
        }
    }

    const setImage = async (stateSetter, errorSetter, emptySetter, largeError, file: File) => {
        stateSetter('');
        if (file.size > 5000000) {
            largeError(true);
            return;
        }
        if (file) {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1,
                useWebWorker: true
            });
            const imageUri    = await imageCompression.getDataUrlFromFile(compressedFile)
            const isValidSize = await checkDimension(imageUri)
            const image = {
                file: isValidSize ? imageUri: undefined,
                type: file.name.split('.').pop()
            }
            stateSetter(image)
            errorSetter(!isValidSize)
            emptySetter(false)
            largeError(false);
        }
    }

    const saveSignature = value => {
        setSignature(value)
        setErrorSignature(false)
        setEmptySignature(false)
        setErrorLargeSignature(false)
    }

    const is_business     = account_type === 'business'
    const verifying       = is_business ? 'Business' : 'Identity'
    const document_header = is_business ? 'BUSINESS REPRESENTATIVE VERIFICATION' : 'IDENTITY VERIFICATION'

    const _next = () => {
        setEmptyType(!type)
        setEmptyFront(!front?.type)
        setEmptyBack(!back?.type)
        setEmptySignature(!signature?.type)
        setEmptySelfie(!selfie?.type)
        if (is_business) {
            setEmptyBusinessDocuments([
                !business_documents[0]?.type,
                !business_documents[1]?.type,
                !business_documents[2]?.type,
                !business_documents[3]?.type,
            ])
        }
        if (next && (
            type && front?.type && back?.type &&
            signature?.type && selfie?.type &&
            (!is_business || (
                business_documents[0]?.type &&
                business_documents[1]?.type &&
                business_documents[2]?.type &&
                business_documents[3]?.type
            ))
        )) {
            onConfirm()
            // setConfirmModal(true)
        }
    }

    useEffect(() => {
        dispatch(signUpActions.setDocumentMeta({
            type, front, back, signature, selfie, business_documents, authorization_letter
        }))
    }, [type, front, back, signature, selfie, business_documents, authorization_letter])

    const onConfirm = () => {
        setConfirmModal(false)
        if (next) {
            next({type, front, back, signature, selfie, business_documents, authorization_letter})
        }
    }

    return <>
        <ConfirmModal state = {[confirmModal, setConfirmModal]} onConfirm = {onConfirm} message = "Are you sure you want to continue?"/>
        {!forReview && <section className="header">
            <h3 className={classes.header}>Verify your {verifying}</h3>
            <div className={classes.subheader}>Verifying {verifying.toLowerCase()} helps create a safe place for everyone to transact, and to comply with regulatory requirements.</div>
        </section>}
        {is_business && <>
            <section className={classes.divider}><span>PROOF OF BUSINESS</span></section>
            <section className="input-group" data-business>
                {
                    business_type && Object.keys(busines_document_names[business_type]).map(
                        (value, i) => {
                            return <InputField
                                type        = 'file'
                                label       = {busines_document_names[business_type][value]}
                                instruction = '.jpeg, .jpg, .png, .doc, .docx, .pdf. max 10MB'
                                name        = {value}
                                accept      = '.jpeg, .jpg, .png, .doc, .docx, .pdf'
                                tooltip     = {<>
                                    <img data-document-front src={`${WEB_ASSETS}/tooltips/documents/${value}.png`}/>
                                    <div className="body">
                                        <h5>Example ({busines_document_names[business_type][value]}):</h5>
                                    </div>
                                </>}
                                value   = {business_documents[i]?.file?.toString()}
                                valid   = {(!business_documents[i] || undefined) && !!business_documents[i] && !errorBusinessDocuments[i] && !emptyBusinessDocuments[i] && !errorLargeBusinessDocuments[i]}
                                error   = {errorBusinessDocuments[i] || emptyBusinessDocuments[i] || errorLargeBusinessDocuments[i]}
                                error_message = {
                                    errorLargeBusinessDocuments[i]? large_file:
                                    emptyBusinessDocuments[i]? required_field :
                                    errorBusinessDocuments[i]? small_image : ''
                                }
                                preview = {
                                    business_documents[i] && business_documents[i]?.file?.toString()?.includes('/pdf;') ? pdfIcon:
                                    business_documents[i] && business_documents[i]?.file?.toString()?.includes('officedocument') ? docIcon:
                                    business_documents[i] && business_documents[i]?.file?.toString()?.includes('msword') ? docIcon:
                                    undefined
                                }
                                onChange = {e => setBusinessDocument(i, e)}
                            />
                        }
                    )
                }
            </section>
            <section className={classes.divider}><span>AUTHORIZATION LETTER</span></section>
            <section className="input-group" data-authorization>
                <InputField
                    type        = 'file'
                    label       = 'Authorization Letter (Optional)'
                    instruction = '.jpeg, .jpg, .png, .doc, .docx, .pdf. max 10MB'
                    name        = 'business_document_1'
                    accept      = '.jpeg, .jpg, .png, .doc, .docx, .pdf'
                    valid   = {(!authorization_letter || undefined) && !!authorization_letter && !errorAuthLetter && !errorLargeAuthLetter}
                    value   = {authorization_letter?.file?.toString()}
                    error   = {errorAuthLetter || errorLargeAuthLetter}
                    error_message = {
                        errorLargeAuthLetter? large_file:
                        errorAuthLetter? small_image : ''
                    }
                    preview = {
                        authorization_letter?.file?.toString()?.includes('/pdf;') ? pdfIcon:
                        authorization_letter?.file?.toString()?.includes('officedocument') ? docIcon:
                        authorization_letter?.file?.toString()?.includes('msword') ? docIcon:
                        undefined
                    }
                    onChange = {e => e.target.files && setDocument(e.target.files[0], setErrorLargeAuthLetter, setErrorAuthLetter, setEmptyAuthLetter, setAuthLetter)}
                />
                <section className="input-box">
                    <p>If your name is not included in any of the required documents, please upload an authorization letter signed out by the business owner/s.</p>
                </section>
            </section>
        </>}
        <section className={classes.divider}><span>{document_header}</span></section>
        <section className="input-group" data-documents>
            <InputField loader
                type    = 'select'
                name    = 'document_type'
                options = {Object.keys(valid_documents).map(type => <option value={type}>{valid_documents[type]}</option>)}
                label   = 'Valid Document'
                value   = {type}
                valid   = {!!type}
                error   = {emptyType}
                error_message = {emptyType? required_field : ''}
                style   = {{container: 'full-width'}}
                placeholder = "Select Valid Document"
                onSelectChange = {e => {
                    setType(e.target.value)
                    setEmptyType(!e.target.value)
                }}
                tooltip = {    
                    <div className="body">
                        <h5>Valid Documents:</h5>
                        <ul className="two-col">
                        {Object.values(valid_documents).map(
                            value => <li>{value}</li>
                        )}
                        </ul>
                    </div>
                }
            />
            <InputField
                type        = 'file'
                label       = 'Front'
                instruction = 'jpeg, jpg, png. max 5MB'
                name        = 'document_front'
                accept      = 'image/jpeg, image/jpg, image/png'
                tooltip     = {!type? undefined: <>
                    <img data-document-front src={`${WEB_ASSETS}/tooltips/documents/${type}-front.png`}/>
                    <div className="body">
                        <h5>Example:</h5>
                    </div>
                </>}
                value   = {front?.file?.toString()}
                valid   = {!!front?.file || undefined}
                error   = {errorFront || emptyFront || errorLargeFront}
                error_message = {
                    errorLargeFront ? large_image:
                    emptyFront? required_field :
                    errorFront? small_image : ''
                }
                onChange = {e => e.target.files && setImage(setFront, setErrorFront, setEmptyFront, setErrorLargeFront, e.target.files[0])}
            />
            <InputField
                type        = 'file'
                label       = 'Back'
                instruction = 'jpeg, jpg, png. max 5MB'
                name        = 'document_back'
                accept      = 'image/jpeg, image/jpg, image/png'
                tooltip     = {!type? undefined: <>
                    {type && <img data-document-back src={`${WEB_ASSETS}/tooltips/documents/${type}-back.png`}/>}
                    <div className="body">
                        <h5>Example:</h5>
                    </div>
                </>}
                value   = {back?.file?.toString()}
                valid   = {!!back?.file || undefined}
                error   = {errorBack || emptyBack || errorLargeBack}
                error_message = {
                    errorLargeBack ? large_image:
                    emptyBack? required_field :
                    errorBack? small_image : ''
                }
                onChange = {e => e.target.files && setImage(setBack, setErrorBack, setEmptyBack, setErrorLargeBack, e.target.files[0])}
            />
            <InputField
                type        = 'file'
                label       = 'e-Signature'
                instruction = 'jpeg, jpg, png. max 5MB'
                name        = 'e_signature'
                accept      = 'image/jpeg, image/jpg, image/png'
                tooltip     = {<>
                    <img data-signature src={esignature}/>
                    <div className="body">
                        <h5>Example:</h5>
                    </div>
                </>}
                value   = {signature?.file?.toString()}
                valid   = {!!signature?.file || undefined}
                error   = {errorSignature || emptySignature || errorLargeSignature}
                error_message = {
                    errorLargeSignature ? large_image:
                    emptySignature? required_field :
                    errorSignature? small_image : ''
                }
                onChange = {e => e.target.files && setImage(setSignature, setErrorSignature, setEmptySignature, setErrorLargeSignature, e.target.files[0])}
            />
            <section className="input-box">
                <p>Upload and image of your handwritten signature or draw your signature <span style={{ cursor: 'pointer' }} onClick={() => signatureState[1](true)}>here</span>.</p>
                <SignatureModal state={signatureState} saveSignature={saveSignature}/>
            </section>
        </section>
        <section className={classes.divider}><span>SELFIE VERIFICATION</span></section>
        <section className="input-group" data-selfie-form>
            <InputField
                type        = 'file'
                label       = 'Selfie Upload'
                instruction = 'jpeg, jpg, png. max 5MB'
                name        = 'selfie_verification'
                accept      = 'image/jpeg, image/jpg, image/png'
                tooltip     = {type ? <>
                    <img data-signature src={selfie_tooltip[type]}/>
                    <div className="body">
                        <h5>Example:</h5>
                    </div>
                </>: ""}
                value   = {selfie?.file?.toString()}
                valid   = {!!selfie?.file || undefined}
                error   = {errorSelfie || emptySelfie || errorLargeSelfie}
                error_message = {
                    errorLargeSelfie ? large_image:
                    emptySelfie? required_field :
                    errorSelfie? small_image : ''
                }
                onChange = {e => e.target.files && setImage(setSelfie, setErrorSelfie, setEmptySelfie, setErrorLargeSelfie, e.target.files[0])}
            />
            <section className="input-box">
                <p>Please upload a photo of yourself while holding up the same document that you provided above. Both you and the provided document should be clearly visible.</p>
            </section>
        </section>
        {!forReview && <section className="footer">
            <section className="buttons">
                {previousStep && <ButtonLoader
                    name  = 'depository_account_back'
                    style = 'bordered blue'
                    text  = 'Back'
                    onClick = {previousStep}
                />}
                <ButtonLoader hasLoader
                    loading = {saving}
                    name  = 'depository_account'
                    style = 'filled blue'
                    text  = 'Next'
                    onClick={() => _next()}
                />
            </section>
            {skip && <ButtonLoader
                name  = 'skip_button'
                style = 'text blue'
                text  = 'Skip this step.'
                onClick = {skip}
            />}
        </section>}
    </>
})

const selfie_tooltip = {
    afp              : afp,
    dswdcertification: dswd_certification,
    nbi              : nbi_clearance,
    ncwdp            : ncwdp,
    passport         : passport,
    prc              : prc_id,
    sss              : SSS,
    voters           : voters_id,
    driverslicense   : drivers_license,
    gsis             : gsis,
    ofw              : ofw_id,
    postal           : postal_id,
    seniorcitizen    : senior_citizen_card,
    umid             : umid
}

export default Documents;