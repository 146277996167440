import React, { memo, ReactNode } from "react";
import Modal, { DefaultModalProps } from '.';
import ButtonLoader from "../ButtonLoader";
import error_icon from 'assets/img/error.svg';

import 'styles/sass/dialog.scss';

interface Props extends DefaultModalProps {
    children?   : ReactNode
    className  ?: string
    title?      : string
    timer?      : string
    message?    : string
    closeButton?: boolean
    onClose?    : () => void
}

const ErrorModal = memo((props: Props) => {
    return (
        <Modal
            disableEscapeKeyDown
            disableBackdropClick
            disableCloseButton = {!props.closeButton}
            state = {props.state}
            style = {`action-box content-box success ${props.className}`}
        >
            <h2 className="center" style={{fontWeight: 800}}>{props.title || "Oh no!"}</h2>
            <img className="logo" src={error_icon} alt="Error Icon" style={{width: '100px', height: 'auto'}}/>
            <p className="center nowrap" style={{margin: '1rem 0', color: 'black', fontSize: '12px', letterSpacing: 0}}>{props.message || props.children}</p>
            <ButtonLoader hasLoader
                name  = 'ok'
                style = 'filled blue'
                text  = 'OK'
                onClick = {() => {
                    props.state[1](false)
                    if (props.onClose !== undefined) {
                        props.onClose();
                    }
                }}
            />
        </Modal>
    )
});

export default ErrorModal;