import InputField from "app/components/InputField";
import React, { memo, useEffect, useState } from "react";
import { busines_document_names, valid_documents } from "utils/options";
import Section from "./Section";

import { selectAuth, selectUserInfo } from "app/common/auth/selectors";
import { DOWNLOAD_DOCUMENT } from "app/common/core_api/resources";
import { useSelector } from "react-redux";
import docIcon from 'styles/sass/images/docx.svg';
import pdfIcon from 'styles/sass/images/pdf.svg';
import { configHeaders } from "utils/request-config";

const SupportingDocuments = memo(() => {
    const auth = useSelector(selectAuth)
    const user = useSelector(selectUserInfo)
    const is_business = user?.account_type?.type == 'business'
    const business_type = user?.account_type?.business?.type

    const [business_documents, setBusinessDocuments]     = useState(['', '', '', ''])
    const [authorization_letter, setAuthorizationLetter] = useState('')

    const documentType              = user?.document_meta?.type || ''
    const [front, setFront]         = useState<any>('')
    const [back, setBack]           = useState<any>('')
    const [signature, setSignature] = useState<any>('')
    const [selfie, setSelfie]       = useState<any>('')

    const load_image = async (type, setter, index: string | undefined = undefined) => {
        const biz_doc = index? `/${index}`: ''
        const url = DOWNLOAD_DOCUMENT.replace(':type', type) + biz_doc
        const options: RequestInit = {headers: configHeaders(auth?.access_token || "")}
        return await fetch(url, options).then(response => {
            if (response.ok) {
                return response.blob()
            }
            throw ""
        }).then(blob => blobToDataURL(blob, uri => setter(uri))).catch(err => "")
    }

    const load_business_docs = async () => {
        const docs: any[] = []
        for (const i in business_documents) {
            await load_image('business_documents', file => docs.push(file), i)
        }
        await new Promise(r => setTimeout(r, 2000));
        return docs
    }

    const blobToDataURL = (blob, callback) => {
        var a = new FileReader();
        a.onload = function(e) {callback(e?.target?.result);}
        a.readAsDataURL(blob);
    }

    const download = (file, type, filename) => {
        if (!!file) {
            var a = document.createElement("a");
            a.href = file;
            a.download = type + '.' + filename?.split('.')?.pop();
            a.click();
            URL.revokeObjectURL(file);
        }
    }

    useEffect(() => {
        load_image('front'    , file => setFront(file))
        load_image('back'     , file => setBack(file))
        load_image('signature', file => setSignature(file))
        load_image('selfie'   , file => setSelfie(file))

        if (is_business) {
            // download business
            load_image('authorization_letter', file => setAuthorizationLetter(file))
            load_business_docs().then(docs => setBusinessDocuments(docs))
        }
    }, [])
    
    return <Section title="SUPPORTING DOCUMENTS">
        {
            is_business && business_type && busines_document_names[business_type] && Object.keys(busines_document_names[business_type]).map(
                (value, i) => {
                    return <InputField readonly
                        valid       = {!!business_documents[i]?.toString()}
                        type        = 'file'
                        label       = {busines_document_names[business_type][value]}
                        name        = {value}
                        instruction = '.jpeg, .jpg, .png, .doc, .docx, .pdf. max 10MB'
                        accept      = '.jpeg, .jpg, .png, .doc, .docx, .pdf'
                        value   = {business_documents[i]?.toString() || (user?.document_meta?.business_documents? user?.document_meta?.business_documents[i]: undefined)}
                        preview = {
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('.pdf') ? pdfIcon:
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('/pdf') ? pdfIcon:
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('.docx') ? docIcon:
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('.doc') ? docIcon:
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('/docx') ? docIcon:
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('officedocument') ? docIcon:
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('msword') ? docIcon:
                            user?.document_meta?.business_documents && user?.document_meta?.business_documents[i]?.toString()?.includes('octet-stream') ? docIcon:
                            undefined
                        }
                        onClick={() => download(business_documents[i], value, ((user?.document_meta?.business_documents || ['']))[i] || '')}
                    />
                }
            )
        }
        {is_business && <>
            <InputField readonly
                type        = 'file'
                label       = 'Authorization Letter (Optional)'
                name        = 'authorization_letter'
                instruction = '.jpeg, .jpg, .png, .doc, .docx, .pdf. max 10MB'
                accept      = '.jpeg, .jpg, .png, .doc, .docx, .pdf'
                valid   = {!!authorization_letter}
                value   = {authorization_letter || user?.document_meta?.authorization_letter}
                preview = {
                    user?.document_meta?.authorization_letter?.toString()?.includes('.pdf') ? pdfIcon:
                    user?.document_meta?.authorization_letter?.toString()?.includes('.docx') ? docIcon:
                    user?.document_meta?.authorization_letter?.toString()?.includes('.doc') ? docIcon:
                    undefined
                }
                onClick={() => download(authorization_letter, 'authorization_letter', user?.document_meta?.authorization_letter)}
            />
            <section className="input-box">
                <p>If your name is not included in any of the required documents, please upload an authorization letter signed out by the business owner/s.</p>
            </section>
        </>}
        <InputField loader readonly
            type    = 'select'
            name    = 'document_type'
            options = {Object.keys(valid_documents).map(type => <option value={type} selected={type == documentType}>{valid_documents[type]}</option>)}
            label   = 'Valid Document'
            valid   = {!!documentType}
            value   = {documentType}
            style   = {{container: 'full-width'}}
            placeholder = "Select Valid Document"
        />
        <InputField readonly
            type        = 'file'
            label       = 'Front'
            instruction = 'jpeg, jpg, png. max 5MB'
            name        = 'document_front'
            accept      = 'image/jpeg, image/jpg, image/png'
            valid   = {!!front}
            value   = {front}
            onClick={() => download(front, 'front', user?.document_meta?.front)}
        />
        <InputField readonly
            type        = 'file'
            label       = 'Back'
            instruction = 'jpeg, jpg, png. max 5MB'
            name        = 'document_back'
            accept      = 'image/jpeg, image/jpg, image/png'
            valid   = {!!back}
            value   = {back}
            onClick={() => download(back, 'back', user?.document_meta?.back)}
        />
        <InputField readonly
            type        = 'file'
            label       = 'e-Signature'
            instruction = 'jpeg, jpg, png. max 5MB'
            name        = 'e_signature'
            accept      = 'image/jpeg, image/jpg, image/png'
            valid   = {!!signature}
            value   = {signature}
            onClick={() => download(signature, 'signature', user?.document_meta?.signature)}
        />
        <InputField readonly
            type        = 'file'
            label       = 'Selfie Upload'
            instruction = 'jpeg, jpg, png. max 5MB'
            name        = 'selfie_verification'
            accept      = 'image/jpeg, image/jpg, image/png'
            valid   = {!!selfie}
            value   = {selfie}
            onClick={() => download(selfie, 'selfie', user?.document_meta?.selfie)}
        />
    </Section>
})

export default SupportingDocuments