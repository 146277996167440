import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { baseStyles } from "../base-styles"

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContainerWrapper: {
            position      : "relative",
            boxSizing     : "border-box",
            minHeight     : "calc(100vh - 56px)",
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center",
            margin        : "auto",
            width         : "100%",
            ["@media(min-width: 375px)"]: {
                width: "368px",
            },
            ["@media(min-width: 414px)"]: {
                width: "400px",
            },
            ["@media(min-width: 768px)"]: {
                width: "440px",
            },
            ["@media(min-width: 1024px)"]: {
                width: "820px",
            },
        },
        cardContainer: {
            fontFamily     : baseStyles.fontFamily,
            backgroundColor: "#fff",
            borderRadius   : "1rem",
            maxWidth       : "820px",
            textAlign      : "center",
            overflowY      : "auto",
            maxHeight      : "560px",
            padding        : "40px 15px",
            ["@media(min-width: 375px)"]: {
                maxHeight: "720px",
                padding: "40px 20px"
            },
            ["@media(min-width: 414px)"]: {
                maxHeight: "800px",
                marginTop: "1rem",
                padding  : "35px",
            },
            ["@media(min-width: 768px)"]: {
                maxHeight: "880px",
                marginTop: "0.5rem",
                padding  : "40px",
            },
            ["@media(min-width: 1366px)"]: {
                maxHeight: "800px",
                marginTop: "0",
            },
            ["@media(min-width: 1366px)"]: {
                maxHeight: "800px",
            },
        },
        cardHeader: {
            fontSize    : "33px",
            textAlign   : "center",
            lineHeight  : "2.25rem",
            color       : baseStyles.color.dark,
        },
        cardAccountTypeContainer: {
            ...baseStyles.grid.row,
            justifyContent: "center"
        },
        cardAccountType: {
            ...baseStyles.grid.col,
            margin       : "0",
            border       : "1px solid #CCCCCC",
            borderRadius : "1rem",
            display      : "flex",
            flexDirection: "column",
            overflow     : "auto",
            minWidth     : "100%",
            marginBottom : "40px",
            ["@media(min-width: 375px)"]: {
                minWidth: "330px",
            },
            ["@media(min-width: 1024px)"]: {
                marginBottom : "0",
                ["&:first-of-type"]: {
                    marginRight: "10px"
                },
                ["&:last-of-type"]: {
                    marginLeft: "10px"
                },
            }
        },
        cardAccountTypeHeaderContainer: {
            color     : baseStyles.color.white,
            height    : "125px",
            textAlign : "center",
            background: "#009CDE",
            padding   : "24px 15%",
        },
        cardAccountTypeHeaderTitle: {
            color       : "inherit",
            fontSize    : "21px",
            marginBottom: "0.5rem"
        },
        cardAccountTypeHeaderSubtitle: {
            color   : "inherit",
            fontSize: "14px",
        },
        cardAccountTypeBodyTitle: {
            fontSize: "14px",
            padding  : "0 40px",
            margin  : "20px",
            color   : baseStyles.color.dark,
            lineHeight: "20px"
        },
        cardAccountTypeBodyContent: {
            fontSize : "13px",
            color    : baseStyles.color.light,
            padding  : "0 30px",
            textAlign: "left",
            lineHeight: "20px"
        },
        cardAccountTypeFooterContainer: {
            padding     : "0 30px 30px",
            marginTop   : "auto",
            border      : "0",
            fontSize    : "13px",
            fontFamily  : baseStyles.fontFamily,
            ["& button"]: {
                marginBottom: "0"
            }
        },
        cardAccountTypeFooter: {
            marginBottom: "1rem",
        },
        cardAccountTypeFeeAmount: {
            fontSize: "72px",
            color   : baseStyles.color.link
        },
        cardAccountTypeFeeCurrency: {
            verticalAlign: "top",
            fontSize     : "13px",
            color        : baseStyles.color.light
        },
        cardAccountTypeFeeNote: {
            fontStyle: "italic",
            fontSize : "13px",
            color    : baseStyles.color.light
        },
        cardAccountTypeButton: {
            textTransform: "uppercase",
            fontSize     : "17px",
            color        : baseStyles.color.white
        },
        cardAccountTypeFooterBusiness: {
            textAlign: "left",
            fontSize : "13px",
            color    : baseStyles.color.light
        },
        cardAccountTypeBusinessFees: {
            fontSize: "13px",
            color   : baseStyles.color.link
        },
        modal: {
            borderRadius: "1rem",
            fontFamily  : baseStyles.fontFamily,
            color       : baseStyles.color.dark,
            maxHeight   : "calc(100% - 20px)",
            ["@media(max-width: 1023px)"]: {
                width: "500px",
            },
            ["@media(max-width: 767px)"]: {
                width: "400px",
            },
        },
        modalContainer: {
            padding: "30px",
            ["@media(min-width: 768px)"]: {
                padding: "40px"
            }
        },
        modalHeader: {
            fontSize: "33px",
            color   : "inherit",
            marginBottom: "30px"
        },
        modalSubheader: {
            fontSize: "14px",
            color   : "inherit",
            marginBottom: "30px",
            lineHeight: "20px"
        },
        modalSocialMediaContainer: {
            ...baseStyles.grid.row,
            padding       : "0",
            justifyContent: "center",
        },
        modalSocialMediaButton: {
            ...baseStyles.grid.col,
            fontFamily   : `${baseStyles.fontFamily}!important`,
            letterSpacing: "0.5px",
            minWidth     : "100%",
            ["&:nth-of-type(1), &:nth-of-type(3)"]: {
                marginRight: "0"
            },
            ["&:nth-of-type(1), &:nth-of-type(2)"]: {
                marginBottom: "20px"
            },
            ["@media(min-width: 414px)"]: {
                minWidth : "150px",
                ["&:nth-of-type(1), &:nth-of-type(3)"]: {
                    marginRight: "20px"
                },
            },
        },
        modalDividerContainer: {
            margin        : "30px 0 2.5rem",
            height        : "1px",
            display       : "flex",
            alignItems    : "center",
            justifyContent: "space-between",
            ["&:before, &:after"]: {
                content  : "' '",
                flex     : "1 1 auto",
                borderTop: `1px solid ${baseStyles.color.disabled}`,
            }
        },
        modalDivider: {
            flex         : "0 0 auto",
            maxWidth     : "90%",
            display      : "block",
            letterSpacing: "0.25rem",
            color        : baseStyles.color.dark,
            padding      : "0 1.5rem",
            fontSize     : "14px",
        },
        modalForm: {
            ...baseStyles.grid.row,
            margin     : "0",
            padding    : "0",
            ["& > .userinfo"]: {
                display: "flex",
                flexWrap: "wrap",
                width: "100%"
            }
        },
        modalFormHeader: {
            width: "100%",
            fontSize: "21px",
            marginBottom: "30px",
            lineHeight: "20px"
        },
        modalFormInput: {
            ["&::placeholder"]: {
                color: baseStyles.color.disabled
            },
            ["& > option"]: {
                width: "300px"
            }
        },
        modalFormInputContainer: {
            ...baseStyles.grid.col,
            minWidth: "100%",
            marginBottom: "30px!important",
            ["@media(min-width: 414px)"]: {
                ["&:not(.full-width)"]: {
                    minWidth: "150px",
                },
                ["& .absolute-container"]: {
                    right: "0"
                }
            },
            ["@media(min-width: 768px)"]: {
                ["&:not(.full-width)"]: {
                    width: "200px"
                }
            },
            ["&:nth-of-type(1), &:nth-of-type(3)"]: {
                marginRight: "20px"
            },
            ["&:nth-of-type(3), &:nth-of-type(4)"]: {
                marginBottom: "42px!important"
            },
            ["& > small"]: {
                position: "absolute"
            }
        },
        modalButton: {
            marginBottom: "40px",
        },
        modalFooter: {
            fontSize: "12px",
            color: baseStyles.color.light
        },
        modalLinks: {
            fontWeight: "normal",
            color: baseStyles.color.link
        },
        modalCloseButton: {
            zIndex  : 10,
            position: "absolute",
            top     : "13px",
            right   : "13px",
            margin  : "0",
            padding : "0",
            width: "25px",
            height: "25px",

            ['&:before']: {
                fontFamily: "fontawesome",
                content   : '"\\f057"',
                color     : baseStyles.color.disabled,
                transform : "translateY(-50%)",
                fontSize  : "1.5rem",
            }
        },
        button: {
            fontFamily: "Arial",
        }
    })
)