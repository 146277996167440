import { LINKEDIN_API } from "app/common/core_api/resources"
import { useQuery } from "utils/useQuery";

const OAUTH_URL = process.env.REACT_APP_LINKEDIN_OAUTH_URL || ''
const useLinkedInLogin = () => {
    let query       = useQuery(),
        email       = query.get('email'),
        access_code = query.get('access_code');

    let redirect_uri = window.location.href.split('?')[0];
        redirect_uri = `${redirect_uri}?email=${email}&access_code=${access_code}`;

    const login = () => {
        let url    = new URL(`${OAUTH_URL}/v2/authorization`),
            params = {
                response_type: "code",
                scope        : "openid,profile",
                client_id    : process.env.REACT_APP_LINKEDIN_CLIENT_ID || '',
                redirect_uri : redirect_uri
            }
        Object.entries(params).forEach(([k,v]) => url.searchParams.append(k, v as string))
        window.onbeforeunload = null;
        window.location.href = url.toString();
    }

    const getUser = async ({code}) => {
        return await fetch(`${LINKEDIN_API}/user?code=${code}&redirect_uri=${encodeURIComponent(redirect_uri)}`).then(res => res.json());
    }
    return {login, getUser}
}

export default useLinkedInLogin;