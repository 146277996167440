import React, { memo, ReactNode } from 'react';
import {Dialog} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { userDashboardPageActions } from 'app/containers/UserDashboardPage/slice';

export interface DefaultModalProps {
    state: [boolean, any]
}

interface Props {
    children?            : ReactNode
    headerText?          : string
    buttonText?          : string
    style?               : string
    containerClassname?  : string
    dialogClassname?     : string
    modalClassname?      : string
    closeButtonClassname?: string
    state                : [boolean, any]
    disableEscapeKeyDown?: boolean
    disableBackdropClick?: boolean
    disableCloseButton?  : boolean
    onClose?             : () => void
}

const Modal = memo((props: Props) => {
    const dispatch = useDispatch()
    const {
        children,
        headerText,
        buttonText,
        style,
        containerClassname,
        dialogClassname,
        modalClassname,
        closeButtonClassname,
        disableEscapeKeyDown,
        disableBackdropClick,
        disableCloseButton
    } = props;
    const [open, toggleModal] = props.state;
    const header  = headerText? <h3>{headerText}</h3>: ''
    const classes = {
        container: `modal-container ${open? '' : 'hide'}`,
        modal    : `modal ${style || ''}`,
        button   : `close ${buttonText? 'is-texted': ''}`,
    }
    
    const onClose = (_, reason: string) => {
        dispatch(userDashboardPageActions.updateActionTime())
        if (
            (reason == 'escapeKeyDown' && !disableEscapeKeyDown) ||
            (reason == 'backdropClick' && !disableBackdropClick)
        ) {
            props.onClose && props.onClose()
            toggleModal(false)
        }
    }

    return (
        <Dialog
            open       = {open}
            className  = {containerClassname || classes.container}
            PaperProps = {{ className: dialogClassname || '' }}
            onClose    = {onClose}
        >
            <section className={modalClassname || classes.modal}>
                {header}
                <section className="content">
                    {children}
                </section>
                {!disableCloseButton && (
                    <button className={closeButtonClassname || classes.button} type="button" onClick={() => {
                        (props.onClose && props.onClose());
                        toggleModal(false)
                    }}>
                        {buttonText || ''}
                    </button>
                )}
            </section>
        </Dialog>
    )
})

export default Modal;