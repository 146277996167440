export const baseStyles = {
    fontFamily: "Arial",
    color     : {
        dark    : "#484848",
        light   : "#8E8E91",
        white   : "#FFFFFF",
        disabled: "#CCCCCC",
        link    : "#009CDE",
        red     : "#E32B2B",
    },
    grid: {
        row: {
            display : "flex",
            flexWrap: "wrap"
        },
        col: {
            position : "relative",
            width    : "100%",
            flexBasis: 0,
            flexGrow : 1,
            maxWidth : "100%",            
        }
    }
}