import { selectUserInfo } from "app/common/auth/selectors";
import { TableDataProps } from "app/containers/UserDashboardPage/types";
import { transaction_status } from "app/containers/UserDashboardPage/utils";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { formatDatetime, numberFormat } from "utils";
import { clients, fulfillment_methods } from "utils/options";

const TableData = memo((props: TableDataProps) => {
    const {asComponent, odd, transaction, toggleTransaction, openTransactionModal, openDeliveryModal} = props;
    const user = useSelector(selectUserInfo)

    const classname = `${odd ? 'odd' : ''} ${transaction.selected ? 'selected': ''}`;
    const toggle    = () => toggleTransaction(transaction.payment_id);

    /**
     * Format Intent
     */
    let intents = {
            cash_in           : 'Cash-In',
            cash_out          : 'Cash-Out',
            top_up            : 'Top-Up',
            deposit           : 'Deposit',
            send_money        : 'Send Money',
            account_activation: 'Account Activation',
            payment           : 'Payment',
        },
        validIntent = transaction.intent && transaction.intent in intents,
        intent      = validIntent? intents[transaction.intent as any]: transaction.intent;

    /**
     * Flags
     */
    let isPetron   = user?.username?.includes('petron'),
        isBusiness = user?.account_type?.type == 'business',
        isClient   = clients.includes(user?.username || ""),
        isApi      = user?.username && user?.groups?.includes(user.username),
        isP2P      = transaction.user_type == 'P2P' && !isClient && !isApi;
    // END

    /**
     * Format Datetime
     */
    let date = formatDatetime(transaction?.updated_at, 'MMM DD, YYYY');
    // END

    /**
     * Format Amounts
     */
    let amount_details = transaction?.amount_details,
        net    = numberFormat(+(transaction.payout_amount || 0)),
        amount = numberFormat(+(amount_details?.gross || 0)),
        fees   = numberFormat((
            isClient? (
                +(amount_details?.fees?.system || 0) +
                +(amount_details?.fees?.sending?.fee || 0) +
                +(amount_details?.fees?.receiving?.fee || 0) +
                +(amount_details?.fees?.delivery?.actual_fee || 0) +
                +(amount_details?.fees?.delivery?.system?.fee || 0)
            ):
            +(transaction.receiver_fee || 0)
        ) + +(transaction.partner_fee || 0));
    //END

    /**
     * Add Currency
     */
    amount = amount_details?.currency == 'PHP'? `₱ ${amount}`: `${amount} ${amount_details?.currency}`;
    fees   = amount_details?.currency == 'PHP'? `₱ ${fees}`: `${fees} ${amount_details?.currency}`;
    net    = amount_details?.currency == 'PHP'? `₱ ${net}`: `${net} ${amount_details?.currency}`;
    // END

    /**
     * Color Texts
     */
    const status       = transaction?.is_onhold? 'hold': transaction.status
    const netColor     = status == 'complete' && 'green'
    const status_color = (
        transaction_status[status] == 'Completed'? 'green':
        transaction_status[status] == 'Failed'? 'red':
        transaction_status[status] == 'On Hold'? 'yellow': ''
    )
    // END

    let name = transaction?.sender_details?.name;
    switch (user?.username) {
        case 'lotto':
            switch (transaction?.intent) {
                case 'cash_out':
                    name = transaction?.receiver_details?.name;
                break;
            }
        break;
    }

    const DeliveryStatus = () => {
        const order_details = transaction?.additional_details?.order_details;
        let classname = 'text',
            onClick   = undefined,
            status    = fulfillment_methods[order_details?.method || "regular"];
        const fulfillmentMethodColor = order_details?.delivery_status == 'delivered' || order_details?.delivery_status == 'canceled' ? '': 'red bold'

        switch (order_details?.method) {
            case 'delivery':
                classname = `${fulfillmentMethodColor} pointer`;
                onClick   = openDeliveryModal as any;
                status    = order_details?.delivery_status? {
                    not_ready   : "For Delivery",
                    for_pickup  : "For Delivery",
                    for_delivery: "Delivery In Progress",
                    delivered   : "For Delivery",
                    canceled    : "For Delivery (Cancelled order)",
                }[order_details?.delivery_status]: "For Delivery";
            break;
            default:
                status = isPetron? "Regular Payment": status;
            break;
        }

        return <td className={classname} onClick={onClick}>{status}</td>
    }

    return (
        <tr role="row" className={classname}>
            {!asComponent && <td className="check-box" onClick={toggle}></td>}
            <td className="date">{date}</td>
            <td className="blue pointer bold" onClick={openTransactionModal}>{transaction.payment_id}</td>
            {isApi && <td className="blue bold">{intent}</td>}
            <td className="text">{name}</td>
            {isP2P && <td className="text">{transaction?.sender_details?.message}</td>}
            {isP2P && isBusiness && <DeliveryStatus/>}
            <td className={`status bold ${status_color}`}>{transaction_status[status]}</td>
            <td className="amount">{amount}</td>
            <td className="amount">{fees}</td>
            <td className={`amount bold ${netColor}`}>{net}</td>
        </tr>
    )
})

export default TableData