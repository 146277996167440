export const configHeaders = (accessToken: string) => {
    const headers: { [name: string]: string } = {};

    headers['Authorization'] = `Bearer ${accessToken}`;
    headers['Content-type'] = 'application/json';
    headers['Accept'] = 'application/json';

    return headers;
};

export const configCustomHeaders = ({ ...header }) => {
    const headers: { [name: string]: string } = {};

    for (let key in header) {
        headers[key] = header[key];
    }

    return headers;
};
